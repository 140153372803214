import React from 'react';
import Cookies from 'js-cookie';

import Navigation from '../components/static/Navigation';
import Footer from '../components/static/Footer';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";

import { RegisterModal } from '../modals';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { validateEmail, validateForm, userExists, registerUser } from '../api';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement,  Tooltip, Legend, Filler );

class PropertyValuePage extends React.Component {
    constructor() {
        super();

        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            muni: urlParams.get('muni') ? urlParams.get('muni') : null,
            area: urlParams.get('area') ? urlParams.get('area') : null,
            email: urlParams.get('email') ? urlParams.get('email') : null,
            didReg: urlParams.get('register') == 'true' ? true : false,
            isReg: null,
            isLogged: Window.user_data.isLogged,
            errors: {},
            loading: false,
            didRegModalShow: false
        }

        this.navigation = React.createRef();
    }

    getPriceData() {
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }

        let requestData = this.state.isLogged && !this.state.email ? {
            area: this.state.area,
            muni: this.state.muni
        } : {
            area: this.state.area,
            muni: this.state.muni,
            email: this.state.email
        }

        return fetch("/price-api/prop-price-lite/", {
            method: 'POST',
            headers: headers,
            credentials: 'same-origin',
            body: JSON.stringify(requestData)}).then(resp => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        let lineData = json.data
                        lineData.line.datasets.map(i => {
                            if(i.label == 'Lägsta kubikpris') { i.borderColor = '#2FAF9A'; i.backgroundColor = 'rgb(47, 175, 175, 0.3)' }
                            if(i.label == 'kr/m3sk') { i.borderColor = '#F26157'; i.backgroundColor = 'rgb(242, 97, 87, 0.3)' }
                            if(i.label == 'Högsta kubikpris') { i.borderColor = '#6A58C6'; i.backgroundColor = 'rgb(106, 88, 198, 0.3)' }
                            i.borderWidth = 4
                            i.pointBorderWidth = 3
                            i.fill = true
                        })
                        gtag("event", "did_prop_price_lite", { method: "Site" });
                        this.setState({ data: lineData, loading: false, registerModal: !this.state.didRegModalShow ? this.state.didReg : false })
                    })
                } else {
                    alert('Something went wrong!')
                    this.setState({ loading: false })
                }
        });
    }

    handleSubmit(e) {
        e.preventDefault()

        let checkList = [];
        let data = this.state

        if (this.state.isLogged) {
            checkList.push('muni', 'area')
        } else if (this.state.isReg == true) {
            checkList.push('email', 'muni', 'area')
        } else {
            checkList.push('roles', 'email', 'muni', 'mobile_phone_number', 'area', 'checkbox')
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg)

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            this.setState({ loading: true, errors: {} })

            if(!this.state.isLogged || this.state.isReg == false) {
                registerUser({ email: data.email, roles: data.roles, muni: data.muni, mobile_phone_number: data.mobile_phone_number }).then(res => {
                    if (res.ok) {
                        setTimeout(() => {
                            gtag('event', 'Registered', {});
                            gtag("event", "sign_up", {method: "Site",})
                        }, 100);
                        this.navigation.current.updateNavbar()
                        this.setState({ isLogged: true, didReg: true })
                        this.getPriceData()
                    } else {
                        this.setState({ isLogged: true, loading: false })
                    }
                })
            } else {
                this.getPriceData()
            }
        }
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists })
                        })
                    }
                })
            }, 200)
        }
    }

    componentDidMount() {
        if(this.state.email) {
            userExists(this.state.email).then(resp => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        this.setState({ hideEmailInput: json.exists })
                    })
                }
            })
        }

        if(this.state.muni && this.state.area) {
            this.getPriceData()
        }
    }

    render() {
        return <div className="container-fluid p-0">
            <div className="bg-white">
                <Navigation ref={this.navigation} />

                <VBLoadingScreen show_loading={this.state.loading} />

                <section className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 pb-2">
                            <h2 className="neutral-charcoal mb-3">Vad är skogen värd?</h2>

                            <p className='neutral-charcoal'>Det är många faktorer som påverkar priset av skogsmark. Här ges en indikation av din fastighets värde men variationen kring medelvärdet är stort. Fyll i nedan parametrar och du får priset direkt. Det kostar ingenting.</p>

                            <form className='mb-5' onSubmit={(e) => {this.handleSubmit(e)}}>
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <VBDropdown
                                            label={<label className='fw-medium pb-1 mt-3'>Kommun</label>}
                                            initial={this.state.muni}
                                            placeholder="Välj kommun"
                                            options={Window.react_data.munis}
                                            error={this.state.errors.muni}
                                            onFocus={() => this.setState({ errors: {...this.state.errors, muni: ''}})}
                                            onChange={(val) => { this.setState({ muni: val.value }) }} />
                                    </div>

                                    <div className="col-12 col-xl-6">
                                        <VBInput
                                            label={<label className='fw-medium pb-1 mt-3'>Skogsareal</label>}
                                            initial={this.state.area}
                                            placeholder="Välj areal"
                                            type="number"
                                            error={this.state.errors.area}
                                            onFocus={() => this.setState({ errors: {...this.state.errors, area: ''}})}
                                            onChange={(val) => { this.setState({ area: val }); }} />
                                    </div>
                                </div>

                                {!this.state.isLogged && !this.state.hideEmailInput && 
                                    <VBInput 
                                        label={<label className='fw-medium pb-1 mt-3'>E-postadress</label>}
                                        placeholder="Ange E-postadress"
                                        error={this.state.errors.email}
                                        onFocus={() => this.setState({ errors: {...this.state.errors, email: ''}})}
                                        onChange={(val) => { this.checkEmail(val); this.setState({ email: val }); }} />
                                }
                                
                                {!this.state.isLogged && this.state.isReg == false && 
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <VBPhoneInput
                                                label={<label className='subtitle fw-bold mt-3'>Mobilnummer</label>}
                                                error={this.state.errors.mobile_phone_number}
                                                onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                                                onChange={(val) => { this.setState({ mobile_phone_number: val }) }} />
                                        </div>

                                        <div className="col-12 col-xl-6">
                                            <VBDropdown
                                                label={<label className='subtitle fw-bold pb-1 mt-3'>Välj en roll</label>}
                                                options={Window.react_data.roles}
                                                error={this.state.errors.roles}
                                                onFocus={() => this.setState({ errors: { ...this.state.errors, roles: '' } })}
                                                onChange={(val) => { this.setState({ roles: val.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column mt-3'>
                                        {this.state.errors?.checkbox && 
                                            <p className='text-danger n-fs-7 mb-1'>{this.state.errors?.checkbox}</p>
                                        }
                                            <div className='d-flex align-items-center'>
                                                <VBCheckbox
                                                    label=""
                                                    initial={false}
                                                    onClick={() => { this.setState({ checkbox: !this.state.checkbox, errors: { ...this.state.errors, checkbox: '' } }) }} />

                                                <p className='mb-0 n-fs-7' style={{ color: `${this.props.termsColor}` }}>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/"  className={`text-decoration-underline ${this.props.textColor}`}>användarvillkor</a> och <a href="/privacy/" className={`text-decoration-underline ${this.props.textColor}`}>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='mt-3 col-12 col-md-5'>
                                <button type="submit" className="n-btn btn-forest-green w-100">Visa fastighetsvärdering</button>
                                </div>
                            </form>

                            {this.state.data && <div className="row mt-5">
                                <div className="col-12">
                                    <h3 className='n-md-h4 neutral-charcoal'>Fastighetsvärde 2023:</h3>
                                </div>

                                <div className="col-4 d-flex flex-column justify-content-end">
                                    <p className="text-center mb-0">{Math.round(this.state.data.bar.find(d => d.id === 'Lågt').value).toLocaleString('sv-SE')} kr</p>
                                    <div className="bg-supp-spruce rounded-5 w-75 mx-auto" style={{height: `${Math.round(300 * (1 / this.state.data.bar.find(d => d.id === 'Högt').value * this.state.data.bar.find(d => d.id === 'Lågt').value))}px`}}></div>
                                    <p className="text-center mt-1">Lågt</p>
                                </div>

                                <div className="col-4 d-flex flex-column justify-content-end">
                                    <p className="text-center mb-0">{Math.round(this.state.data.bar.find(d => d.id === 'Medel').value).toLocaleString('sv-SE')} kr</p>
                                    <div className="bg-supp-plum rounded-5 w-75 mx-auto" style={{height: `${Math.round(300 * (1 / this.state.data.bar.find(d => d.id === 'Högt').value * this.state.data.bar.find(d => d.id === 'Medel').value))}px`}}></div>
                                    <p className="text-center mt-1">Medel</p>
                                </div>

                                <div className="col-4">
                                    <p className="text-center mb-0">{Math.round(this.state.data.bar.find(d => d.id === 'Högt').value).toLocaleString('sv-SE')} kr</p>
                                    <div className="bg-supp-scarlet rounded-5 w-75 mx-auto" style={{height: "300px"}}></div>
                                    <p className="text-center mt-1">Högt</p>
                                </div>

                                <div className="col-12 mt-3">
                                    <h3 className="mb-3 mt-4 n-md-h4 neutral-charcoal">Historiska fastighetspriser (kr/m³sk) :</h3>

                                    <Line data={this.state.data.line} options={{ responsive: true, plugins: {datalabels: { display: false}} }} style={{minHeight: "300px"}} />
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>

                <Footer />
            </div>

            <RegisterModal show={this.state.registerModal} onToggle={() => this.setState({registerModal: !this.state.registerModal, didRegModalShow: true})} passwordSend={true} />
        </div>
    }
}
export default PropertyValuePage;
