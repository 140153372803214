import { Puck } from "@measured/puck";
import React from "react";
import Cookies from 'js-cookie';
import {config}  from './EditorConfig';

import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';


const startContentPage = {
    content: [
        {
            type: "Navigation",
            props: {
                id: "Navigation-d64392d3-ae72-400a-8b3f-f08940947809"
            }
        },
        {
            type: "Footer",
            props: {
                id: "Footer-d568d06f-fcd6-4927-9188-ab47f15b8400"
            }
        }
    ],
    root: {},
    zones: {}
}

const startContentPost = {
    content: [
        {
            type: "Navigation",
            props: {
                id: "Navigation-d64392d3-ae72-400a-8b3f-f08940947809"
            }
        },
        {
            type: "PostCommon",
            props: {
                id: "PostCommon-d6das2d3-ae72-400a-8b3f-f08978947809"
            }
        },
        {
            type: "Footer",
            props: {
                id: "Footer-d568d06f-fcd6-4927-9188-ab47f15b8400"
            }
        }
    ],
    root: {},
    zones: {}
}

// Describe the initial data
const getInitialData = () => {
    let data = Window.react_data

    if(!data.content && !data.draft && data.edit_obj_type == "page") {
        return startContentPage
    } else if (!data.content && !data.draft && data.edit_obj_type == "post"){
        return startContentPost
    } else {
        return {
            content: [],
            root: {},
            zones: {}
        }
    }
}

class Editor extends React.Component{
    constructor(props){
        super(props)
        this.state={
            alert: false,
            content: getInitialData(),
            draft: Window.react_data.draft,
            live: Window.react_data.content,
            refresh: false,
            updateEditingByIsRunning: false,
            choicesModal: Window.react_data.draft || Window.react_data.content ? true : false
        }

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    updateEditingBy() {
        this.setState({updateEditingByIsRunning: true})
        setTimeout(() => {
            const headers = {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            }
            // API request to update last edited by
            return fetch(`/admin-api/${Window.react_data.edit_obj_type}/${Window.react_data.id}/`, {
                method: 'PATCH',
                headers: headers,
                body: JSON.stringify({})
            }).then(() => {
                this.updateEditingBy()
            })
        }, 60000);
    }

    componentDidMount() {
        if(!this.state.updateEditingByIsRunning) {
            this.updateEditingBy()
        }
        document.addEventListener('keydown', this.handleKeyDown);
    }

    refreshPuck() {
        this.setState({ refresh: true })

        setTimeout(() => {
            this.setState({ refresh: false })
        }, 50);
    }

    save(content, draft){
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let data = {}
        if(draft){
            data = {
                draft: this.state.content
            }
        } else{
            data = {
                draft: false,
                content: this.state.content,
                is_live: true
            }
        }
        
    
        return fetch(`/admin-api/${Window.react_data.edit_obj_type}/${Window.react_data.id}/`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(data)
        }).then(() => {
            if(draft){
                this.setState({ draft: this.state.content, alert: true, alertContent: {title: "Success!", text: `Successfully saved draft for ${Window.react_data.title}!`} })
            } else {
                this.setState({ draft: null, live: this.state.content, alert: true, alertContent: {title: "Congratulations!", text: `${Window.react_data.title} has been published live!`}})
            }
        })
    }

    overrides(){
        return {
        headerActions: ({ children }) => (
            <>
                {children}
                <button className='_Button_1brfa_1 _Button--secondary_1brfa_53 _Button--medium_1brfa_22' onClick={() => window.open('/vb-admin/cms/', '_self')}>CMS</button>
                {this.state.live ? <button className='_Button_1brfa_1 _Button--secondary_1brfa_53 _Button--medium_1brfa_22' onClick={() => window.open("/" +Window.react_data.url, '_blank')}>Preview live</button>: null}
                {this.state.draft ? <button className='_Button_1brfa_1 _Button--secondary_1brfa_53 _Button--medium_1brfa_22' onClick={() => window.open("/" +Window.react_data.url+'draft/', '_blank')}>Preview draft</button>: null}
                <button className='_Button_1brfa_1 _Button--secondary_1brfa_53 _Button--medium_1brfa_22' onClick={() => {this.save(null, true)}}>Save draft</button>
            </>
        ),}
    }

    handleKeyDown(event) {
        if (event.ctrlKey || event.metaKey) { 
          if (event.key === 's') {
            event.preventDefault();
            this.save(null,true);
          }
        }
      }
    
      componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
      }

    render(){
        return <div onKeyDown={this.handleKeyDown}>
            {!this.state.refresh ? <Puck 
                headerPath={Window.react_data.url}
                headerTitle={Window.react_data.title}
                config={config} 
                data={this.state.content} 
                onPublish={() => {this.setState({publishModal: true})}} 
                onChange={(content) => {this.setState({content: content})}} 
                overrides={this.overrides()} />: null}

            {this.state.alert ? <Alert className="text-center mx-5 my-2" variant="success" onClose={() => this.setState({alert: false})} dismissible>
                <Alert.Heading>{this.state.alertContent?.title}</Alert.Heading>
                <p className="mb-0">{this.state.alertContent?.text}</p>
            </Alert>: null}

            <Modal show={this.state.choicesModal} onHide={() => {}} backdrop="static" size="lg" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0">
                    <h2 className="primary-main">Choices</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    {this.state.draft && <p className="white">Last edited by: {Window.react_data.editing_by_name} - {Window.react_data.last_edited ? Window.react_data.last_edited.slice(0, 10) : 'undefined'}</p>}

                    <p className="white">This pages seems to already have live content and/or draft content. How would you like to proceed?</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-between border-0">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => {this.setState({ content: Window.react_data.edit_obj_type == "page" ? startContentPage : startContentPost, choicesModal: false }); this.refreshPuck()}}>FROM SCRATCH</button>
                    {Window.react_data.content ? <button type="button" className="btn btn-secondary btn-sm" onClick={() => {this.setState({ content: Window.react_data.content, choicesModal: false }); this.refreshPuck()}}>NEW DRAFT FROM LIVE</button> : null}
                    {Window.react_data.draft ? <button type="button" className="btn btn-secondary btn-sm" onClick={() => {this.setState({ content: Window.react_data.draft, choicesModal: false }); this.refreshPuck()}}>CONTINUE DRAFT</button> : null}
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.publishModal} onHide={() => this.setState({publishModal: false})} size="md" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main">Publish {Window.react_data.title} live?</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className="white">Are you sure you want to publish this page live?</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-between border-0">
                    <button type="button" className="btn btn-primary border-white white hover-primary-dark btn-sm" onClick={() => this.setState({ publishModal: false })}>CANCEL</button>

                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => {this.setState({ publishModal: false }); this.save(this.state.content, false)}}>PUBLISH</button>
                </Modal.Footer>
            </Modal>
        </div>;
    }
}

// Render Puck editor
export default Editor