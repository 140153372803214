import React from 'react';
import Cookies from 'js-cookie';

import VBPhoneInput from '@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput';
import VBMultiOption from '@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption';

class complementinfo extends React.Component {
    constructor(){
        super();
        this.state={
            errors: "",
            munis: [],
            roles: [],
            phoneno: ""
        }

    }

    submit(){
        const headers = {
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        var formData = new FormData();
        formData.append("muni", this.state.munis)
        formData.append("roles", this.state.roles)
        formData.append("mobile_phone_number", this.state.phoneno)
        formData.append("next", Window.react_data.next_path)
        fetch("/user_complement_info_landing/", {
            method: 'POST',
            headers: headers,
            body: formData
          }).then(resp => resp.json()).then((json) => {
            if(json.errors){
                this.setState({errors:json.errors})
                return;
            }
            window.location = json.next;
          })
    }
  
    render() {
        return <div>
            {/* Choose Roles for user if they dont have any */}
            {!Window.react_data.user_has_roles ? <div>
            <VBMultiOption
            options={Window.react_data.business_roles}
            label={<label>Ange din roll(er)</label>}
            onChange={(val) => {this.setState({roles:val.map((itm) => itm.value)})}}
            error={this.state.errors?.roles}
            />
            </div> : null }
            {/* Choose Muni for user if they dont have any */}
            {!Window.react_data.user_has_muni ? <div>
            <VBMultiOption
                options={Window.react_data.munis}
                label={<label className="mt-3">Välj kommun(er)</label>}
                onChange={(val) => {this.setState({munis:val.map((itm) => itm.value)})}}
                error={this.state.errors?.muni}
            />
            </div> : null }
            {/* Enter Telephone Number for user if they dont have any */}
            {!Window.react_data.user_has_phone ? <div>
            <VBPhoneInput 
            label={<label className="mt-3">Ange ditt telefonnummer: </label>}
            onChange={(val) => {this.setState({phoneno:val})}}
            error={this.state.errors?.mobile_phone_number}
            />
             </div> : null}
            
             <button class="n-btn btn-forest-green mt-3" onClick={() => {this.submit();}}>Fortsätt</button>


            </div>;
    }
}
export default complementinfo;