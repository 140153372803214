import React from 'react';
import styled from "styled-components";
import Cookies from 'js-cookie';
import pointInPolygon from 'point-in-polygon';

import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown'
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';

import UpdateMap from './handlers/UpdateMap';
import PropSelector from './PropSelector';
import LeafletMapHandler from "./handlers/LeafletMapHandler";
import PropModal from './handlers/PropModal';
import SaljaVirkeButton from "../SaljaVirkeButton";
import Help from './handlers/help/Help';

const SectionPopupDesktop = styled(Offcanvas)`
    transition: all ease 300ms;
    left: 0px !important;

    &.show {
        left: 270px !important;
    }
`

const SectionPopupMobile = styled(Offcanvas)`
    height: 100% !important;
    max-width: 500px !important;
    
    @media (min-width: 500px) {
        left: 50% !important;
        margin-left: -250px !important;
    }
`

const CustomScrollbar = styled.div`
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #023436;
        border-radius: 10px;
    }
`

const PremiumCover = styled.a`
    transition: background-color ease 300ms;
    z-index: 1;
    width: 70%;
    &:hover {
        background-color: #A2CCB7 !important;
    }
`

const styles = ` 
    #help-button {
        z-index: 1000;
        width: 80px;
        height: 40px;
        background-color: white;
        border-radius: 8px;
        border: 1px solid;
        border-color: #D3D3D3;
        padding-left: 5px;
        position: absolute;
        top: 320px;
        left: auto;
        right: 15px;
    }
  
  @media (min-width: 768px) {
    #help-button {
      top: 210px;
      left: auto;
      right: 15px;
    }
  }
  
  @media (min-width: 1024px) {
    #help-button {
      top: 210px;
      left: auto;
      right: 15px;
    }
  }
`;

class Karta extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionPopup: false,
            currentSectionData: [],
            currentlySelectedProp: {},
            activeProps: [],
            currentlySelectedSect: {},
            editingProp: null,
            sectionData: [],
            showMarkerPopup: false,
            clickedSectionCount: 0,
            ownedProps: Window.react_data.ownedProps,
            // Marker data
            createMarkerIcon: null,
            createMarkerName: "",
            createMarkerDesc: "",
            updatedMarkerIcon: null,
            updatedMarkerName: "",
            updatedMarkerDesc: "",
            updateMarkerPropId: null,
            deleteMarkerId: "",
            deleteMarkerPropId: null,
            // Modals
            markerModal: false,
            markerModalView: 'create',
            deleteMarkerModal: false,
            infoSourcesModal: false,
            infoSellwoodModal: false,
            buyersClose: true,
            guldAnnonsModal: true,
            primakundModal: false,
            newUserModal: true,
            propModalVisible: (Window.react_data.ownedProps == 0)
        }

        this.mapHandler = React.createRef();
        this.updateMap = React.createRef();     
    }

    reloadGeoJson() {
        this.mapHandler.current.clearMap(isCleared => {
            if (isCleared) {
                this.state.activeProps.map(prop => {
                    setTimeout(() => {
                        this.mapHandler.current.setMapGeojson(prop)
                    }, 200);
                })
                this.mapHandler.current.setState({ loading: false });
            }
        })
    }

    updateMapDeletedProp(prop) {
        let propList = this.state.activeProps
        if (this.state.activeProps.find(p => p.info.value == prop.value)) {
            propList = propList.filter(p => p.info.value != prop.value)
            this.setState({ activeProps: propList }, () => this.reloadGeoJson())
        } else {
            alert('Inte hittat fastighet')
        }
    }

    handleErrorRequest(resp) {
        if(resp.status == 500) {
            this.mapHandler.current.setState({ loading: false });
            alert('Oops something went wrong')
            throw new Error('Oops something went wrong');
        } else {
            return resp.json()
        }
    }

    updatePropData(data) {
        let props = this.state.activeProps
        if(data.lmID) {
            props.find(p => p.info.value == data.lmID).json = data
        } else if(data.dbID) {
            props.find(p => p.info.value == "FBA"+data.dbID).json = data
        }
    
        this.setState({ activeProps: props }, () => this.reloadGeoJson())
    }

    openMarkerPlacementPopup(){
        this.setState({  markerModalView: 'create' });
        this.mapHandler.current.map.current.enableMarker();
    }

    handleShapeEdit(e, all_shapes) {
        this.mapHandler.current.setState({ loading: true });
        let target = "/map-api/user-edit-sections/"
        return fetch(target, {
            method: "POST",
            body: JSON.stringify({ propID: this.state.currentlySelectedProp.value, geoms: all_shapes.toGeoJSON() }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => this.handleErrorRequest(resp)).then((data) => {
            this.updatePropData(data)
            this.mapHandler.current.setState({ loading: false });

            return data;
        });
    }

    handleShapeDelete(del, all_shapes) {
        this.mapHandler.current.setState({ loading: true });
        let target = "/map-api/user-delete-sections/"
        let localIds = [];
        del.eachLayer((lyr) => {
            localIds.push(lyr.feature.properties.nr)
        })
        return fetch(target, {
            method: "POST",
            body: JSON.stringify({ propID: this.state.currentlySelectedSect.lm_id, localIDs: localIds }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => this.handleErrorRequest(resp)).then((data) => {
            data.map(p => this.updatePropData(p))
            return data;
        });
    }

    handleShapeCreate(e, all_shapes) {
        let propID = null
        if (this.state.activeProps.length == 1) {
            propID = this.state.activeProps[0].info.value
            this.shapeCreateSubmit(e, propID)
        } else if (this.state.activeProps.length > 1) {
            this.state.activeProps.map(prop => {
                if (prop.BorderGeojson && this.isPolygonWithinPolygon(e.layer.toGeoJSON(), prop.BorderGeojson)) {
                    propID = prop.info.value
                    this.shapeCreateSubmit(e, propID)
                }
            })
            if (!propID) {
                this.setState({ confirmPropModal: true, createShapeLayer: e })
            }
        } else {
            alert('Something went wrong')
        }
    }

    shapeCreateSubmit(e, propID) {
        this.mapHandler.current.setState({ loading: true });

        if (e.layerType == 'marker') {
            return fetch("/map-api/user-create-marker/", {
                method: "POST",
                body: JSON.stringify({
                    propID: propID,
                    geom: e.layer.toGeoJSON(),
                    name: this.state.createMarkerName,
                    comment: this.state.createMarkerDesc,
                    icon: this.state.createMarkerIcon
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(resp => this.handleErrorRequest(resp)).then((data) => {
                this.updatePropData(data)
                this.setState({
                    createMarkerIcon: null,
                    createMarkerName: "",
                    createMarkerDesc: "",
                })
                return data;
            });
        } else {
            return fetch("/map-api/user-create-sections/", {
                method: "POST",
                body: JSON.stringify({ propID: propID, geom: e.layer.toGeoJSON() }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(resp => this.handleErrorRequest(resp)).then((data) => {
                if (data.hasOwnProperty("to_big")) {
                    alert("Du har ritat en för stor avdelning")
                    window.location.reload();
                    return;
                }
                this.updatePropData(data)
                return data;
            });
        }
    }

    createAdFromSection() {
        let target = "/map-api/create-ad/"
        fetch(target, {
            method: "POST",
            body: JSON.stringify({ "sectID": this.state.currentlySelectedSect.nr }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => this.handleErrorRequest(resp)).then((data) => {
            Cookies.set("ad_id", data.ad_id);
            window.location = "/auctions/create";
        });
    }

    // Marker functions
    editMarker = (itm, propID) => {
        this.setState({
            markerModal: true,
            markerModalView: 'edit',
            markerId: itm.id,
            updatedMarkerName: itm.name,
            updatedMarkerDesc: itm.comment,
            updatedMarkerIcon: itm.icon,
            updateMarkerPropId: propID
        });
    };

    updateMarker() {
        this.mapHandler.current.setState({ loading: true });
        let target = "/map-api/user-update-marker/"
        return fetch(target, {
            method: "POST",
            body: JSON.stringify({
                propID: this.state.updateMarkerPropId,
                markerId: this.state.markerId,
                markerName: this.state.updatedMarkerName,
                markerComment: this.state.updatedMarkerDesc,
                markerIcon: this.state.updatedMarkerIcon
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => this.handleErrorRequest(resp)).then((data) => {
            this.updatePropData(data)
            return data;
        });
    }

    deleteMarker = (markerID, propID) => {
        this.setState({ deleteMarkerId: markerID, deleteMarkerPropId: propID, deleteMarkerModal: true })
    }

    onDrawStart(e) {      
        if (e.layerType == "marker") {
            this.setState({ markerModal: true });
        }
    }

    calculateValueFellingClass(klass, volume, price_timber, price_pulp) {
        if (klass == "S1") {
            return (volume * 0.7 * price_timber) + (volume * 0.3 * price_pulp)
        } else if (klass == "S2") {
            return (volume * 0.8 * price_timber) + (volume * 0.2 * price_pulp)
        } else if (klass == "G1") {
            return ((volume * 0.33) * 0.3 * price_timber) + ((volume * 0.33) * 0.7 * price_pulp)
        } else if (klass == "G2") {
            return ((volume * 0.33) * 0.5 * price_timber) + ((volume * 0.33) * 0.5 * price_pulp)
        } else if (klass == "R") {
            return 2665
        } else {
            return 0
        }
    }

    //set pane either on left or bottom
    setSlidingPane(data) {
        this.updateMap.current.updateData(data)

        if(this.state.sectionPopup) {
            this.setState({
                refreshNotes: true,
                currentlySelectedSect: data,
                currentSectionData: this.formatSectionData(data),
                getRiskSectionsData: this.formatSectionRisks(data.risks)
            }, () => this.setState({refreshNotes: false}))
        } else {
            this.setState({
                sectionPopup: true,
                currentlySelectedSect: data,
                currentSectionData: this.formatSectionData(data),
                getRiskSectionsData: this.formatSectionRisks(data.risks)
            })
        }
    }

    formatSectionRisks(data) {
        let bugData = data.find(d => d.type == "Granbarkborre Risk")
        let fireData = data.find(d => d.type == "Brand Risk")
        return (
            <div className={!Window.user_data.isPremium ? "user-select-none" : ""}>
                {bugData && <div className="d-flex align-items-center py-2">
                    <div className="col-1">
                        <img src={Window.static_path + "img/map-markers/marker_bug_green.png"} width="26px" height="26px" />
                    </div>
                    <div className="col-6 px-2">{bugData.type}</div>
                    <div className="col-5">{bugData.severity}</div>
                </div>}
                {fireData && <div className="d-flex align-items-center py-2">
                    <div className="col-1">
                        <img src={Window.static_path + "img/map-markers/marker_fire_green.png"} width="26px" height="26px" />
                    </div>
                    <div className="col-6 px-2">{fireData.type}</div>
                    <div className="col-5">{fireData.severity}</div>
                </div>}
            </div>
        );
    }
    

    formatSectionData(data) {
        let majorTree = "B"
        if (data.tall > data.gran && data.tall > data.lov) {
            majorTree = "T"
        } else if (data.gran > data.tall && data.gran > data.lov) {
            majorTree = "G"
        }
        let age_span = "Okänd ålder"
        let age_spans = [
            [0, 10, "0-10"],
            [10, 20, "10-20"],
            [20, 30, "20-30"],
            [30, 40, "30-40"],
            [40, 50, "40-50"],
            [50, 60, "50-60"],
            [60, 70, "60-70"],
            [70, 80, "70-80"],
            [80, 90, "80-90"],
            [90, 100, "90-100"],
            [100, 999, "100+"]
        ]
        age_spans.forEach((itm) => {
            if (data.age > itm[0] && data.age <= itm[1]) {
                age_span = itm[2]
            }
        })

        if (Number(data.cut_year) > 2005) {
            return <div className="row align-items-center g-0 p-2">
                <div className="col-6 p-2">Avdelnings nr</div>
                <div className="col-6 p-2">{data.localID}</div>

                <div className="col-6 p-2">Huggningsklass</div>
                <div className="col-6 p-2">Ingen åtgärd</div>

                <div className="col-6 p-2">Area</div>
                <div className="col-6 p-2">{data.area} ha</div>

                <div className="col-6 p-2">Ålder</div>
                <div className="col-6 p-2">{data.age} år</div>
            </div>
        } else {
            return <div className="row align-items-center g-0">
                <div className="col-6 p-2">Avdelnings nr</div>
                <div className="col-6 p-2">{data.localID}</div>

                <div className="col-6 p-2">Huggningsklass</div>
                <div className="col-6 p-2">{data.felling_class}</div>

                <div className="col-6 p-2">Area</div>
                <div className="col-6 p-2">{data.area} ha</div>

                <div className="col-6 p-2">Totalvolym</div>
                <div className="col-6 p-2">{data.volume} m³sk</div>

                <div className="col-6 p-2">Tall</div>
                <div className="col-6 p-2">{Math.round(data.tall * data.volume / 100)} m³sk</div>

                <div className="col-6 p-2">Gran</div>
                <div className="col-6 p-2">{Math.round(data.gran * data.volume / 100)} m³sk</div>

                <div className="col-6 p-2">Löv</div>
                <div className="col-6 p-2">{Math.round(data.lov * data.volume / 100)} m³sk</div>

                <div className="col-6 p-2">TGL %</div>
                <div className="col-6 p-2">{data.tall} | {data.gran} | {data.lov}</div>

                <div className="col-6 p-2">Estimerad bonitet</div>
                <div className="col-6 p-2">{Math.round(data.growth_rate)} m³sk/ha/år</div>

                <div className="col-6 p-2">Biomassa</div>
                <div className="col-6 p-2">{data.bio} ton torrsubstans/ha</div>

                <div className="col-6 p-2">Grundyta</div>
                <div className="col-6 p-2">{data.grun} m²/ha</div>

                <div className="col-6 p-2">Estimerad ålder</div>
                <div className="col-6 p-2">{age_span} år</div>

                <div className="col-6 p-2"><span className="text-nowrap">Estimerad SI</span> (ståndortsindex)</div>
                <div className="col-6 p-2">{majorTree}{data.si}</div>

                <div className="col-6 p-2">Medelhöjd</div>
                <div className="col-6 p-2">{data.height} m</div>

                <div className="col-6 p-2">Medeldiameter</div>
                <div className="col-6 p-2">{data.diameter} cm</div>

                <div className="col-6 p-2">Skannat år</div>
                <div className="col-6 p-2">{data.scanned_year}</div>

                <div className="col-6 p-2">Framräknat till</div>
                <div className="col-6 p-2">{new Date().getFullYear()}</div>
            </div>
        }
    }

    //populate sliding pane
    populateSectiondata() {
        //check if we have a selected section
        if (!this.state.currentlySelectedSect.hasOwnProperty("nr")) {
            return null;
        }
        
        return <CustomScrollbar className="ms-4 me-2 pe-2">
            <div  className="neutral-charcoal bg-neutral-cloud subtitle rounded my-3 p-3">
                <div className="d-flex p-2">
                    <div className="col-12 fw-medium" id="sectionDataContainer">
                        Översiktlig data
                        <img src={`${Window.static_path}img/icons/info-circle.svg`} role="button" className="float-end charcoal-filter" width="20px" height="20px" onClick={() => this.setState({ infoSourcesModal: true })} />
                        <img id="editSectionDataPen" src={`${Window.static_path}img/icons/ui-edit.svg`} role="button" className="float-end charcoal-filter me-2" width="20px" height="20px" onClick={() => { this.setState({ sectionPopup: false }); this.updateMap.current.showPopupFunc(true) }} />
                    </div>
                </div>

                {this.state.currentSectionData}
            </div>

            <div>
                <img src={`${Window.static_path}img/icons/info-circle.svg`} role="button" className="float-end forest-green-filter" heigth="20px" width="20px" onClick={() => this.setState({ infoSellwoodModal: true })} />
                <button className='n-btn btn-forest-green btn-sm w-100 my-2' onClick={this.createAdFromSection.bind(this)}>Ta in anbud på denna avdelning</button>
            </div>

            <div className='bg-neutral-cloud rounded p-3 my-3'>
                <div className={!Window.user_data.isPremium ? "position-relative user-select-none text-center" : "text-center"}>
                    {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-white pine subtitle position-absolute top-50 start-50 translate-middle rounded p-3">Bli <b>Pluskund</b> för att se virkespriset</PremiumCover> : null}
                    <div style={!Window.user_data.isPremium ? { filter: "blur(9px)" } : {}}>
                        <h3 className="n-fs-5 neutral-charcoal fw-medium">Förväntat netto intäkt/kostnad</h3>
                        <h2 className="n-h4 supp-forest-green">{(this.state.currentlySelectedSect.actions.length > 0 ? this.state.currentlySelectedSect.actions.reduce((acum, itm) => { return acum + itm.result }, 0) : 0).toLocaleString().replaceAll(',', ' ')} kr</h2>
                    </div>
                </div>
            </div>

            <div className='neutral-charcoal bg-neutral-cloud subtitle rounded my-3 p-3'>
                <div className="row g-0 position-relative p-2">
                    {/* {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-white pine subtitle position-absolute top-50 start-50 translate-middle text-center rounded p-3">Bli <b>Pluskund</b> för att se risk för barkborrar och brand</PremiumCover> : null} */}
                    <div>
                        <div className="col-12 fw-medium">
                            Risker
                        </div>
                        {this.state.getRiskSectionsData}
                    </div>
                </div>


            </div>

            <div className='bg-neutral-cloud rounded p-3 my-3'>
                <div className='d-flex flex-column subtitle p-2'>
                    <div className="col-12 neutral-charcoal fw-medium mb-2">
                        Lägg till anteckning
                    </div>

                    <div className="col-12">
                        {!this.state.refreshNotes && <VBTextarea resize="none" height="150px" initial={this.state.currentlySelectedSect.note} onChange={(val) => { this.state.currentlySelectedSect.note = val; this.setState({ currentlySelectedSect: this.state.currentlySelectedSect }); }} />}
                    </div>

                    <div className="col-12">
                        <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={(e) => { this.saveNote(e) }}>Spara anteckning</button>
                    </div>
                </div>
            </div>

            <div className='bg-neutral-cloud rounded p-3 my-3'>
                <div className='d-flex flex-column subtitle p-2'>
                    <div className="col-12 neutral-charcoal fw-medium mb-2">
                        Verktygslåda
                    </div>

                    <div className='col-12'>
                        <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={() => {
                            this.setState({ sectionPopup: false})
                            this.mapHandler.current.map.current.enableEditLayers()
                        }}>Redigera lager</button>
                    </div>

                    {Window.user_data.isPremium && <div className='col-12'>
                        <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={() => {
                            this.setState({ sectionPopup: false, markerModalView: 'create' })
                            this.mapHandler.current.map.current.enableMarker()
                        }}>Markörer</button>
                    </div>}

                    <div className='col-12'>
                        <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={() => {
                            this.setState({ sectionPopup: false })
                            this.mapHandler.current.map.current.deleteSelectedLayer();
                        }}>Radera lager</button>
                    </div>
                </div>
            </div>
        </CustomScrollbar>
    }

    saveNote() {
        let target = "/map-api/user-update-sections/"
        this.mapHandler.current.setState({ loading: true });

        fetch(target, {
            method: "POST",
            body: JSON.stringify({
                PropID: this.state.currentlySelectedProp.value,
                nr: this.state.currentlySelectedSect.nr,
                note: this.state.currentlySelectedSect.note
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(res => {
            if (res.ok) {
                res.json().then(json => {
                    //this.updatePropData(json)
                    this.mapHandler.current.setState({ loading: false });
                });
            } else {
                alert('Oops! Något gick fel...')
            }

        });
    }

    handleHelpPopup() {
        const helpCookieName = `help_karta_${Window.user_data.loggedInUserID}`;
        const helpCookie = Cookies.get(helpCookieName);

        // If there is no cookie for this user ID then show modal
        if (!helpCookie) {
            let expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 60);
            // Set cookie with the current user ID
            Cookies.set(helpCookieName, 'completed', { expires: expirationDate });
            this.setState({ helpModalsVisible: true });
        }
    }

    isPolygonWithinPolygon(inner, outer) {
        var isContained = true;

        outer.geometry.coordinates.map(polygon => {
            if(inner.geometry.type == "Point") {
                if (!pointInPolygon(inner.geometry.coordinates, polygon[0])) {
                    isContained = false;
                }
            } else {
                inner.geometry.coordinates.map(shape => {
                    shape.map(point => {
                        if (!pointInPolygon(point, polygon[0])) {
                            isContained = false;
                        }
                    })
                })
            }
        })

        return isContained
    }
    
    onSectionClick(data){
        this.setSlidingPane(data);

        if (!Window.user_data.isPremium && (this.state.clickedSectionCount + 1 == 2 || (this.state.clickedSectionCount + 1) % 5 == 0)) {
            this.setState({ primakundModal: true });
        }
        this.setState({ clickedSectionCount: this.state.clickedSectionCount + 1 });
    }

    propertyFinish(propsData) {
        this.setState({ activeProps: propsData }, () => {
            this.mapHandler.current.clearMap(isCleared => {
                if(isCleared) {
                    Promise.all(
                        propsData.map(prop => {
                            setTimeout(() => {
                                this.mapHandler.current.onFinish(prop)
                            }, 200);
                        })
                    ).then(() => {
                        this.mapHandler.current.stopLoading()
                        this.handleHelpPopup()
                    })
                }
            })
        })
    }

    render() {
        const mapMarkers = [
            { "src": Window.static_path + "img/map-markers/marker-location.svg" },
            { "src": Window.static_path + "img/map-markers/marker-mushroom.svg" },
            { "src": Window.static_path + "img/map-markers/marker-tree.svg" },
            { "src": Window.static_path + "img/map-markers/marker-stop.svg" },
            { "src": Window.static_path + "img/map-markers/marker-windy.svg" },
            { "src": Window.static_path + "img/map-markers/marker-treebug.svg" },
            { "src": Window.static_path + "img/map-markers/marker-onfire.svg" },
        ]

        return (<React.Fragment>
            <style>{styles}</style>
                <div className="w-100 h-100 position-relative" style={{zIndex: 0 }}>
                    <div style={{ "z-index": "1000", "bottom": "20px", "left": "50%", "transform": "translateX(-55%)", "position": "absolute" }}>
                        {this.state.currentlySelectedProp.hasOwnProperty("label") && screen.width >= 768 ? <SaljaVirkeButton style={{ "fontSize": "120%" }} extra_ad_data={{ "description_of_property": this.state.currentlySelectedProp.label }} className="n-btn btn-core">Sälja virke</SaljaVirkeButton> : null}
                    </div>

                    <LeafletMapHandler
                        ref={this.mapHandler}
                        handleShapeEdit={this.handleShapeEdit.bind(this)}
                        handleShapeCreate={this.handleShapeCreate.bind(this)}
                        handleShapeDelete={this.handleShapeDelete.bind(this)}
                        onDrawStart={(e) => { this.onDrawStart(e) }}
                        onSectionClick={(data) => {this.onSectionClick(data); }}
                        editMarker={this.editMarker}
                        deleteMarker={this.deleteMarker}
                        openMarkerPlacementPopup={this.openMarkerPlacementPopup.bind(this)}
                        {...this.props} />

                    <UpdateMap
                        ref={this.updateMap}
                        currentSectionData={this.state.currentlySelectedSect}
                        onSave={(data) => { this.updatePropData(data); this.updateMap.current.showPopupFunc(false) }} />

                    <div className="container-fluid d-flex position-absolute justify-content-center top-0 left-0 pt-3">
                        {this.state.ownedProps.length > 0 && <PropSelector
                            onDivideStart={() => {this.mapHandler.current.startLoading(); this.setState({sectionPopup: false})}}
                            onDivideFinish={data => this.propertyFinish(data)}
                            updateMapDeletedProp={prop => this.updateMapDeletedProp(prop)}
                            deleteProps={deletedProp => {
                                // If you delete the property in prop selector with the garbage bin this is run
                                if (this.state.activeProps.find(prop => {prop.info.value === deletedProp.value})) {
                                    this.mapHandler.current.deleteLayersProperty();
                                }
                            }}
                            activeProps={this.state.activeProps}
                            ownedProps={this.state.ownedProps} />}
                    </div>
                    <button id="help-button" className='position-fixed mt-2 d-flex align-items-center' onClick={() => this.setState({helpModalsVisible: true})}>
                        <img src={Window.static_path + "img/icons/BsInfoLg.svg"} className="neutral-charcoal-filter" style={{ width:'18px', height:'18px' }}></img>
                        <span className="neutral-charcoal lh-1 ms-1" style={{fontSize:'13px'}}>Hjälp</span>
                    </button>
                </div>

                <SectionPopupDesktop className="d-none d-lg-block py-2 bg-neutral-cloud-100" show={this.state.sectionPopup} id="sectionDetailsContainer"  onHide={() => { this.setState({ sectionPopup: false }) }} placement='start' backdrop={false}>
                    <div className="row subtitle g-0 px-4 py-2 my-3 bg-neutral-cloud hover-bg-neutral-cloud-700" role="button" id="closeSectionDetailsContainer" onClick={() => this.setState({ sectionPopup: false })}>
                        <div className="col-12 d-flex justify-content-between align-items-center supp-forest-green">
                            <span className="fw-medium">Fastighet</span>
                            <span className="ms-2 supp-forest-green">{this.state.currentlySelectedSect?.property_name}</span>
                            <img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-50" width="30" height="30" />
                        </div>
                    </div>

                    {this.populateSectiondata()}
                </SectionPopupDesktop>
                
                <SectionPopupMobile className="d-block d-lg-none py-2 bg-neutral-cloud-100" show={this.state.sectionPopup} id="sectionDetailsContainer"  onHide={() => { this.setState({ sectionPopup: false }) }} placement='bottom' backdrop={false}>
                    <div className="row subtitle g-0 mx-4 my-2 align-items-center">
                        <div className="col-12 d-flex justify-content-start align-items-center py-2" role="button" id="closeSectionDetailsContainer" onClick={() => this.setState({ sectionPopup: false })}>
                            <img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-50" width="35" height="35" />
                            <p className="fw-medium mb-0">Fastighet</p>
                        </div>
                        
                        <div className="col-12 supp-forest-green">{this.state.currentlySelectedProp.label}</div>
                    </div>

                    {this.populateSectiondata()}
                </SectionPopupMobile>

                {/* CONFIRM PROP MODAL */}
                <Modal show={this.state.confirmPropModal} onHide={() => {this.setState({ confirmPropModal: false, confirmPropModalError: '' }); this.mapHandler.current.map.current.state.map.removeLayer(this.state.createShapeLayer.layer) }} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="neutral-charcoal mb-0 n-h4">Vilken fastighet redigerar du?</h2>
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='neutral-charcoal'>Eftersom du har flera fastigheter synligt behöver vi veta vilken fastighet du redigerar</p>

                        <VBDropdown
                            options={this.state.activeProps.map(p => {return {label: p.info.label, value: p.info.value}})}
                            onChange={(val) => this.setState({ editingProp: val.value })}
                            error={this.state.confirmPropModalError}
                            onFocus={() => this.setState({ confirmPropModalError: '' })} />
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end border-0">
                        <button type="button" className="n-btn btn-forest-green" onClick={() => {
                            if(!this.state.editingProp) {
                                this.setState({ confirmPropModalError: 'Du måste välja en fastighet för att fortsätta' })
                            } else {
                                this.shapeCreateSubmit(this.state.createShapeLayer, this.state.editingProp)
                                this.setState({ confirmPropModal: false })}
                            }
                        }>Fortsätt</button>
                    </Modal.Footer>
                </Modal>

                {/* MARKER DELETE POPUP */}
                <Modal show={this.state.deleteMarkerModal} onHide={() => this.setState({ deleteMarkerModal: false })} size="md" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="neutral-charcoal mb-0 n-h4">Är du säker på att du vill radera?</h2>
                    </Modal.Header>
                    <Modal.Footer className="justify-content-between border-0">
                        <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => this.setState({ deleteMarkerModal: false })}>Nej</button>
                        <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => {
                            this.mapHandler.current.setState({ loading: true });
                            this.setState({ deleteMarkerModal: false })
                            let target = "/map-api/user-delete-marker/"
                            return fetch(target, {
                                method: "POST",
                                body: JSON.stringify({
                                    propID: this.state.deleteMarkerPropId,
                                    markerId: this.state.deleteMarkerId,
                                }),
                                headers: {
                                    'Content-Type': 'application/json',
                                    'X-CSRFToken': Cookies.get('csrftoken')
                                },
                            }).then(resp => this.handleErrorRequest(resp)).then((data) => {
                                this.updatePropData(data)
                                return data;
                            });
                        }}>Radera</button>
                    </Modal.Footer>
                </Modal>

                {/* MARKER PLACEMENT POPUP */}
                <Modal show={this.state.markerModal} onHide={() => { this.setState({ markerModal: false }); this.mapHandler.current.map.current.disableMarker() }} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="neutral-charcoal mb-0 n-h4">Skapa Markör</h2>
                    </Modal.Header>
                    <Modal.Body className="neutral-charcoal py-0">
                        <p>Markörer hjälper dig hålla kolla på viktiga punkter i din skog.</p>
                        <p className="mb-1">Välj ikon:</p>
                        {this.state.errorMessage && (
                            <p className="text-danger">{this.state.errorMessage}</p>
                        )}
                        {mapMarkers.map((itm) => {
                            const isSelected = this.state.markerModalView == 'edit' ? (this.state.updatedMarkerIcon == itm.src) : (this.state.createMarkerIcon == itm.src);
                            return <a onClick={() => { this.state.markerModalView == 'edit' ? this.setState({ updatedMarkerIcon: itm.src }) : this.setState({ createMarkerIcon: itm.src }) }} className="m-2 d-inline-flex flex-justify-center flex-align-center justify-content-center rounded hover-bg-neutral-cloud-700" href="#" style={{ width: "64px", height: "72px", transition: "300ms", background: isSelected ? "#F0F0F0" : "" }}>
                                <img src={itm.src} className="p-1" width="48px" />
                            </a>
                        })}

                        <p className="mb-1 mt-2">Namn</p>
                        <VBInput
                            initial={this.state.markerModalView == 'edit' ? this.state.updatedMarkerName : null}
                            onChange={(val) => { this.state.markerModalView == 'edit' ? this.setState({ updatedMarkerName: val }) : this.setState({ createMarkerName: val }) }} />

                        <p className="mb-1 mt-2">Beskrivning</p>
                        <VBTextarea
                            initial={this.state.markerModalView == 'edit' ? this.state.updatedMarkerDesc : null}
                            onChange={(val) => { this.state.markerModalView == 'edit' ? this.setState({ updatedMarkerDesc: val }) : this.setState({ createMarkerDesc: val }) }} resize="none" height="200px" />
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between border-0">
                        <button className='n-btn btn-forest-green rounded btn-sm w-100 my-2' onClick={() => {
                            if (this.state.markerModalView == 'edit') {
                                this.setState({ markerModal: false })
                                this.updateMarker();
                            } else {
                                // Check if a marker is selected before allowing to proceed
                                if (this.state.createMarkerIcon !== null) {
                                    
                                    this.setState({ markerModal: false, errorMessage: '' })
                            } else {
                                
                                this.setState({ errorMessage: '*Vänligen välj en markör innan du fortsätter.' });
                            }
                        }
                        }}>
                        {this.state.markerModalView == 'edit' ? 'Spara' : 'Välj plats på kartan'}
                        </button>

                        {this.state.markerModalView == 'edit' && <button className='n-btn btn-forest-green btn-outline btn-sm w-100 my-2' onClick={() => {
                            this.setState({ markerModal: false, deleteMarkerModal: true, deleteMarkerId: this.state.markerId })
                        }}>Radera</button>}
                    </Modal.Footer>
                </Modal>

                {/* SOURCES INFORMATION POPUP */}
                <Modal show={this.state.infoSourcesModal} onHide={() => this.setState({ infoSourcesModal: false })} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="neutral-charcoal mb-0 n-h4">Information</h2>
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='neutral-charcoal'>Vi hämtar data från flera olika källor vissa helt öppna och andra under olika licenser, några av våra källor är: Lantmäteriet, Skogsstyrelsen, Naturvårdsverket, Riksantikvarieämbetet och Sveriges lantbruksuniversitet.</p>
                        <p className='neutral-charcoal'>Vi tackar alla som levererar data till oss enormt mycket för deras hårda jobb med att tillhandahålla den data vi jobbar med.</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end border-0">
                        <button type="button" className="n-btn btn-forest-green" onClick={() => this.setState({ infoSourcesModal: false })}>Stäng</button>
                    </Modal.Footer>
                </Modal>

                {/* SELLING TIMBER INFORMATION POPUP */}
                <Modal show={this.state.infoSellwoodModal} onHide={() => this.setState({ infoSellwoodModal: false })} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="neutral-charcoal mb-0 n-h4">Sälj virke på Treebula</h2>
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='neutral-charcoal'>Vill du säkerställa rätt virkespris? Gör som tusentals andra skogsägare och använd Treebulas marknadsplats för att sälja virke.  Du lägger upp den avdelning/ar du vill ta in anbud på och får anbud av alla potentiella köpare på så sätt säkerställer du rätt virkespris. Tjänsten är helt kostnadsfri för dig som skogsägare och du har helt fri prövningsrätt det vill säga är du inte nöjd med anbuden kan du tacka nej.</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end border-0">
                        <button type="button" className="n-btn btn-forest-green" onClick={() => this.setState({ infoSellwoodModal: false })}>Stäng</button>
                    </Modal.Footer>
                </Modal>

                {/* ALERT BUYERS CLOSE */}
                {new Date() > new Date("2022-03-12") && new Date() < new Date("2023-04-12") ? <Modal show={this.state.buyersCloseModal} onHide={() => this.setState({ buyersCloseModal: false })} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="supp-forest-green mb-0 n-h4">Hej Skogsägare,</h2>
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='neutral-charcoal'>Goda nyheter! Vi vill meddela att vi har fått in nya skogsbolag på Treebula som köper virke där just du äger skog. Dessa skogsbolag exporterar virke utomlands och skeppar virket från närmaste hamn.</p>
                        <p className='neutral-charcoal'>De erbjuder just nu specialpriser på virke till Treebulas medlemmar i vissa län. Ska du avverka eller gallra inom snar framtid? Klicka på knappen nedan och fyll i formuläret så ringer vi upp och berättar mer. Självklart är det inte bindande att få mer information.</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end border-0">
                        <a className='n-btn btn-forest-green' href="https://virkesborsen.typeform.com/to/DPp5jv1e">Läs mer och ta del här</a>
                    </Modal.Footer>
                </Modal> : null}

                {/* ALERT GULD ANNONS */}
                {new Date() > new Date("2022-12-24") && new Date() < new Date("2023-01-15") ? <Modal show={this.state.guldAnnonsModal} onHide={() => this.setState({ guldAnnonsModal: false })} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="supp-forest-green mb-0 n-h4">Gratis Guldannons till våra medlemmar</h2>
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='neutral-charcoal'>Jultraditionen fortsätter - Gratis Guldannons till alla medlemmar! Ska du avverka eller gallra i vår och vill ha rätt virkespris? Vi bjuder på en gratis Guldannons (värd 2000 kr) fram till 15/1.</p>
                        <p className='neutral-charcoal'>Du kan ta del av erbjudandet nu och bestäm själv när du vill använda det under året. Självklart är det INTE bindande att använda din guldannons och det är helt kostnadsfritt. Klicka på knappen nedan för att få mer information och ta del av din guldannons.</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end border-0">
                        <a className='n-btn btn-forest-green' href="https://virkesborsen.typeform.com/to/Sa5xmdXw">Läs mer och ta del här</a>
                    </Modal.Footer>
                </Modal> : null}

                {/* ALERT Pluskund - COMMENTED OUT DUE TO TEMPORARY DISABLED */}
                {/*<Modal show={this.state.primakundModal} onHide={() => this.setState({ primakundModal: false })} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeButton>
                        <h2 className="supp-forest-green mb-0 n-h4">Bli Pluskund</h2>
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='neutral-charcoal'>Vill du se all information i din skogsöversikt, placera ut kartmarkörer, ändra avdelningsgränser, se virkesvärde per avdelning m.m? Bli Pluskund för 999kr/år eller 129kr/månad.</p>
                        {!Window.react_data.didNykund ? <p className="neutral-charcoal">Vi erbjuder dig just nu att prova Pluskund gratis i 7 dagar eller 20% om du blir Pluskund ett år.</p> : null}
                    </Modal.Body>

                    {Window.react_data.didNykund ? <Modal.Footer className="justify-content-between border-0">
                        <a className='n-btn btn-forest-green' href="/primakund/?subscription_length=month&instant_generate=1">Köp Pluskund månad</a>
                        <a className='n-btn btn-forest-green' href="/primakund/?subscription_length=year&instant_generate=1">Köp Pluskund år</a>
                    </Modal.Footer> :
                    <Modal.Footer className="justify-content-end border-0">
                        <a className='n-btn btn-forest-green' href="/primakund/prova/">Till erbjudandet</a>
                    </Modal.Footer>}
                </Modal>*/}
                {/*NEW USER OR USER WITHOUT PROPERTY POPUP */}
                <PropModal
                    visible={this.state.propModalVisible}
                    onHide={() => {this.setState({propModalVisible:false})}}
                    onDivideStart={() => this.mapHandler.current.startLoading()}
                    onDivideFinish={data => this.propertyFinish(data)}
                    updateOwnedProps={arr => this.setState({ownedProps: arr})} >
                </PropModal>
            
            {this.state.helpModalsVisible && <Help mapHandler={this.mapHandler} onClose={() => this.setState({helpModalsVisible: false})}/>}
        </React.Fragment>);
    }
}


export default Karta;