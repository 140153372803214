import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ErrorModal = ({show, onToggle, title, message}) => {
    return <Modal show={show} onHide={() => onToggle()} size="md" contentClassName="bg-neutral-cloud neutral-charcoal p-2" centered>
        <Modal.Header className="align-items-start border-0" closeButton>
            <h4 className="mb-0">{title ? title : "Oops! Något gick fel."}</h4>
        </Modal.Header>
        <Modal.Body className="py-0">
            <p className='py-3'>{message ? message : <>Ring <a href="tel:08339944" className="fw-bold primary-main">08-33 99 44</a> så hjälpa vi dig vidare.</>}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-0">
            <button type="button" className="n-btn btn-forest-green" onClick={() => onToggle()}>Fortsätt</button>
        </Modal.Footer>
    </Modal>
}

const RegisterModal = ({show, onToggle, passwordSend}) => {
    return <Modal show={show} onHide={() => onToggle()} size="lg" contentClassName="bg-neutral-cloud neutral-charcoal p-2" centered>
        <Modal.Header className="align-items-start border-0" closeButton>
            <h4 className="mb-0">Välkommen till Treebula</h4>
        </Modal.Header>
        <Modal.Body className="py-0">
            <p className="n-fs-6">Ditt konto är nu registrerat hos oss och du kan komma igång med att använda alla våra digitala tjänster kostnadsfritt. Jämför virkespriser, ta in anbud på avverkning och gallring, få rådgivning eller skapa en skogsbruksplan - digitalt och helt utan förbindelser.</p>

            {passwordSend && <p className="n-fs-6">Ditt lösenord har skickats till den mailadress du angav vid registrering, använd den för att logga in på dina sidor.</p>}
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-0">
            <button type="button" className="n-btn btn-forest-green" onClick={() => onToggle()}>Stäng</button>
        </Modal.Footer>
    </Modal>
}

export {ErrorModal, RegisterModal}