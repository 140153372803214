import Cookies from 'js-cookie';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ErrorModal } from '../modals';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { validateEmail, validateForm, userExists, createAd, getAdData, loginUser, registerUser } from '../api';
import * as Opt from '../components/editor/Helpers';
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import ColorSelector from '../components/editor/fields/ColorSelector';

class SellerLandingForm extends React.Component {
    constructor() {
        super();

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            didReg: false,
            formData: {
                arrangement: Window.react_data.lists.arrangements[0].value //set the default value to the first option
            },
            errors: {},
            activeTab: 1
        }

        this.timeout = 0;
        this.navigation = React.createRef();
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists });
                        })
                    } else {
                        this.setState({ errorModal: true })
                    }
                })
            }, 200)
        }
    }

    createRedirectToAd() {
        this.setState({ loading: true })
        if (this.state.formData.arrangement == "grot") {
            if(this.state.didReg) {
                window.location = "/auctions/grot/create/?register=true"
            } else {
                window.location = "/auctions/grot/create/"
            }
        } else {
            createAd({ arrangement: this.state.formData.arrangement }).then((resp) => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        Cookies.set('ad_id', json.ad_id)
                        Cookies.set('curr_step', 1)
                        if(this.state.didReg) {
                            window.location = "/auctions/create/?register=true"
                        } else {
                            window.location = "/auctions/create/"
                        }
                    })
                } else {
                    this.setState({ loading: false, errorModal: true })
                }
            });
        }
    }

    goToExistingAd() {
        this.setState({ loading: true })
        if (this.state.formData.arrangement == "grot") {
            window.location = "/auctions/grot/create/"
        } else {
            getAdData(Cookies.get("ad_id")).then((resp) => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        if (json.state == 10) {
                            window.location = "/auctions/create/";
                        } else if (json.state == 20) {
                            window.location = "/auctions/" + json.id.toString() + "/review/";
                        } else {
                            this.setState({ loading: false, errorModal: true })
                        }
                    })
                } else {
                    this.setState({ loading: false, errorModal: true })
                }
            })
        }
    }


    handleSubmit(e) {
        e.preventDefault();

        let checkList = [];
        let data = this.state.formData;
        let action = null;

        // Check which fields needs to be validated
        if (this.state.isLogged) {
            action = 'continue';
            checkList.push('arrangement');
        } else if (this.state.isReg === null) {
            checkList.push('arrangement', 'email')
        } else if (this.state.isReg) {
            action = 'login';
            checkList.push('arrangement', 'email', 'password')
        } else {
            action = 'register';
            checkList.push('arrangement', 'email', 'muni', 'mobile_phone_number', 'checkbox');
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg)

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })

        } else {
            this.setState({ loading: true })
            if (action == 'continue') {
                Cookies.get('ad_id') && this.state.isLogged && data.arrangement != 'grot' ? this.setState({ loading: false, popupModal: true }) : this.createRedirectToAd();
            } else if (action == 'login') {
                loginUser({ email: data.email, password: data.password }).then(res => {
                    if (res.ok) {
                        this.setState({ isLogged: true })
                        document.getElementsByTagName('body')[0].classList.toggle("nav-update")
                        setTimeout(() => {
                            if (Cookies.get('ad_id') && data.arrangement != 'grot') {
                                this.setState({ loading: false, popupModal: true })
                            } else {
                                this.setState({ loading: true })
                                this.createRedirectToAd()
                            }
                        }, 100);
                    } else if (res.status == 401) {
                        validation.email = 'E-posten och det angivna lösenordet överensstämmer inte';
                        validation.password = 'E-posten och det angivna lösenordet överensstämmer inte';

                        this.setState({ errors: validation, loading: false })
                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })
            } else if (action == 'register') {
                this.setState({ loading: true });
                registerUser({ email: data.email, muni: data.muni, roles: [2], mobile_phone_number: data.mobile_phone_number }).then(res => {
                    if (res.ok) {
                        this.setState({ isLogged: true, loading: false })
                        document.getElementsByTagName('body')[0].classList.toggle("nav-update")
                        gtag('event', 'Registered', {});
                        gtag("event", "sign_up", { method: "Site" });

                        this.setState({ loading: true })
                        this.createRedirectToAd()

                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })
            } else {
                this.setState({ loading: false, errorModal: true })
            }
        }
    }

    render() {
        return (<>
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className={`bg-${this.props.backgroundColor} ${this.props.textColor}`} style={{ borderRadius: "40px", padding: "32px" }}>
                <div>
                    <p className="n-fs-3 fw-medium">Skapa din annons</p>
                    <p>Välj åtgärd</p>
                    <form>
                        <ul style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0 }}>
                            {Window.react_data.lists.arrangements.map((tab, index) => (
                                <li key={index} style={{ marginTop: "12px", marginRight: "8px" }}>
                                    <button
                                        className='fs-6'
                                        style={{
                                            borderRadius: "8px",
                                            padding: '4px 8px',
                                            border: 'none',
                                            backgroundColor: this.state.activeTab === index + 1 ? '#023436' : '#EEE8E2',
                                            color: this.state.activeTab === index + 1 ? '#FFFFFF' : '#025650',
                                        }}
                                        onClick={(e) => { e.preventDefault(); this.setState({ activeTab: index + 1, formData: { ...this.state.formData, arrangement: tab.value } }) }}
                                    >
                                        {tab.label}
                                    </button>
                                </li>
                            ))}
                        </ul>

                        {!this.state.isLogged ? <div style={{ marginTop: "24px" }}>
                            <VBInput
                                type="email"
                                label={<p className='subtitle mb-1 mt-3 n-fs-7' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Ange e-postadress:</p>}
                                placeholder="E-postadress"
                                error={this.state.errors.email}
                                onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                                onChange={(val) => { this.checkEmail(val); this.setState({ formData: { ...this.state.formData, email: val } }); }} />

                            {this.state.isReg === false ? <>
                                <VBMultiOption
                                    placeholder="Välj en eller flera kommuner."
                                    label={<p className='subtitle mb-1 mt-3 n-fs-7' style={{ color: "rgba(0, 0, 0, 0.40)" }}>I vilka kommuner äger du skog? (flerval)</p>}
                                    options={Window.react_data.lists.munis}
                                    autoClose={true}
                                    error={this.state.errors.muni}
                                    onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, muni: val.map(itm => parseInt(itm.value)) } }) }} />

                                <VBPhoneInput
                                    label={<p className='subtitle mb-1 mt-3 n-fs-7' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Ange mobilnummer:</p>}
                                    placeholder="Mobilnummer"
                                    error={this.state.errors.mobile_phone_number}
                                    onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, mobile_phone_number: val } }) }} />

                                <div className='d-flex flex-column' style={{ marginTop: "24px" }}>
                                    {this.state.errors?.checkbox && 
                                        <p className='text-danger n-fs-7 mb-1'>{this.state.errors?.checkbox}</p>
                                    }
                                    <div className='d-flex align-items-center'>
                                        <VBCheckbox
                                            label=""
                                            initial={false}
                                            onClick={() => { this.setState({ formData: {...this.state.formData, checkbox: !this.state.formData.checkbox}, errors: { ...this.state.errors, checkbox: '' } }) }} />
                                            
                                        <p className='mb-0 n-fs-7' style={{ color: `${this.props.termsColor}` }}>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/"  className={`text-decoration-underline ${this.props.textColor}`}>användarvillkor</a> och <a href="/privacy/" className={`text-decoration-underline ${this.props.textColor}`}>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                                    </div>
                                </div>
                            </> : null}

                            {this.state.isReg === true ? <>
                                <VBInput
                                    type="password"
                                    placeholder="Lösenord"
                                    label={<p className='subtitle mb-1 mt-3 n-fs-7' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Ange lösenord:</p>}
                                    error={this.state.errors.password}
                                    onFocus={() => this.setState({ errors: { ...this.state.errors, password: '' } })}
                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, password: val } }); }} />

                                <div className="d-flex mt-2">
                                    <a className="n-fs-7 text-decoration-underline" href="/forgot-password/" style={{color:"black"}}target="_blank">Glömt lösenordet?</a>
                                </div>
                            </> : null}
                        </div> : null}
                        
                        <div className='mt-4 d-flex'>
                            <a href="#" className='n-btn btn-core w-100' onClick={(e) => { e.preventDefault(); this.handleSubmit(e) }}>Skapa din annons<span><img className='ms-2' width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} /></span></a>
                        </div>
                    </form>
                </div>

                <Modal show={this.state.popupModal} onHide={() => this.setState({ popupModal: false })} size="lg" contentClassName="bg-neutral-cloud-200 p-3" centered>
                    <Modal.Body className="neutral-charcoal pb-0"> 
                        <p className="n-fs-4 fw-medium mb-1">Skapa Annons</p>
                        <p className='n-fs-7'>Du kan även se alla dina annonsutkast under <a className="supp-pine text-decoration-underline" href="/my-account/">Mina sidor</a>.</p>

                        <div className="card mt-4 p-3" role="button" onClick={() => this.setState({popupChoice: 'continue'})} style={{ border: `solid 2px ${this.state.popupChoice == 'continue' ? "#6A58C6" : "transparent"}` }}>
                            <p className='n-fs-5 fw-medium mb-2 d-flex justify-content-between'>Fortsätt på påbörjad annons{this.state.popupChoice == 'continue' && <img src={`${Window.static_path}img/icons/checkmark.svg`} alt="Checkmark" height="22px" />}</p>
                            <p className='n-fs-6 mb-0'>Återuppta där du lämnade och gör din annons redo för publicering.</p>
                        </div>

                        <div className="card mt-4 p-3" role="button" onClick={() => this.setState({popupChoice: 'new'})} style={{ border: `solid 2px ${this.state.popupChoice == 'new' ? "#6A58C6" : "transparent"}` }}>
                            <p className='n-fs-5 fw-medium mb-2 d-flex justify-content-between'>Skapa ny annons{this.state.popupChoice == 'new' && <img src={`${Window.static_path}img/icons/checkmark.svg`} alt="Checkmark" height="22px" />}</p>
                            <p className='n-fs-6 mb-0'>Börja från grunden och skapa din perfekta annons.</p>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="justify-content-center justify-content-lg-end border-0 mt-4">
                        <div className='float-end'>
                            <button type="button" className="n-btn btn-charcoal btn-ghost neutral-charcoal me-2" onClick={() => this.setState({ popupModal: false })}>Avbryt</button>
                            <button type="button" className="n-btn btn-forest-green" disabled={!this.state.popupChoice} onClick={() => {
                                if (this.state.popupChoice == 'continue'){
                                    this.goToExistingAd();
                                } else if(this.state.popupChoice == 'new'){
                                    Cookies.remove("ad_id");
                                    this.createRedirectToAd();
                                }

                                this.setState({ popupModal: false })}}>Fortsätt<span><img className={`${!this.state.popupChoice ? 'neutral-stone-filter' : 'white-filter'} ms-2`} width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <ErrorModal show={this.state.errorModal} onToggle={() => this.setState({errorModal: !this.state.errorModal})} />
            </div>
        </>
        )
    }
}

SellerLandingForm.defaultProps = {
    textColor: "neutral-charcoal",
    backgroundColor: "white",
}

SellerLandingForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },

}
export default SellerLandingForm;
