import React from 'react';
import TextEditor from '../editor/fields/TextEditor';
import * as Opt from '../editor/Helpers';

class Text extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-block'} w-100 quill-text ${Opt.CalculateFontSize(this.props.fontSizes)} ${this.props.textStyle} ${this.props.align} ${Opt.CalculateSpacing(this.props.spacing)}`} dangerouslySetInnerHTML={{__html: this.props.text}}></div>
        )

    }
}

Text.defaultProps = {
    visibility: "",
    text:"Lorem ipsum dolor sit amet.",
    align: 'text-start',
    spacing: [],
    fontSize: '',
    textStyle: ""
}

Text.fields={
    text: {
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} />,
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },    
    align: {
        type: "select",
        options: Opt.TextAlign
    },
    spacing: Opt.spacingOptions,
    fontSizes: Opt.fontSizeOptions,
    textStyle: {
        type: "select",
        options: Opt.TextStyles
    }   
}

export default Text;