import Cookies from 'js-cookie';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Used to validate any email address
const validateEmail = (val) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase())
}

const validateForm = (data, list, isReg) => {
    console.log(isReg)
    let validation = {}
    list.forEach(check => {
        if (check == 'arrangement' && (!data.arrangement || data.arrangement.length < 1)) {
            validation.arrangement = "Du måste välja en åtgärd";
        }
        if (check == 'area' && (!data.area || isNaN(data.area) || Number(data.area) <= 0)) {
            if (!data.area) {
                validation.area = "Du måste ange area";
            } else {
                validation.area = "Areal måste vara ett positivt heltal";
            }
        }
        if (check == 'email' && (!data.email || !validateEmail(data.email))) {
            validation.email = "Den här e-postadressen ser inte ut att vara korrekt";
        }
        if (check == 'roles' && (!data.roles || data.roles.length < 1)) {
            validation.roles = "Du måste välja någon av följande roller";
        }
        if (check == 'muni' && (!data.muni || data.muni.length < 1)) {
            validation.muni = "Du måste välja minst en kommun";
        }
        if (check == 'mobile_phone_number' && (!data.mobile_phone_number || data.mobile_phone_number.length < 1 || !isValidPhoneNumber(data.mobile_phone_number))) {
            validation.mobile_phone_number = "Telefonnumret du angav är inte korrekt";
        }
        if (check == 'password') {
            if (isReg == true && (!data.password || data.password.length < 1)) {
                validation.password = "Lösenord du angav är inte korrekt";
            } else if (isReg == false) {
                if (!data.password || data.password.length < 8) {
                    validation.password = "Du måste ange minst 8 tecken i ditt lösenord";
                } else if (data.password != data.passwordCheck) {
                    validation.password = "Lösenordet du angav överensstämmer inte";
                    validation.passwordCheck = "Lösenordet du angav överensstämmer inte";
                }
            }
        }
        if (check == 'checkbox' && !data.checkbox) {
            validation.checkbox = "Vänligen kryssa i kryssrutan";
        }
    })
    return validation
}

const validatePNR = (sPNum) => {
  var numbers = sPNum.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
  var checkSum = 0;

  if (!isDate(sPNum.substring(0,4),sPNum.substring(4,6),sPNum.substring(6,8))) {
    console.log("Datumet " + sPNum.substring(0,8) + " är inte korrekt.");
    return false;
  }

  if (numbers == null) { return false; }

  var n;
  for (var i = 3; i <= 12; i++)
  {
    n=parseInt(numbers[i]);
    if (i % 2 == 0) {
      checkSum+=n;
    } else {
      checkSum+=(n*2)%9+Math.floor(n/9)*9
    }
  }

  if (checkSum%10==0) { return true;}
  return false;
}

function getYear(y) { return (y < 1000) ? y + 1900 : y; }

function isDate(year, month, day) {
  month = month - 1; // 0-11 in JavaScript
  var tmpDate = new Date(year,month,day);
  if ( (getYear(tmpDate.getYear()) == year) &&
  (month == tmpDate.getMonth()) &&
  (day == tmpDate.getDate()) )
    return true;
  else
    return false;
}

// This function checks if the user exists and returns a boolean
const userExists = (val) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/user-api/exists/?email=" + encodeURIComponent(val), {
        method: 'GET',
        headers: headers
    })
}

// This function creates a new Ad for the user and redirects to it (needs to be logged in and give arrangement as data) eg: {arrangement: 'arrangement'}
const isWeekend = (date) => {
    return date.getDay() === 0 || date.getDay() === 6; // Sunday or Saturday
}
const createAd = (data) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    let url = "/auctions-api/create-auction/"
    if(data.arrangement == 'grot') {
        url = url+"grot/"
    }

    const start_time = new Date();
    start_time.setDate(start_time.getDate())
    const end_time = new Date(start_time);  
    end_time.setDate(end_time.getDate() + 21)
    while(isWeekend(end_time)){
        end_time.setDate(end_time.getDate()+ 1)
    }
    const felling_start_time = new Date(end_time);
    felling_start_time.setDate(end_time.getDate() + 1) 
    const felling_end_time = new Date(felling_start_time);
    felling_end_time.setDate(felling_start_time.getDate() + 730)
    
    data["start_time"] = start_time.toISOString().split('T')[0]
    data["end_time"] = end_time.toISOString().split('T')[0]
    data["felling_start_time"] = felling_start_time.toISOString().split('T')[0]
    data["felling_end_time"] = felling_end_time.toISOString().split('T')[0]

    return fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify(data)
    })
}

const updateAd = (data) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/auctions-api/auctions/"+data.id+"/", {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify(data)
    })
}

const updateGrotAd = (data) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/grot-api/update/", {
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify(data)
    })
}

const selectWinningBid = (bid_id) => {
    const headers = {
      'X-CSRFToken': Cookies.get('csrftoken'),
      "Content-Type": "application/json"
    }
    return fetch("/bid-api/select-winner/", {
        method:"POST",
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({"ad_id": Window.react_data.ad.id, "bid_id": bid_id})
    }).then(resp => resp.json()).then((json) => {
        console.log(json);
    })
  }

//Simple bid is a bid with only comments and an ad
const createSimpleBid = (comments, files, ad_id)=>{
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('attachments', file);
    });
    formData.append("comments", comments)
    formData.append("ad_id", ad_id)
    
    const headers = {
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/bid-api/simple/", {
        method:"POST",
        credentials: 'same-origin',
        headers: headers,
        body: formData
    }).then(resp => resp.json()).then((json) => {
        console.log(json);
    })
}

const uploadAdAttachments = (files, id)=>{
    const file_header = {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'Accept': 'application/json'
    }

    const header =  {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'Content-Type': 'application/json'
    }

    let responses = []
    let success_files = []

    files.forEach(file => {
        let files_list = new FormData();
        files_list.append('file', file);
        files_list.append('original_file_name', file.name);
        let url = '/auctions-api/file/'

        let response = fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            body: files_list,
            headers: file_header
        }).then(res => res.json()).then((json) => {
            url = '/auctions-api/auctionsattachment/'
            let resp = fetch(url, {
                method: 'POST', 
                credentials: 'same-origin',
                body: JSON.stringify({ad: parseInt(id), file: json.id}),
                headers: header
            }).then(res2 => res2.json()).then((json2) => {
                let item = {id: json2.id, name: json.original_file_name, url: json.file}
                success_files.push(item)
            })
            return resp;
        })
        responses.push(response);
    })

    return Promise.all(responses).then(() => {
        return true
    });
}

const deleteAdAttachments=(file_id)=>{
    let url="/auctions-api/auctionsattachment/"
    const headers = {
        'X-CSRFToken': Cookies.get('csrftoken'),
    }
    let response = fetch(url + file_id.toString() + "/", {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: headers,
       
    })

    return response;
}

const uploadGrotAdAttachments = (files, id, email)=>{

    const header =  {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'Accept': 'application/json'
    }

    let responses = []

    files.forEach(file => {
        let files_list = new FormData();
        files_list.append('file', file);
        files_list.append('original_file_name', file.name);
        files_list.append('email', email)
        files_list.append('ad_id', id.toString())
        let url = '/grot-api/attachment/'

        let response = fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            body: files_list,
            headers: header
        })
        responses.push(response);
    })

    Promise.all(responses).then(() => {
        return true
    });
}

const APIrequest = function(url, sort, data, custom_header) {
    const headers = custom_header == null ? {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    } : custom_header;

    let request = fetch(url, {
        method: sort,
        credentials: 'same-origin',
        headers: headers,
        body: data == null ? null : data
    })

    return request.then(resp => {
        if (resp.ok == false) {
            let message = 'Oops.. Something went wrong please contact your genie:\n\n'+resp.status+' - '+resp.statusText+'\n'+unixTimeConverter(Date.now())+'\n'+resp.url
            alert(message)
            return Promise.reject(resp.statusText+' - '+unixTimeConverter(Date.now()));
        } else {
            return resp
        }
    })
}

const getAdData = (ad_id) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch('/auctions-api/auctions/' + ad_id.toString() + "/", {
        method: 'GET',
        credentials: 'same-origin',
        headers: headers
    })
}

// data should include valid credentials eg. {"passowrd": password, "email": email}
const loginUser = (data) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/user-api/login/", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    });
}

// data should include valid required registration fields. (PASSWORD is optional) eg: {"email": email, "muni": muni, "roles": roles, "mobile_phone_number": mobile_phone_number}
const registerUser = (data) => {
    try{
        window.lintrk('track', { conversion_id: 14716858 });
        uet_report_conversion();
    }
    catch(error){
        console.log(error);
    }
    
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/user-api/register/", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    });
}

const getUserRights = () => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/user-api/rights/", {
        method: 'GET',
        headers: headers
    });
}

const updateUser = (data) => {
    
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }

    return fetch("/user-api/update/", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({"data": data})
    });
}

const getKlarnaCheckout = (container, coupon, sub_length) => {
    gtag('event', 'InitiateCheckout', {});
    gtag('event', 'begin_checkout', {
        "currency": "sek",
        "value": 0,
        "coupon": coupon,
        "items": [
            {
                "item_id": sub_length,
                "item_name": sub_length
            }
        ]
    });

    let url = "/primakund/klarna/";
    url = url + "?subscription_length=" + sub_length;
    if (coupon) {
        url = url + "&coupon_code=" + coupon;
    }

    return fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' }
    }).then(resp => resp.json()).then((json) => {
        if (json.errors) {
            container.innerHTML = "";
            return json
        } else {
            container.innerHTML = json.klarna_html;
            var scriptsTags = container.getElementsByTagName('script');
            // This is necessary otherwise the scripts tags are not going to be evaluated
            for (var i = 0; i < scriptsTags.length; i++) {
                var parentNode = scriptsTags[i].parentNode;
                var newScriptTag = document.createElement('script');
                newScriptTag.type = 'text/javascript';
                newScriptTag.text = scriptsTags[i].text;
                parentNode.removeChild(scriptsTags[i]);
                parentNode.appendChild(newScriptTag);
            }
            return json
        }
    });
}

export { validateEmail, validateForm, validatePNR, userExists, createAd, updateAd, selectWinningBid, createSimpleBid, uploadAdAttachments,deleteAdAttachments, getAdData, loginUser, registerUser, getUserRights, getKlarnaCheckout, updateUser, updateGrotAd, uploadGrotAdAttachments };