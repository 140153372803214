import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class Metrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} bg-${this.props.backgroundColor}`}>
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col-md-4">
                  <h2 className={`n-h2 fw-bold ${this.props.fontColor}`}>29%</h2>
                  <p className={`n-fs-5 mb-4 ${this.props.fontColor}`}>Högre virkespris</p>
                </div>
                <div className="col-md-4">
                  <h2 className={`n-h2 fw-bold ${this.props.fontColor}`}>1500+</h2>
                  <p className={`n-fs-5 mb-4 ${this.props.fontColor}`}>Virkesköpare</p>
                </div>
                <div className="col-md-4">
                  <h2 className={`n-h2 fw-bold ${this.props.fontColor}`}>65,000+</h2>
                  <p className={`n-fs-5 mb-4 ${this.props.fontColor}`}>Medlemmar</p>
                </div>
            </div>
          </div>
        </div>
    )
  }
}

Metrics.defaultProps = {
  visibility: "",
  backgroundColor: 'white-500',
  fontColor: 'neutral-charcoal',
  spacing: [],
}

Metrics.fields = {
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  fontColor: {
    type: "select",
    options: Opt.colorVariables
  }
}

export default Metrics;