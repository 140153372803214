import React from 'react';
import styled from "styled-components";

import GroupedBarGraph from '../Graphs/GroupedBarGraph';
import PieChart from '../Graphs/PieChart';
import LineChart from '../Graphs/LineChart';

import { updateSectionsProcentage, getSBPPdf } from './map_api';
import PropSelector from './PropSelector';
import PropModal from './handlers/PropModal';

import SaljaVirkeButton from "../SaljaVirkeButton";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import Modal from 'react-bootstrap/Modal';
import HelpOverblick from './handlers/help/HelpOverblick';
import Cookies from 'js-cookie';
import VBRadioGroup from '@virkesborsen/vb-js-commons/lib/components/new/vbRadioGroup';

const Span = styled.span`
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
    background-color: ${props => props.bgColor}; 
`

const PremiumCover = styled.a`
    transition: background-color ease 300ms;
    z-index: 1;
    max-width: 70%;
    &:hover {
        background-color: #023436 !important;
        color: #FFFFFF !important;
    }
`

class Overblick extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeProps: [],
            selectedProp: {},
            data: [],
            sortedData: [],
            totalVolume: 0,
            totalArea: 0,
            growthrateAverage: 0,
            totalTall: 0,
            totalGran: 0,
            totalLov: 0,
            volume: [],
            age: [],
            volumeAge: [],
            fellingClass: [],
            classification: [],
            sortedVolumeAge: [],
            area: 0,
            loading: false,
            loadingPdf: false,
            loading_message: <h2 className="white w-100 text-center pt-1">Vänta...</h2>,
            intervalID: null,
            edit_bonitet: false,
            bonitet_new_value: 0,
            edit_volume: false,
            volume_new_value: 0,
            errorModal: false,
            currentlySelectedProp: {},
            ownedProps: Window.react_data.ownedProps,
            propModalVisible: (Window.react_data.ownedProps == 0),
            showAgePerHaGraph: true
        }

        this.mapHandler = React.createRef();
    }


    setValues() {
        let sumvolume = 0, sumArea = 0, sumTall = 0, sumGran = 0, sumLov = 0, sumAreaWater = 0;
        let allGrowthRate = [];

        let totalVolumeK = 0, totalVolumeS1 = 0, totalVolumeS2 = 0, totalVolumeG1 = 0, totalVolumeG2 = 0, totalVolumeR = 0;
        let totalAreaK = 0, totalAreaS1 = 0, totalAreaS2 = 0, totalAreaG1 = 0, totalAreaG2 = 0, totalAreaR = 0;
        let fellingClassArray = [];

        let totalVolumeOne = 0, totalVolumeTwo = 0, totalVolumeThree = 0, totalVolumeFour = 0, totalVolumeFive = 0, totalVolumeSix = 0,
            totalVolumeSeven = 0, totalVolumeEight = 0, totalVolumeNine = 0, totalVolumeTen = 0, totalVolumeEleven = 0;

        let totalAreaOne = 0, totalAreaTwo = 0, totalAreaThree = 0, totalAreaFour = 0, totalAreaFive = 0, totalAreaSix = 0,
            totalAreaSeven = 0, totalAreaEight = 0, totalAreaNine = 0, totalAreaTen = 0, totalAreaEleven = 0;
        
        let volumeAgeAscendingArray = [];
        let areaAgeAscendingArray = [];
        let total_co2 = {annual_co2_growth: 0, carbon_permits: 0, climate_neutral_felling: 0, co2: 0}
        let total_solar_energy = {approx_ton_paper: 0, approx_villas: 0, arrende_per_ha: 0, mil_with_truck: 0, solar_energy: 0}
        let total_solar_ha = 0
        
        this.state.activeProps.map(prop => {
            sumAreaWater = sumAreaWater + prop.json.area

            // sum up all co2 data per property
            total_co2.annual_co2_growth += prop.json.co2_data.annual_co2_growth
            total_co2.carbon_permits += prop.json.co2_data.carbon_permits
            total_co2.climate_neutral_felling += prop.json.co2_data.climate_neutral_felling
            total_co2.co2 += prop.json.co2_data.co2

            // sum up all solar data per property
            total_solar_energy.approx_ton_paper += prop.json.solar_energy.approx_ton_paper
            total_solar_energy.approx_villas += prop.json.solar_energy.approx_villas
            total_solar_energy.arrende_per_ha += prop.json.solar_energy.arrende_per_ha
            total_solar_energy.mil_with_truck += prop.json.solar_energy.mil_with_truck
            total_solar_energy.solar_energy += prop.json.solar_energy.solar_energy

            total_solar_ha += parseInt(prop.json.solar_ha)

            prop.json.data.map(individualdata => {
                sumvolume = sumvolume + individualdata.volume
                sumArea = sumArea + parseInt(individualdata.area)
                if (parseFloat(individualdata.growth_rate) > 0) {
                    allGrowthRate.push(parseFloat(individualdata.growth_rate));
                }
                sumTall = sumTall + (Math.round(individualdata.tall * individualdata.volume / 100))
                sumGran = sumGran + (Math.round(individualdata.gran * individualdata.volume / 100))
                sumLov = sumLov + (Math.round(individualdata.lov * individualdata.volume / 100))

                //setting up per felling class total area and total volume
                if (individualdata.felling_class === 'K') {
                    totalVolumeK += individualdata.volume
                    totalAreaK += parseInt(individualdata.area)

                } else if (individualdata.felling_class === 'S1') {
                    totalVolumeS1 += individualdata.volume
                    totalAreaS1 += parseInt(individualdata.area)
                }
                else if (individualdata.felling_class === 'S2') {
                    totalVolumeS2 += individualdata.volume
                    totalAreaS2 += parseInt(individualdata.area)

                }
                else if (individualdata.felling_class === 'G1') {
                    totalVolumeG1 += individualdata.volume
                    totalAreaG1 += parseInt(individualdata.area)

                }
                else if (individualdata.felling_class === 'G2') {
                    totalVolumeG2 += individualdata.volume
                    totalAreaG2 += parseInt(individualdata.area)

                }
                else if (individualdata.felling_class === 'R') {
                    totalVolumeR += individualdata.volume
                    totalAreaR += parseInt(individualdata.area)

                }

                //setting up per age range total volume
                if (individualdata.age >= 0 && individualdata.age < 10) {
                    totalVolumeOne += individualdata.volume
                    totalAreaOne += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 10 && individualdata.age < 20) {
                    totalVolumeTwo += individualdata.volume
                    totalAreaTwo += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 20 && individualdata.age < 30) {
                    totalVolumeThree += individualdata.volume
                    totalAreaThree += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 30 && individualdata.age < 40) {
                    totalVolumeFour += individualdata.volume
                    totalAreaFour += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 40 && individualdata.age < 50) {
                    totalVolumeFive += individualdata.volume
                    totalAreaFive += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 50 && individualdata.age < 60) {
                    totalVolumeSix += individualdata.volume
                    totalAreaSix += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 60 && individualdata.age < 70) {
                    totalVolumeSeven += individualdata.volume
                    totalAreaSeven += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 70 && individualdata.age < 80) {
                    totalVolumeEight += individualdata.volume
                    totalAreaEight += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 80 && individualdata.age < 90) {
                    totalVolumeNine += individualdata.volume
                    totalAreaNine += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 90 && individualdata.age < 100) {
                    totalVolumeTen += individualdata.volume
                    totalAreaTen += parseInt(individualdata.area)
                }
                else if (individualdata.age >= 100) {
                    totalVolumeEleven += individualdata.volume
                    totalAreaEleven += parseInt(individualdata.area)
                }
            })           
        })

        fellingClassArray.push({ class: "K", area: totalAreaK, volume: totalVolumeK }, { class: "S1", area: totalAreaS1, volume: totalVolumeS1 }, { class: "S2", area: totalAreaS2, volume: totalVolumeS2 }, { class: "G1", area: totalAreaG1, volume: totalVolumeG1 }, { class: "G2", area: totalAreaG2, volume: totalVolumeG2 }, { class: "R", area: totalAreaR, volume: totalVolumeR });

        volumeAgeAscendingArray.push((totalVolumeOne), (totalVolumeTwo), (totalVolumeThree), (totalVolumeFour), (totalVolumeFive), (totalVolumeSix),(totalVolumeSeven), (totalVolumeEight), (totalVolumeNine), (totalVolumeTen), (totalVolumeEleven));

        areaAgeAscendingArray.push((totalAreaOne), (totalAreaTwo), (totalAreaThree), (totalAreaFour), (totalAreaFive), (totalAreaSix),
            (totalAreaSeven), (totalAreaEight), (totalAreaNine), (totalAreaTen), (totalAreaEleven));

        //sorting the data
        const growthrateAvg = allGrowthRate.reduce((a, b) => a + b, 0) / allGrowthRate.length
        const sortedDataAscending = [...this.state.data].sort((a, b) => a.localID - b.localID);

        const sumTGL = sumTall + sumGran + sumLov;
        const percentageTall = (sumTall / sumTGL) * 100;
        const percentageGran = (sumGran / sumTGL) * 100;
        const percentageLov = (sumLov / sumTGL) * 100;

        this.setState({
            total_co2: total_co2,
            total_solar_energy: total_solar_energy,
            total_solar_ha: total_solar_ha,
            area: sumAreaWater,
            totalVolume: sumvolume,
            totalArea: sumArea,
            growthrateAverage: growthrateAvg.toFixed(2),
            totalTall: percentageTall.toFixed(1),
            totalGran: percentageGran.toFixed(1),
            totalLov: percentageLov.toFixed(1),
            sortedData: sortedDataAscending,
            fellingClass: fellingClassArray,
            sortedVolumeAge: volumeAgeAscendingArray,
            sortedAreaAge:areaAgeAscendingArray,
        })
    }

    startLoadingPdf() {
        var loadingMessages = [
            <React.Fragment><h2 className="white w-100 text-center pt-1">Skapar rapport...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Laddar ned rapport...</h2></React.Fragment>,
        ]
        var i = 0
        var intervalID = setInterval(() => {
            this.setState({ loading_message: loadingMessages[i] })
            if (i + 1 <= loadingMessages.length - 1) {
                i += 1
            }
        }, 3000)
        this.setState({ loadingPdf: true, intervalID: intervalID });
    }

    stopLoadingPdf() {
        this.setState({ loadingPdf: false });
        clearInterval(this.state.intervalID);
    }

    startLoading() {
        var loadingMessages = [
            <><h2 className="white w-100 text-center pt-1">Hämtar laserdata...</h2></>,
            <><h2 className="white w-100 text-center pt-1">Hämtar publik data...</h2></>,
            <><h2 className="white w-100 text-center pt-1">Startar Algoritm...</h2></>,
            <><h2 className="white w-100 text-center pt-1">Beräknar avdelningar...</h2></>,
            <><h2 className="white w-100 text-center pt-1">Beräknar virkesvärden...</h2></>,
            <><h2 className="white w-100 text-center pt-1">Analyserar trädslag...</h2></>,
            <><h2 className="white w-100 text-center pt-1">Läser av trädhöjd...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></>,
            <><h2 className="white w-100 text-center pt-1">Analyserar marken...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></>,
            <><h2 className="white w-100 text-center pt-1">Bedömer risker för barkborre och brand...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></>,
            <><h2 className="white w-100 text-center pt-1">hämtar virkespriser...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></>,
            <><h2 className="white w-100 text-center pt-1">Analyserar trädslag<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></>,
            <><h2 className="white w-100 text-center pt-1">Beräknar risker...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></>,
            <><h2 className="white w-100 text-center pt-1">Slutför, detta kan ta några minuter</h2><p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></>
        ]
        var i = 0
        var intervalID = setInterval(() => {
            this.setState({ loading_message: loadingMessages[i] })
            if (i + 1 <= loadingMessages.length - 1) {
                i += 1
            }
        }, 5000)
        this.setState({ loading: true, intervalID: intervalID });
    }

    stopLoading() {
        this.setState({ loading: false });
        clearInterval(this.state.intervalID);
    }

    changeBonitet() {
        this.setState({ loading: true });
        let name = "growth_rate"
        let curr_avg = (this.state.activeProps[0].json.data.reduce((a, b) => a + parseFloat(b.growth_rate), 0)) / this.state.activeProps[0].json.data.filter(itm => parseFloat(itm.growth_rate) > 0).length

        if (this.state.bonitet_new_value == curr_avg) {
            this.setState({ loading: false });
            return;
        }

        let value = this.state.bonitet_new_value / curr_avg;

        let json = updateSectionsProcentage(this.state.activeProps[0].info.value, name, value);
        json.then((js) => {
            let activeProps = this.state.activeProps
            activeProps[0].json.data = js.data
            this.setState({ edit_bonitet: false, activeProps: activeProps, loading: false });
            this.setValues();
        })
    }

    changeVolume() {
        this.setState({ loading: true });
        let name = "volume"
        let curr_tot = (this.state.activeProps[0].json.data.reduce((a, b) => a + parseFloat(b.volume), 0))

        if (this.state.volume_new_value == curr_tot) {
            this.setState({ loading: false, edit_volume: false });
            return;
        } else if (this.state.volume_new_value == 0) {
            this.setState({ volume_new_value: curr_tot, loading: false, edit_volume: false, errorModal: true });
            return;
        } else if (curr_tot == 0) {
            this.setState({ loading: false, edit_volume: false });
            return null;
        }

        let value = this.state.volume_new_value / curr_tot;

        let json = updateSectionsProcentage(this.state.activeProps[0].info.value, name, value);
        json.then((js) => {
            let activeProps = this.state.activeProps
            activeProps[0].json.data = js.data
            this.setState({ edit_volume: false, activeProps: activeProps, loading: false }, () => this.setValues());
        })
    }

    getAlderChartImg(element_alder_chart) {
        
        let alder_chart = html2canvas(element_alder_chart).then(canvas => {
            let alder_chart = canvas.toDataURL('image/png');
            this.setState({alder_chart: alder_chart})
            element_alder_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4 shadow'
            return alder_chart
        })
        return alder_chart
    }

    getPriceChartImg(element_price_chart) {

        let price_chart = html2canvas(element_price_chart).then(canvas => {
            let price_chart = canvas.toDataURL('image/png');
            this.setState({price_chart: price_chart})
            element_price_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4 shadow'
            return price_chart
        })
        return price_chart
    }

    getTreeChartImg(element_tree_chart) {
        
        let tree_chart = html2canvas(element_tree_chart).then(canvas => {
            let tree_chart = canvas.toDataURL('image/png');
            this.setState({tree_chart: tree_chart})
            element_tree_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4 shadow'
            return tree_chart
        })
        return tree_chart
    }

    downloadSBPPdf(e) {
        this.startLoadingPdf();
        let element_alder_chart = document.getElementById('alder_chart');
        let element_tree_chart = document.getElementById('tree_chart');
        let element_price_chart = document.getElementById('price_chart');
        if (window.outerWidth > 1800) {
            element_alder_chart.className = 'bg-white neutral-charcoal rounded w-75 p-2 p-lg-4'
            element_tree_chart.className = 'bg-white neutral-charcoal rounded w-50 p-2 p-lg-4' 
            // element_price_chart.className = 'bg-white charcoal rounded w-50 p-2 p-lg-4'
        } else if (window.outerWidth < 600) {
            element_alder_chart.className = 'bg-white neutral-charcoal rounded w-125 p-2 p-lg-4'
            element_tree_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4'
            // element_price_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4'
        } else {
            element_alder_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4'
            element_tree_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4'
            // element_price_chart.className = 'bg-white neutral-charcoal rounded h-100 p-2 p-lg-4'
        }
        setTimeout( () => {
            this.getAlderChartImg(element_alder_chart).then((res) => {
                this.getTreeChartImg(element_tree_chart).then((res) => {
                    this.fetchSBPPdf();
                })
            })
        }, 200);
        
        // this.getPriceChartImg(); Not used at the moment.
        
    }

    fetchSBPPdf() {
 
        getSBPPdf({"alder_chart": this.state.alder_chart, "price_chart": this.state.price_chart, "tree_chart": this.state.tree_chart}, this.state.activeProps).then((res) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = res.file;
            downloadLink.download = "sbp.pdf";
            downloadLink.click();
            setTimeout(() => this.stopLoadingPdf(), 4000);
        });
    }

    setPriceLineData() {
        let curr_year = new Date().getFullYear();
        let data = {}
        let color = ''
        let bar = []
        
        this.state.activeProps.map(prop => {
            let growth_per_year = (prop.json.data.reduce((a, b) => a + parseFloat(b.growth_rate), 0))
            let total_vol = (prop.json.data.reduce((a, b) => a + parseFloat(b.volume), 0))

            color = prop.json.valuationChartData.line[0].color
            bar = prop.json.valuationChartData.bar

            for (let i=0; i < prop.json.valuationChartData.years.length; i++) {
                let year = prop.json.valuationChartData.years[i];
    
                let year_diff = curr_year - year;
                let growth_during_period = year_diff * growth_per_year
                let vol_historic = (total_vol - growth_during_period)
    
                prop.json.valuationChartData.line.forEach((itm) => {
                    if (itm.data.length > 0) {
                        if (data[year]) {
                            data[year] += Math.round((itm.data[i] * vol_historic) / 100000) * 100000
                        } else {
                            data[year] = Math.round((itm.data[i] * vol_historic) / 100000) * 100000
                        }
                    }
                })
            }
        })

        let data_list = []
        let year_list = []
        for (let year in data) {
            data_list.push(data[year])
            year_list.push(year)
        }

        let valuationChartData = {
            bar: bar,
            line: [{
                color: color,
                data: data_list
            }],
            years: year_list
        }
        
        this.setState({ valuationChartData: valuationChartData })
    }

    handleHelpPopup() {
        const helpCookieName = `help_overblick_${Window.user_data.loggedInUserID}`;
        const helpCookie = Cookies.get(helpCookieName);

        // If there is no cookie for this user ID then show modal
        if (!helpCookie) {
            let expirationDate = new Date()
            expirationDate.setDate(expirationDate.getDate() + 60)
            // Set cookie with the current user ID
            Cookies.set(helpCookieName, 'completed', { expires: expirationDate })
            this.setState({ helpModalsVisible: true })
        }
    }

    propertyFinish(propsData) {
        this.setState({ activeProps: propsData }, () => {
            Promise.all([this.setPriceLineData(), this.setValues()]).then(() => {
                this.setState({ loading: false })
                this.handleHelpPopup()
            })
        })
    }

    render() {
        return <>
            <div className='container-fluid py-5 bg-white vh-100 overflow-auto'>
                <h1 className="mt-3">Överblick</h1>
                {this.state.data.length == 0 ?<div className="row">
                        <div className="col-12">
                            <h4>Välj fastighet i listan nedan</h4>
                        </div>
                    </div>:null}
                
                <div className="d-flex py-2 row align-items-center mb-3">
                    <div className='col-md-6 col-xl-4 row'>           
                        <div className='col-11'>
                            <PropSelector
                                onDivideStart={() => this.startLoading()}
                                onDivideFinish={data => this.propertyFinish(data)}
                                deleteProps={(deletedProp) => {
                                    if (this.state.selectedProp.value === deletedProp.value) {
                                        //this.setState({data:[]})
                                    }
                                }}
                                activeProps={this.state.activeProps}
                                ownedProps={this.state.ownedProps} />

                        </div>
                       <div className='col-1 p-2'>
                            <button className='rounded bg-supp-stone border-0' style={{ width:'48px', height:'48px' }} onClick={() => this.setState({ helpModalsVisible: true })}>
                                <img src={`${Window.static_path}img/icons/BsInfoLg.svg`} role="button" className="rounded border-0 charcoal-filter" width="24px" height="24px"></img>
                            </button>  
                        </div>  
                    </div>

                    <div className="col-12 col-xl-6 ms-md-4 mt-3 mt-md-0 ">
                        {this.state.selectedProp ? <SaljaVirkeButton extra_ad_data={{"description_of_property": this.state.selectedProp.label}} className="n-btn btn-core">Sälja virke</SaljaVirkeButton> :null }
                        {this.state.selectedProp && this.state.activeProps.length < 2 && window.location.pathname.includes('sudo') ? <button className="btn green bg-white mx-md-2 mt-1" onClick={(e) => this.downloadSBPPdf(e)}>Hämta Pdf</button> :null }
                    </div> 
                </div>              

                <VBLoadingScreen
                    show_loading={this.state.loading}
                    disable_reload_timeout={true}
                    loading_text={this.state.loading_message} />
                <VBLoadingScreen
                    show_loading={this.state.loadingPdf}
                    disable_reload_timeout={true}
                    loading_text={this.state.loading_message} />

               
                {this.state.sortedData && this.state.valuationChartData &&
                    <>
                        <div className='row g-3 mb-3'>
                            <div className='col-12 col-lg-3'>
                                <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                                    <h5 className="fw-medium">Totalvolym {this.state.activeProps.length < 2 ? <span role="button" onClick={() => { this.setState({ edit_volume: !this.state.edit_volume }) }}><img src={Window.static_path + "img/icons/ui-edit.svg"} id="edit_volume" className="charcoal-filter" width="20px" height="20px" style={{maxHeight: '70vh'}} /></span>: null}</h5>
                                    {this.state.edit_volume ?
                                        <>
                                            <VBInput type="number" initial={this.state.totalVolume} onChange={(val) => { this.setState({ volume_new_value: val.replace(",", ".") }) }} />
                                            <button className='btn btn-secondary btn-sm my-3' onClick={() => { this.changeVolume(); }}>Spara</button>
                                        </>
                                        : <h3><span className="fw-medium">{(this.state.totalVolume).toLocaleString('sv-SE')}</span> m³sk</h3>}
                                </div>
                            </div>

                            <div className='col-12 col-lg-3'>
                                <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                                    <h5 className="fw-medium">Areal inklusive vatten</h5>
                                    <h3><span className="fw-medium">{(this.state.area).toLocaleString('sv-SE')}</span> ha</h3>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className="col-12 col-lg-68 position-relative">
                                {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-neutral-charcoal text-center white subtitle position-absolute top-50 start-50 translate-middle rounded p-3">Bli <b>Pluskund</b> för att se estimerat fastighetsvärde</PremiumCover> : null}
                                <div id="price_chart" className={`bg-neutral-cloud shadow rounded p-2 p-lg-4 ${!Window.user_data.isPremium ? 'user-select-none' : ''}`} style={!Window.user_data.isPremium ? { filter: "blur(9px)" } : {}}>
                                    <LineChart
                                        title={<h5 className="fw-medium">Fastighetsvärde</h5>}
                                        description={"Priserna utgår från ditt uppmätta virkesförråd"}
                                        data={{
                                            labels: this.state.valuationChartData.years,
                                            datasets: this.state.valuationChartData.line.map((itm) => {
                                                return {
                                                    label: "Pris",
                                                    data: itm.data,
                                                    backgroundColor: itm.color,
                                                    borderWidth: 4,
                                                    borderColor: itm.color,
                                                    borderCapStyle: 'butt',
                                                    borderDash: [],
                                                    borderDashOffset: 0.0,
                                                    borderJoinStyle: 'miter',
                                                    pointBorderColor: itm.color,
                                                    pointBackgroundColor: '#fff',
                                                    pointBorderWidth: 5,
                                                    pointRadius: 10,
                                                    pointHitRadius: 10,
                                                    pointHoverRadius: 8,
                                                    pointHoverBackgroundColor: '#fff',
                                                    pointHoverBorderColor: itm.color,
                                                    pointHoverBorderWidth: 5,
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row g-3 mb-3' >
                            <div className={`col-12 ${this.state.total_solar_ha >= 5 ? 'col-lg-6' : 'col-lg-8'} position-relative`}>
                                {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-neutral-cloud neutral-charcoal text-center subtitle position-absolute top-50 start-50 translate-middle rounded p-3">Bli <b>Pluskund</b> för att se huggningsklasser</PremiumCover> : null}
                                <div className={`shadow rounded bg-neutral-cloud neutral-charcoal p-2 h-100 p-lg-4 ${!Window.user_data.isPremium ? 'user-select-none' : ''}`} style={!Window.user_data.isPremium ? { filter: "blur(9px)" } : {}}>
                                    <h5 className='fw-medium'>Huggningsklasser</h5>
                                    <p className='subtitle'>Uppskattad fördelning av olika ägoslag</p>
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className='fw-medium' scope="col">Huggningsklass</th>
                                                <th className='fw-medium' scope="col">Total areal (ha)</th>
                                                <th className='fw-medium' scope='col'>Total volym (m³sk)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.fellingClass.map((individualData) => {
                                                return <tr>
                                                    <td>{individualData.class}</td>
                                                    <td>{individualData.area}</td>
                                                    <td>{individualData.volume}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div id="solar-energy" className={`col-12 ${this.state.total_solar_ha >= 5 ? 'col-lg-6' : 'col-lg-4'}`}>
                                <div className='shadow rounded bg-neutral-stone supp-forest-green h-100 p-2 p-lg-4'>
                                    {this.state.total_solar_ha >= 5 ? <>
                                        <h5 className="fw-medium">Solenergiproduktion</h5>
                                        <p>Du har <span className="h5 fw-medium tertiary-main">{Math.round(this.state.total_solar_ha).toLocaleString('sv-SE')}</span> hektar lämplig för storskalig solenergiproduktion.</p>
                                        <p className="mb-1">Estimerat arrendevärde:</p>
                                        <h2 className="tertiary-main fw-medium">{Math.round(this.state.total_solar_ha * this.state.total_solar_energy.arrende_per_ha).toLocaleString('sv-SE')} <span className="h5">kr/år</span></h2>
                                        <p className="mb-1">Uppskattad energiproduktion:</p>
                                        <h2 className="tertiary-main fw-medium">{Math.round(this.state.total_solar_ha * this.state.total_solar_energy.solar_energy).toLocaleString('sv-SE')} <span className="h5">MWh/år</span></h2>

                                        <p className="mb-1">Det motsvarar:</p>
                                        <div className="d-flex align-items-center">
                                            <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                            <p className="fw-medium mb-0">{Math.round(this.state.total_solar_ha * this.state.total_solar_energy.mil_with_truck).toLocaleString('sv-SE')} mil med eldriven lastbil.</p>
                                        </div>

                                        <div className="d-flex align-items-center my-1">
                                            <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                            <p className="fw-medium mb-0">Tillverkning av {Math.round(this.state.total_solar_ha * this.state.total_solar_energy.approx_ton_paper).toLocaleString('sv-SE')} ton kontorspapper.</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                            <p className="fw-medium mb-0">Årsförbrukningen i {Math.round(this.state.total_solar_ha * this.state.total_solar_energy.approx_villas).toLocaleString('sv-SE')} villor.</p>
                                        </div>
                                        
                                        <p className="subtitle mt-2 mb-0">Om du vill ha en mer nogrann bedömning eller har andra frågor, kontakta vår partner <a className="hover-primary-core fw-medium" onClick={() => {mixpanel.track("Clicked Recap mailto"); window.location.href="mailto:christer.boman@recap.se?subject=Jag vill veta mer om storskalig solenergiproduktion";}} href="#">Recap Energy (recapenergy.com)</a></p>
                                    </> : <>
                                        <h5 className="fw-medium">Potential för storskalig solenergiproduktion</h5>
                                        <p className="fw-medium"><img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="supp-pine-filter me-1" />Nej</p>
                                    </>}
                                </div>
                            </div>
                        </div>

                        <div className='row g-3 mb-3' >
                            <div className='col-12 col-lg-6 position-relative'>
                                {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-neutral-charcoal hover-supp-forest-green text-center white subtitle position-absolute top-50 start-50 translate-middle rounded p-3">Bli <b>Pluskund</b> för att se trädslagsfördelningen</PremiumCover> : null}
                                <div id="tree_chart" className={`shadow rounded bg-neutral-cloud h-100 neutral-charcoal p-2 p-lg-4 mb-lg-2 ${!Window.user_data.isPremium ? 'user-select-none' : ''}`} style={!Window.user_data.isPremium ? { filter: "blur(9px)" } : {}}>
                                    <PieChart {...{
                                        topRight: <p className='caption fw-medium'>Tall %<br /> Gran %<br /> Löv %</p>,
                                        title: <h5 className="fw-medium">Trädslagsfördelning</h5>,
                                        description: 'Estimerad trädslagsfördelning',
                                        page: 'sbp',
                                        legend: <ul className='d-flex flex-column py-4' style={{ listStyle: "none" }}>
                                            <li className='caption fw-medium'><Span bgColor="#00ABAC"></Span> &nbsp; Tall: {Math.trunc(this.state.totalTall)} %</li>
                                            <li className='caption fw-medium'><Span bgColor="#C4D0CD"></Span> &nbsp; Gran: {Math.trunc(this.state.totalGran)} %</li>
                                            <li className='caption fw-medium'><Span bgColor="#EAEEED"></Span> &nbsp; Löv: {Math.trunc(this.state.totalLov)} %</li>
                                        </ul>,
                                        options: {
                                            plugins: {
                                                tooltip: {
                                                    enabled: true
                                                },
                                                datalabels: {
                                                    color: 'black',
                                                },
                                            },
                                            legend: {
                                                display: false,
                                            },
                                        },
                                        data: {
                                            datasets: [
                                                {
                                                    label: '# of Votes',
                                                    data: [(Math.trunc(this.state.totalTall)), (Math.trunc(this.state.totalGran)), (Math.trunc(this.state.totalLov))],
                                                    backgroundColor: [
                                                        '#00ABAC',
                                                        '#A2CCB7',
                                                        '#EAEEED',
                                                    ],
                                                    borderColor: [
                                                        '#00ABAC',
                                                        '#A2CCB7',
                                                        '#EAEEED',
                                                    ],
                                                    borderWidth: 1,
                                                },
                                            ],
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 position-relative">
                            <div className="col-12 pb-2" id="carbon">
                                <div className="shadow rounded bg-neutral-stone neutral-charcoal p-2 p-lg-4 mb-0">
                                <h5 className="fw-medium neutral-charcoal">Koldioxidlagring</h5>
                                    {/* <p className="white subtitle">Så mycket bidrar din skog till klimatet</p> */}
                                <div className="d-flex flex-wrap">
                                    <div className="col-12">
                                        <p className="neutral-charcoal subtitle mb-1">Totalt för hela fastigheten:</p>
                                        <div className="col-12">
                                            <div className="d-flex align-items-center">
                                            <img src={`${Window.static_path}img/icons/co2-level.svg`} height="30px" className="supp-forest-green-filter me-3"/>
                                            <h2 className="supp-forest-green mb-0 me-1">{Math.round(this.state?.total_co2?.co2).toLocaleString('sv-SE')}</h2><span className="h5 supp-forest-green mb-0">ton CO²</span>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <p className="neutral-charcoal subtitle mb-1">Årligt upptag:</p>
                                        <div className="col-12">
                                            <div className="d-flex align-items-center"> 
                                            <img src={`${Window.static_path}img/icons/graph-up-arrow.svg`} height="30px" className="supp-forest-green-filter me-3"/>
                                            <h2 className="supp-forest-green mb-0 me-1">{this.state?.total_co2?.annual_co2_growth.toLocaleString('sv-SE')}</h2><span className="h5 supp-forest-green mb-0">ton CO²/år</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="col-12 mt-1">
                                        <p className="neutral-charcoal subtitle mb-1">Utsläppsrätter motsvarande:</p>
                                        <div className="col-12">
                                            <div className="d-flex align-items-center"> 
                                            <img src={`${Window.static_path}img/icons/co2_euro.svg`} height="30px" className="supp-forest-green-filter me-3"/>
                                            <h2 className="supp-forest-green mb-0 me-1">{this.state?.total_co2?.carbon_permits.toLocaleString('sv-SE')}</h2><span className="h5 supp-forest-green mb-0">kr/år</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <p className="neutral-charcoal subtitle mb-1">Klimatneutral Avverkning:</p>
                                        <div className="col-12">
                                            <div className="d-flex align-items-center"> 
                                            <img src={`${Window.static_path}img/icons/co2_felling.svg`} height="30px" className="supp-forest-green-filter me-3"/>
                                            <h2 className="supp-forest-green mb-0 me-1">{this.state?.total_co2?.climate_neutral_felling.toLocaleString('sv-SE')}</h2><span className="h5 supp-forest-green mb-0">m3/år</span>
                                            </div>
                                        </div>
                                        <p className="neutral-charcoal caption mt-1 mb-0">(CO² beräkningar baserade på <a className="supp-forest-green caption hover-md-white" target="_blank" href="https://www.skogforsk.se/cd_20230404142305/contentassets/53009dfcdb79481cadc8c9b7d8becbf6/arbetsrapport-1156-2023-berakningar-av-kolbalanser-for-sveaskogs-innehav.pdf">Skogforsks rapport</a>)</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 position-relative'>
                                {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-neutral-charcoal white text-center subtitle position-absolute top-50 start-50 translate-middle rounded p-3">Bli <b>Pluskund</b> för att se medelbonitet</PremiumCover> : null}
                                <div className={`shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4 ${!Window.user_data.isPremium ? 'user-select-none' : ''}`} style={!Window.user_data.isPremium ? { filter: "blur(9px)" } : {}}>
                                    <h5 className='fw-medium'>Medelbonitet {this.state.activeProps.length < 2 ? <span role="button" onClick={() => { this.setState({ edit_bonitet: !this.state.edit_bonitet }) }}><img src={Window.static_path + "img/icons/ui-edit.svg"} id="edit_bonitet" className="neutral-charcoal-filter" width="20px" height="20px" /></span>: null}</h5>
                                    {this.state.edit_bonitet ?
                                        <>
                                            <VBInput type="number" initial={this.state.growthrateAverage} onChange={(val) => { this.setState({ bonitet_new_value: val.replace(",", ".") }) }} />
                                            <button className='btn btn-secondary btn-sm my-3' onClick={() => { this.changeBonitet(); }}>Spara</button>
                                        </>
                                        : <h3><span className="fw-medium">{Math.round(this.state.growthrateAverage)}</span> m³sk/ha/år</h3>}
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className='row g-3 mb-3' >
                            {/* <div className='col-12 position-relative'>
                                {!Window.user_data.isPremium ? <PremiumCover href="/primakund/" target="_blank" className="bg-neutral-cloud neutral-charcoal text-center subtitle position-absolute top-50 start-50 translate-middle rounded p-3">Bli <b>Pluskund</b> för att se huggningsklasser</PremiumCover> : null}
                                <div className={`shadow rounded bg-white neutral-charcoal p-2 p-lg-4 ${!Window.user_data.isPremium ? 'user-select-none' : ''}`} style={!Window.user_data.isPremium ? { filter: "blur(9px)" } : {}}>
                                    <h5 className='fw-medium'>Huggningsklasser</h5>
                                    <p className='subtitle'>Uppskattad fördelning av olika ägoslag</p>
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className='fw-medium' scope="col">Huggningsklass</th>
                                                <th className='fw-medium' scope="col">Total areal (ha)</th>
                                                <th className='fw-medium' scope='col'>Total volym (m³sk)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.fellingClass.map((individualData) => {
                                                return <tr>
                                                    <td>{individualData.class}</td>
                                                    <td>{individualData.area}</td>
                                                    <td>{individualData.volume}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                        </div>
                        <div className='row g-3 mb-3' >
                            <div className='col-12 col-lg-8'>
                                <div id="alder_chart" className='bg-neutral-cloud neutral-charcoal rounded h-100 p-2 p-lg-4 shadow'>
                                    <div className='row container-fluid'>
                                        <h5 className="fw-medium col-9">Åldersfördelning</h5>
                                        <div className='caption fw-medium col-3'>
                                            <VBRadioGroup
                                                options={[{ value: true, label: "Areal (ha)" }, { value: false, label: "Volym (m³sk)" }]}
                                                initial={this.state.showAgePerHaGraph}
                                                onChange={(val) => { this.setState({ showAgePerHaGraph: val }) }} />
                                        </div>
                                    </div>
                                    {this.state.showAgePerHaGraph ? <GroupedBarGraph {...{
                                        topRight: <React.Fragment><p className='caption fw-medium'>Y-axeln: <span> Areal (ha)</span> </p><p className='caption fw-medium'>X-axeln: <span>Ålder</span></p></React.Fragment>,
                                        topRight: <div>
                                            <p className='caption fw-medium mb-1'>Y-axeln: Areal (ha)</p>
                                            <p className='caption fw-medium'>X-axeln: Ålder</p>
                                        </div>,
                                        description: 'Estimerad åldersfördelning',
                                        page: 'sbp',
                                        data: {
                                            labels: ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100", "100+"],
                                            datasets: [
                                                {
                                                    label: "Areal",
                                                    data: this.state.sortedAreaAge,
                                                    backgroundColor: '#A2CCB7',
                                                    borderSkipped: false,
                                                    borderWidth: 1,
                                                    borderRadius: 50,
                                                    categoryPercentage: 0.4,
                                                    barPercentage: 1.0
                                                },

                                            ],
                                        },
                                        options: {
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: {
                                                    enabled: true
                                                },
                                                datalabels: {
                                                    color: 'transparent'
                                                }
                                            },
                                            scales: {
                                                x: {
                                                    ticks: {
                                                        color: '#1C332A',
                                                        fontSize: 10,
                                                        fontWeight: 'bold',
                                                        autoSkip: false,
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    ticks: {
                                                        color: '#1C332A',
                                                        fontSize: 60,
                                                        fontWeight: 'bold',
                                                        beginAtZero: true,
                                                        min: 0,
                                                        max: 1000,
                                                        stepSize: 10,
                                                        grace: "0.005%",
                                                        callback: function (value, index, ticks) {
                                                            return value + " " + '-';
                                                        },
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false,
                                                    },
                                                },
                                            },
                                            maintainAspectRatio: false,
                                            responsive: true
                                        }
                                    }} />:<GroupedBarGraph {...{
                                        topRight: <React.Fragment><p className='caption fw-medium'>Y-axeln: <span> Volym (m³sk)</span> </p><p className='caption fw-medium'>X-axeln: <span>Ålder</span></p></React.Fragment>,
                                        topRight: <div>
                                            <p className='caption fw-medium mb-1'>Y-axeln: Volym (m³sk)</p>
                                            <p className='caption fw-medium'>X-axeln: Ålder</p>
                                        </div>,
                                        description: 'Estimerad åldersfördelning',
                                        page: 'sbp',
                                        data: {
                                            labels: ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100", "100+"],
                                            datasets: [
                                                {
                                                    label: "Volym",
                                                    data: this.state.sortedVolumeAge,
                                                    backgroundColor: '#037971',
                                                    borderSkipped: false,
                                                    borderWidth: 1,
                                                    borderRadius: 50,
                                                    categoryPercentage: 0.4,
                                                    barPercentage: 1.0
                                                },

                                            ],
                                        },
                                        options: {
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: {
                                                    enabled: true
                                                },
                                                datalabels: {
                                                    color: 'transparent'
                                                }
                                            },
                                            scales: {
                                                x: {
                                                    ticks: {
                                                        color: '#1C332A',
                                                        fontSize: 10,
                                                        fontWeight: 'bold',
                                                        autoSkip: false,
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    ticks: {
                                                        color: '#1C332A',
                                                        fontSize: 60,
                                                        fontWeight: 'bold',
                                                        beginAtZero: true,
                                                        min: 0,
                                                        max: 3000,
                                                        stepSize: 100,
                                                        grace: "0.005%",
                                                        callback: function (value, index, ticks) {
                                                            return value + " " + '-';
                                                        },
                                                    },
                                                    grid: {
                                                        drawBorder: false,
                                                        display: false,
                                                    },
                                                },
                                            },
                                            maintainAspectRatio: false,
                                            responsive: true
                                        }
                                    }} />}
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                            <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                            <h5 className="fw-medium">Har du virke eller GROT att sälja? </h5>
                            <br/>
                            <p>Vi hjälper dig ta in anbud!</p>
                            <p>✓ Kostnadsfritt – Vi tar betalt från skogsbolagen.</p>
                            <p>✓ Fri prövningsrätt och inga förbindelser.</p>
                            <p>✓ Nå ut till en bredare köparbas för det bästa priset.</p>
                            <p>✓ Dedikerat stöd och rådgivning under hela processen.</p>
                            <h5 className='fw-medium'><a href="/auctions/grot/create/" className='supp-forest-green'>Klicka här för en prisförfrågan.</a></h5> 
                                </div>
                            </div>
                        </div>
                        <div className='row g-3 mb-3' >
                            <div className='col-12'>
                                <div className='shadow rounded bg-neutral-stone neutral-charcoal overflow-auto p-2 p-lg-4' style={{ height: "500px" }}>
                                    <h5 className='fw-medium'>Avdelningsbeskrivning</h5>
                                    <p className='subtitle'>Sammanfattning av samtliga avdelningar</p>
                                    <table className="table table-borderless table-striped position-relative">
                                        <thead className="position-sticky bg-neutral-stone" style={{ "top": "-30px", "height": "60px" }}>
                                            <tr>
                                                {this.state.activeProps.length > 1 ? <th className='fw-medium' scope="col">Fastighet </th> : null}
                                                <th className='fw-medium' scope="col">Avd # </th>
                                                <th className='fw-medium' scope="col">Area</th>
                                                <th className='fw-medium' scope='col'>Ålder</th>
                                                <th className='fw-medium' scope='col'>HKL</th>
                                                <th className='fw-medium' scope='col'>SI</th>
                                                <th className='fw-medium' scope='col'>Volym</th>
                                                <th className='fw-medium' scope='col'>T | G | L</th>
                                                <th className='fw-medium' scope='col'>Bonitet</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.activeProps.map(prop => {
                                                return prop.json.data.map(i => {
                                                    let majorTree = "B"
                                                    if (i.tall > i.gran && i.tall > i.lov) {
                                                        majorTree = "T"
                                                    } else if (i.gran > i.tall && i.gran > i.lov) {
                                                        majorTree = "G"
                                                    }
                                                    return <tr>
                                                        {this.state.activeProps.length > 1 ? <td>{prop.info.label}</td> : null}
                                                        <td>{i.localID}</td>
                                                        <td>{i.area}</td>
                                                        <td>{i.age}</td>
                                                        <td>{i.felling_class}</td>
                                                        <td>{i.si > 0 ? <React.Fragment>{majorTree}{i.si}</React.Fragment> : null}</td>
                                                        <td>{i.volume}</td>
                                                        <td>{i.tall} | {i.gran} | {i.lov}</td>
                                                        <td>{Math.round(i.growth_rate)}</td>
                                                    </tr>
                                                })
                                            })}

                                            {this.state.sortedData.map((data) => {
                                                let majorTree = "B"
                                                if (data.tall > data.gran && data.tall > data.lov) {
                                                    majorTree = "T"
                                                } else if (data.gran > data.tall && data.gran > data.lov) {
                                                    majorTree = "G"
                                                }
                                                return <tr>
                                                    <td>{data.localID}</td>
                                                    <td>{data.area}</td>
                                                    <td>{data.age}</td>
                                                    <td>{data.felling_class}</td>
                                                    <td>{data.si > 0 ? <React.Fragment>{majorTree}{data.si}</React.Fragment> : null}</td>
                                                    <td>{data.volume}</td>
                                                    <td>{data.tall} | {data.gran} | {data.lov}</td>
                                                    <td>{Math.round(data.growth_rate)}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>}

                <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} contentClassName="bg-red-alert p-2" centered>
                    <Modal.Body>
                        <h2 className="neutral-charcoal">Oops! Något gick fel.</h2>
                        <p className='neutral-charcoal py-3'>Ring <a href="tel:08339944" className="fw-medium supp-forest-green">08-33 99 44</a> så hjälpa vi dig vidare.</p>
                        <button type="button" className="n-btn btn-forest-green btn-sm float-end" onClick={() => this.setState({ errorModal: false })}>Fortsätt</button>
                    </Modal.Body>
                </Modal>

                {/*NEW USER OR USER WITHOUT PROPERTY POPUP */}
                <PropModal
                    visible={this.state.propModalVisible}
                    onHide={() => {this.setState({propModalVisible:false})}}
                    onDivideStart={() => this.startLoading()}
                    onDivideFinish={data => this.propertyFinish(data)}
                    updateOwnedProps={arr => this.setState({ownedProps: arr})} />
            </div>
            {this.state.helpModalsVisible && <HelpOverblick onClose={() => this.setState({ helpModalsVisible: false })} />}
        </>
    }
}
export default Overblick;