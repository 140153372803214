import React from "react";
import Navigation from "../../components/static/Navigation";
import Footer from "../../components/static/Footer";
import VBLeaflet from "@virkesborsen/vb-js-commons/lib/components/vbLeaflet";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import Card from 'react-bootstrap/Card';
import Cookies from 'js-cookie';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';
import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';

class Auctions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ads: Window.react_data.ads,
            sold_ads: Window.react_data.sold_ads,
            regions: Window.react_data.regions,
            wood_types: Window.react_data.wood_types,
            trees: Window.react_data.trees,
            ad_types: Window.react_data.ad_types,
            url_quoted_company_names: Window.react_data.url_quoted_company_names,
            sort: Window.react_data.sort,
            form: Window.react_data.form,
            no_results: Window.react_data.no_results,
            checked: false, //this is either true or false
            showLoading: false,
        }

        this.navigation = React.createRef();
        this.map = React.createRef();

    }
    setUpMap(allAds) {
        setTimeout(() => {
            const map = this.map.current.state.map;
            map.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    map.removeLayer(layer);
                }
            });
            if (map && allAds.length > 0) {

                const bounds = L.latLngBounds();

                allAds.forEach((ad) => {
                    const { latitude, longitude, id } = ad;

                    if (latitude !== null && longitude !== null) {
                        const isAd = this.state.ads.includes(ad)
                        if (isAd) {
                            const path = "img/map-markers/marker_pin_secondary-orange.svg";
                            const icon = L.icon({
                                iconUrl: Window.static_path + path,
                                iconSize: [32, 32],
                                shadowSize: [32, 32],
                                iconAnchor: [16, 32],
                                shadowAnchor: [16, 32],
                                popupAnchor: [0, -16]
                            });

                            let marker = L.marker([latitude, longitude], { icon: icon }).setZIndexOffset(1000).addTo(map)

                            marker.on("click", function () {

                                window.location.href = `/auctions/${id}`

                            });

                        }
                        else {
                            const path = "img/map-markers/marker_pin_primary-main.svg"
                            const icon = L.icon({
                                iconUrl: Window.static_path + path,
                                iconSize: [32, 32],
                                shadowSize: [32, 32],
                                iconAnchor: [16, 32],
                                shadowAnchor: [16, 32],
                                popupAnchor: [0, -16]
                            });

                            let marker = L.marker([latitude, longitude], { icon: icon }).addTo(map);

                        }

                        bounds.extend([latitude, longitude]);
                    }
                });

                map.fitBounds(bounds, { padding: [50, 50] });
            }
        }, 1000);
    }
    componentDidMount() {
        this.setUpMap(this.state.ads)
    }


    handleFilter() {
        this.setState({ showLoading: true });
        const { selectedRegions, selectedWood_types, selectedTrees, selectedAd_types, adId_search, selectedUrl_quoted_company_names, selectedSort,
        } = this.state;

        const filterOptions = {
            regions: selectedRegions, wood_types: selectedWood_types, trees: selectedTrees, ad_types: selectedAd_types, ad_id: adId_search, url_quoted_company_names: selectedUrl_quoted_company_names, sort: selectedSort,
        };

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        };

        fetch(`/kop-virke/virkesauktioner/`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: headers,
            body: JSON.stringify(filterOptions),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ ads: data.ads, sold_ads: data.sold_ads , showLoading:false}, () => {
                    if (this.state.checked == true) {
                        const allAds = [...this.state.sold_ads, ...this.state.ads]
                        this.setUpMap(allAds)

                    }
                    else if (this.state.checked == false) {
                        this.setUpMap(this.state.ads)
                    }
                }
                )
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    handleCardClick = (id) => {
        window.open(`/auctions/${id}`, '_blank');
    };

    handleBookmarkClick(adId, event) {
        event.stopPropagation(); //stop event
        this.setState({ showLoading: true });

        const bookedAd = { bookmarked: adId }
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        };

        fetch(`/kop-virke/virkesauktioner/`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: headers,
            body: JSON.stringify(bookedAd),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ ads: data.ads, sold_ads: data.sold_ads }, () => {
                    //const allAds = [...this.state.sold_ads, ...this.state.ads]
                    const allAds = this.state.ads
                    this.setUpMap(allAds);
                    this.setState({ showLoading: false });
                })
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ showLoading: false });
            });

    };

    render() {
        const today = new Date().toISOString().slice(0, 10);
        const isSummerPopupVisible = today > "2023-12-22" && today < "2023-01-08";


        return <div className="container-fluid p-0">
            <Navigation />
            {Window.user_data.isBanned ? (
                <section className='bg-white pt-0 pt-md-3'>
                    <div className="container">
                        <div className="row flex-md-row align-items-start justify-content-center">
                            <div className='col-md-6 col-12'>
                                <img src={`${Window.static_path}img/icons/Warning.svg`} className='tertiary-red-filter me-1' height='16px' width='16px' alt='Warning Icon' />
                                <p className='text-center mb-0 tertiary-red subtitle fw-bold mb-3'>
                                    Du har inte tillgång till att öppna och se annonser, kontakta Treebula för mer information på{' '}
                                    <a className='text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href="tel:00468339944">08339944</a> eller maila till <a className='text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href="mailto:info@virkesborsen.se">info@virkesborsen.se.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <div className="container-fluid n-fs-6 p-0">
                    <VBLoadingScreen
                        show_loading={this.state.showLoading}
                        disable_reload_timeout={true}
                        loading_text={null} />
                    <div className="bg-white">
                        <section className="bg-white pt-0 pt-md-3">
                            <div className="container neutral-charcoal">
                                <div className="row">
                                    {/* Left side */}
                                    <div className="col-lg-6 col-md-12 ">
                                        <div className="p-4 rounded-top bg-neutral-cloud">
                                            <h2 className="n-fs-4">
                                                Virkesannonser
                                            </h2>
                                            <div className="d-flex justify-content-between">
                                                {/* Filter your result */}
                                                <p className="n-fs-6 mr-auto mb-0">Filtrera ditt resultat: {this.state.ads.length} </p>
                                                
                                            </div>
                                            <div className="mt-3 d-flex jutify-content-between row">
                                                <div className="col-12 col-md-4 pe-2">
                                                    {/* Län aka County */}
                                                    <VBMultiOption
                                                        inputStyle={{ minHeight: 0 }}
                                                        label="Län"
                                                        placeholder="Vänligen välj"
                                                        options={this.state.regions}
                                                        onChange={(val) => {
                                                            this.setState({ selectedRegions: val }, () => {
                                                                this.handleFilter()
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 pe-2">
                                                    {/* Sortiment aka Range */}
                                                    <VBMultiOption
                                                        inputStyle={{ minHeight: 0 }}
                                                        label="Sortiment"
                                                        placeholder="Vänligen välj"
                                                        options={this.state.wood_types}
                                                        onChange={(val) => {
                                                            this.setState({ selectedWood_types: val }, () => {
                                                                this.handleFilter()
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {/* Trädslag aka Tree species */}
                                                <div className="col-12 col-md-4 pe-2">
                                                    <VBMultiOption
                                                        inputStyle={{ minHeight: 0 }}
                                                        label="Trädslag"
                                                        placeholder="Vänligen välj"
                                                        options={this.state.trees}
                                                        onChange={(val) => {
                                                            this.setState({ selectedTrees: val }, () => {
                                                                this.handleFilter()
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 d-flex jutify-content-between row">
                                                <div className="col-12 col-md-4 pe-2">
                                                    {/* Kontraktstyp aka Contract type */}
                                                    <VBMultiOption
                                                        inputStyle={{ minHeight: 0 }}
                                                        label="Kontraktstyp"
                                                        placeholder="Vänligen välj"
                                                        options={this.state.ad_types}
                                                        onChange={(val) => {
                                                            this.setState({ selectedAd_types: val }, () => {
                                                                this.handleFilter()
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 pe-2">
                                                    {/* Annonsid */}
                                                
                                                    <VBSearchInput
                                                        inputStyle={{minHeight: 0}} 
                                                        label="AnnonsID/Fastighet"
                                                        initial={""}
                                                        placeholder="Sök"
                                                        onChange={(val) => { val.length === 0 || val.length > 2 ? this.setState({adId_search:val},()=>{this.handleFilter()}): null}}
                                                        />
                                                </div>
                                                {/* Sortera efter aka Sort by */}
                                                <div className="col-12 col-md-4 pe-2">
                                                    <VBDropdown
                                                        inputStyle={{ minHeight: 0 }}
                                                        label="Sortera"
                                                        placeholder="Vänligen välj"
                                                        initial={""}
                                                        options={this.state.sort}
                                                        onChange={(val) => {
                                                            this.setState({ selectedSort: [val] }, () => {
                                                                this.handleFilter()
                                                            })
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='d-flex mt-4 justify-content-start'>
                                                    {/* Hide or show old ads */}
                                                    {/* <VBToggle
                                                        label={<p className="n-fs-7 mb-0">Dölj/Visa gamla annonser</p>}
                                                        checked={this.state.checked}
                                                        onChange={(val) => {
                                                            this.setState({ checked: val })
                                                            if (val == true) {
                                                                const allAds = [...this.state.sold_ads, ...this.state.ads]
                                                                this.setUpMap(allAds)
                                                            }
                                                            else if (val == false) {
                                                                this.setUpMap(this.state.ads)
                                                            }
                                                        }} /> */}

                                                    {/*<VBCheckbox
                                                        label={<p className="n-fs-7 mb-0">Dölj/Visa gamla annonser</p>}
                                                        initial={this.state.checked}
                                                        onClick={(val) => {
                                                            this.setState({ checked: val })
                                                            if (val == true) {
                                                                //const allAds = [...this.state.sold_ads, ...this.state.ads]
                                                                const allAds = this.state.ads
                                                                this.setUpMap(allAds)
                                                            }
                                                            else if (val == false) {
                                                                this.setUpMap(this.state.ads)
                                                            }
                                                        }}
                                                    /> */}

                                                </div>
                                        </div>

                                        {/* when there's no results */}
                                        <div className="d-flex flex-column flex-lg-row rounded-bottom justify-content-between align-items-center p-3 bg-neutral-cloud-600">

                                            {this.state.no_results && this.state.ads ? (

                                                <span className="mb-2 mb-md-0">Inga resultat. Här är några auktioner som kanske intresserar dig:</span>

                                            ) : this.state.ads ? (

                                                <span>{this.state.ads.length} pågående {this.state.ads.length > 1 ? "auktioner" : "auktion"}. Klicka på annons nedan:</span>

                                            ) : (

                                                <span>Inga resultat. Här ser du äldre avslutade annonser:</span>

                                            )}
                                            <a href="/salja-virke/c/" className="d-flex justify-content-center w-100 w-lg-auto n-btn btn-core btn-sm border-0 text-nowrap"><img src={Window.static_path +"img/icons/icon-left-dark.svg"} className="me-2"/>Skapa annons</a>
                                        </div>

                                        <div className="card-container" style={{ maxHeight: "400px", overflowY: "scroll", scrollbarWidth: "auto", msOverflowStyle: "auto" }}>
                                            {/* ads and sold_ads */}

                                            {this.state.ads.map((ad) => {
                                                return (
                                                    <Card key={ad.id} className="hover-bg-primary-core-200" style={{ cursor: "pointer", borderRadius: "0" }} onClick={() => this.handleCardClick(ad.id)}>
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between lh-1">
                                                                <div className="d-flex bg-supp-forest-green-400 rounded mb-3 ps-2 align-items-center pb-3 pb-md-0 me-3 me-md-0 white">
                                                                    <img
                                                                        src={`${Window.static_path}img/icons/${ad.bookmarked ? 'bookmark_star_orange.svg' : 'bookmark_star.svg'}`}
                                                                        height='16px'
                                                                        width='16px'
                                                                        alt='BookMark'
                                                                        onClick={(e) => { this.handleBookmarkClick(ad.id, e) }}
                                                                    />

                                                                    <p className="p-md-2 py-2 px-1 mb-0 rounded fw-medium n-fs-6" style={{ height: "40px" }}>
                                                                        #{ad.id} {ad.region}</p>
                                                                </div>
                                                                {ad.auction_is_over ? (
                                                                    <p className="n-fs-7"><span className="neutral-pebble">Slutdatum</span><br />
                                                                        {ad.end_time}</p>
                                                                ) : ad.auction_in_future ? (

                                                                    <p className="n-fs-7"><span className="neutral-pebble">Startdatum</span><br />
                                                                        {ad.start_time}</p>

                                                                ) : ad.auction_is_live ? (

                                                                    <p className="n-fs-7"><span className="neutral-pebble">Slutdatum</span><br />
                                                                        {ad.end_time}</p>

                                                                ) : null}
                                                            </div>
                                                            <div className="row n-fs-7">
                                                                <div className="d-flex justify-content-between col-12 lh-1">

                                                                    <p className="pe-3 col-4"><span className="neutral-pebble">Kontraktstyp</span><br />
                                                                        {ad.ad_type[0].toUpperCase() + ad.ad_type.slice(1)}</p>


                                                                    <p className="pe-3 col-4"><span className="neutral-pebble">Typ</span><br />
                                                                        {ad.arrangements}</p>

                                                                    <p className="pe-3 col-4"><span className="neutral-pebble">Total Volym</span><br />
                                                                        {(ad.total_volume || ad.total_volume === 0) ? ad.total_volume + " m³fub" : 0 + " m³fub"}</p>

                                                                </div>
                                                                <div className="d-flex justify-content-between col-12 lh-1">

                                                                    <p className="pe-3 mb-0 col-4"><span className="neutral-pebble">Gran</span><br />{(ad.gran || ad.gran === 0) ? ad.gran + " m³fub" : 0 + " m³fub"}
                                                                    </p>
                                                                    <p className="mb-0 pe-3 col-4"><span className="neutral-pebble">Tall</span><br />{(ad.tall || ad.tall === 0) ? ad.tall + " m³fub" : 0 + " m³fub"}
                                                                    </p>
                                                                    <p className="pe-3 mb-0 col-4"><span className="neutral-pebble">Löv</span><br />
                                                                        {/* Need to change to lov value here */}
                                                                        {(ad.lov || ad.lov === 0) ? ad.lov + " m³fub" : 0 + " m³fub"}</p>
                                                                </div>
                                                            </div>
                                                        </Card.Body>

                                                    </Card>
                                                )

                                            })}

                                            {this.state.checked == true && this.state.sold_ads.map((ad) => {
                                                return (
                                                    <Card key={ad.id} className="hover-bg-primary-core-200" style={{ cursor: "pointer",borderRadius: "0" }} onClick={() => this.handleCardClick(ad.id)}>
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between lh-1">
                                                                <div className="d-flex bg-supp-forest-green-400 rounded mb-3 ps-2 align-items-center pb-3 pb-md-0 me-3 me-md-0 white">
                                                                    <img
                                                                        src={`${Window.static_path}img/icons/${ad.bookmarked ? 'bookmark_star_orange.svg' : 'bookmark_star.svg'}`}
                                                                        height='16px'
                                                                        width='16px'
                                                                        alt='BookMark'
                                                                        onClick={(e) => { this.handleBookmarkClick(ad.id, e) }} />

                                                                    <p className="p-md-2 mb-0 py-2 px-1 rounded fw-medium n-fs-6" style={{ height: "40px" }}>#{ad.id} {ad.region}</p>
                                                                </div>
                                                                {ad.auction_is_over ? (
                                                                    <p className="n-fs-7"><span className="neutral-pebble">Slutdatum</span><br />
                                                                        {ad.end_time}</p>
                                                                ) : ad.auction_in_future ? (

                                                                    <p className="n-fs-7"><span className="neutral-pebble">Startdatum</span><br />
                                                                        {ad.start_time}</p>

                                                                ) : ad.auction_is_live ? (

                                                                    <p className="n-fs-7"><span className="neutral-pebble">Slutdatum</span><br />
                                                                        {ad.end_time}</p>

                                                                ) : null}
                                                            </div>
                                                            <div className="row n-fs-7">
                                                                <div className="d-flex justify-content-between col-12 lh-1">


                                                                    <p className="pe-3 col-4"><span className="neutral-pebble">Kontraktstyp</span><br />
                                                                        {ad.ad_type[0].toUpperCase() + ad.ad_type.slice(1)}</p>


                                                                    <p className="col-4 pe-3 "><span className="neutral-pebble">Typ</span><br />
                                                                        {ad.arrangements}</p>

                                                                    <p className="pe-3 col-4"><span className="neutral-pebble">Total Volym</span><br />
                                                                        {(ad.total_volume || ad.total_volume === 0) ? ad.total_volume + " m³fub" : 0 + " m³fub"}</p>

                                                                </div>
                                                                <div className="d-flex justify-content-between col-12 lh-1">

                                                                    <p className="pe-3 mb-0 col-4"><span className="neutral-pebble">Gran</span><br />{(ad.gran || ad.gran === 0) ? ad.gran + " m³fub" : 0 + " m³fub"}
                                                                    </p>
                                                                    <p className="mb-0 pe-3 col-4"><span className="neutral-pebble">Tall</span><br />{(ad.tall || ad.tall === 0) ? ad.tall + " m³fub" : 0 + " m³fub"}
                                                                    </p>
                                                                    <p className="mb-0 pe-3 col-4"><span className="neutral-pebble">Löv</span><br />{(ad.lov || ad.lov === 0) ? ad.lov + " m³fub" : 0 + " m³fub"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Card.Body>

                                                    </Card>
                                                )
                                            })}

                                        </div>

                                    </div>
                                    {/* Right side */}
                                    <div className="col-lg-6 d-none d-lg-block p-0" style={{ height: "800px" }}>
                                        <VBLeaflet
                                            style={{ borderRadius: "15px", zIndex:"1" }}
                                            basemapName="Satellit"
                                            ref={this.map}
                                            enableLayers={true}
                                            hideToolkitButton={true}
                                            hideLeafletLayerButton={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </section>

                    </div>
                    {/* ends here no footer */}
                </div>
            )}
            {isSummerPopupVisible && (
                <div>
                    <h2>Ser det tomt ut?</h2>
                    <p>Från och med <span>22 December och fram till 8 Januari</span> visar vi inte mycket annonser här.
                        <span>Kom tillbaka i Januari</span> så hittar du mycket mer virke till salu</p>
                    <button
                        onClick={{}}
                        className="btn blue"
                    >Stäng</button>
                </div>
            )}
            <Footer />
        </div>
    }
}

export default Auctions;


// Custom scrollbar CSS
const customScrollbarCSS = `
.card-container {
    max-height: 480px;
    overflow-y: auto;
}

.card-container::-webkit-scrollbar {
    width: 8px;
}

.card-container::-webkit-scrollbar-thumb {
    background-color: #818887;
    border-radius: 4px;
}

.card-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
`;
const styleTag = document.createElement('style');
styleTag.type = 'text/css';
styleTag.appendChild(document.createTextNode(customScrollbarCSS));
document.head.appendChild(styleTag);

