import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption"
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBTextarea from "@virkesborsen/vb-js-commons/lib/components/new/vbTextarea";
import VBCheckbox from "@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox";

const DATE = {
    type: "date",
    get_input: (label, initial, onChange) => <VBInput initial={initial?.slice(0,10)} label={label} onChange={onChange} type="date" />
}

const BOOL = {
    type: "boolean",
    get_input: (label, initial, onChange) => <VBCheckbox initial={initial ? true : false} label={label} onChange={onChange} />
}

const TEXT = {
    type: "text",
    get_input: (label, initial, onChange) => <VBInput initial={initial} label={label} onChange={onChange} />
}

const NUMBER = {
    type: "text",
    get_input: (label, initial, onChange) => <VBInput type="number" initial={initial} label={label} onChange={onChange} />
}

const HIDDEN = {
    type: "text",
    get_input: (label, initial, onChange) => null
}

const TEXTAREA = {
    type: "text",
    get_input: (label, initial, onChange) => <VBTextarea initial={initial} label={label} onChange={onChange} height="300px" />
}

const DROPDOWN = {
    type: "text",
    get_input: (label, initial, onChange, options) => <VBDropdown options={options} initial={initial} label={label} onChange={onChange} />
}

const MULTIOPTION = {
    type: "text",
    get_input: (label, initial, onChange, options) => <VBMultiOption options={options} initial={initial} label={label} onChange={onChange} />
}

const ONE_PER_LINE = {
    type: "array",
    get_input: (label, initial, onChange) => <VBTextarea initial={initial} label={label} onChange={onChange} height="300px" />
}

const FILE = {
    type: "FILE",
    get_input: (label, initial, onChange) => {return <label className="n-btn btn-forest-green btn-sm mt-3" role="button"> <img className={`me-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/icon-left.svg"} htmlFor='attachments' />{label}
        <input
        multiple
        type='file'
        id='attachments'
        className='position-absolute'
        style={{ opacity: 0, width: "1px", marginLeft: "24px" }}
        onChange={(e) => {onChange(e.target.files[0])}}
        />
    </label>
    }
}

const data_map = {
    project:{
        create_url: "/naturehub/api/project/create/",
        update_url: "/naturehub/api/project/",
        contentType: "application/json",
        allow_delete: false,
        fields:{
            start_time: {input: DATE, label: "Start datum"},
            end_time: {input: DATE, label: "Slut datum"},
            public: {input: BOOL, label: "Synlig för användare"},
            owners_email_list: {input: ONE_PER_LINE, label: "Skogsägare e-poster (en per rad)"},
            managers_email_list: {input: ONE_PER_LINE, label: "Projektansvarig e-poster (en per rad)"},
            buyers_email_list: {input: ONE_PER_LINE, label: "Köpare e-poster (en per rad)"},
            title: {input: TEXT, label: "Titel"},
            tag: {input: TEXT, label: "Tag"},
            buy_link: {input: TEXT, label: "Köp länk"},
            property_designation: {input: TEXT, label: "Fastighetsbeteckning"},
            description: {input: TEXTAREA, label: "Beskrivning"},
            nature_description: {input: TEXTAREA, label: "Beskrivning natur"},
            other_info: {input: TEXTAREA, label: "Övrigt information"},
            facebook_link: {input: TEXT, label: "Facebook länk"},
            linkedin_link: {input: TEXT, label: "LinkedIn länk"},
            instagram_link: {input: TEXT, label: "Instagram länk"},
            x_link: {input: TEXT, label: "X länk"},
            fsc_certified: {input: BOOL, label: "FSC Certifierad"},
            pefc_certified: {input: BOOL, label: "PEFC Certifierad"},
            public: {input: BOOL, label: "Publikt tillgänglig"},
            state: {input: DROPDOWN, label:"State", window_options: "state_options"},
            hidden_segments: {input: MULTIOPTION, label:"Dolda Sektioner", window_options: "segment_options"},
        },
        formatters:{
            owners_email_list:{
                output: val => val == "" ? undefined: val?.split('\n'),
                input: val => val.join("\n")
            },
            managers_email_list:{
                output: val => val == "" ? undefined: val?.split('\n'),
                input: val => val.join("\n")
            },
            buyers_email_list:{
                output: val => val == "" ? undefined: val?.split('\n'),
                input: val => val.join("\n")
            },
            state:{
                output: val => val.value,
                input: val => val
            }
        }
    },
    update:{
        create_url: "/naturehub/api/project-update/create/",
        update_url: "/naturehub/api/project-update/",
        contentType: "application/json",
        allow_delete: true,
        fields:{
            title: {input: TEXT, label: "Titel"},
            date: {input: DATE, label: "Datum"},
            items: {input: ONE_PER_LINE, label: "Åtgärder (en per rad)"},
            written_by: {input: HIDDEN, label:"Hidden"}
        },
        formatters:{
            items: {
                output: val => {if(!val){return val} return val.split('\n').map((itm) => {return {description:itm}})},
                input: val => {if(!val){return val} return val.map((itm) => itm.description).join("\n")},
            },
            written_by: {
                input: val => val,
                output: val => undefined
            }
        }
    },
    detail:{
        create_url: "/naturehub/api/project-detail/create/",
        update_url: "/naturehub/api/project-detail/",
        contentType: "application/json",
        allow_delete: true,
        fields:{
            title: {input: TEXT, label: "Titel"},
            description: {input: TEXTAREA, label: "Beskrivning"},
            link: {input: TEXT, label: "Länk (lämna blank om ingen)"},
            order: {input: NUMBER, label: "Plats i ordningen"}
        }
    },
    attachment:{
        create_url: "/naturehub/api/project-attachment/create/",
        update_url: "/naturehub/api/project-attachment/",
        contentType: null,
        allow_delete: true,
        fields:{
            name: {input: TEXT, label: "Namn"},
            file: {input: FILE, label: "Bild"},
        }
    },
    image_file:{
        create_url: "/naturehub/api/project-image/create/",
        update_url: "/naturehub/api/project-image/",
        contentType: null,
        allow_delete: true,
        fields:{
            image: {input: FILE, label: "Bild"},
        }
    },
    forest:{
        create_url: "/naturehub/api/project-forest/create/",
        update_url: "/naturehub/api/project-forest/",
        contentType: "application/json",
        allow_delete: true,
        fields:{
            geom: {input: TEXTAREA, label:"Geojson"},
            name: {input: TEXT, label:"Namn"},
            left_side_headline: {input: TEXT, label:"Vänster rubrik"},
            left_side: {input: TEXTAREA, label:"Vänster (En per rad, använd \":\" mellan, exempelvis \"Tall:87%\""},
            right_side_headline: {input: TEXT, label:"Höger rubrik"},
            right_side: {input: TEXTAREA, label:"Höger (En per rad, använd \":\" mellan, exempelvis \"Virkesförråd:127 m3fub\""},
            description: {input: TEXTAREA, label: "Beskrivning"}
        },
        formatters: {
            geom: {
                input: val => JSON.stringify(val),
                output: val => val
            }
        }
    },
    forest_section:{
        create_url: "/naturehub/api/project-forest-section/create/",
        update_url: "/naturehub/api/project-forest-section/",
        contentType: "application/json",
        allow_delete: true,
        fields:{
            geom: {input: TEXTAREA, label:"Geojson"},
            geom_color: {input: TEXT, label: "Färg i kartan"},
            name: {input: TEXT, label: "Namn"},
            description: {input: TEXTAREA, label:"Beskrivning"},
            other_info_formatted: {input: TEXTAREA, label:"Övrig information (**För fet text, ***För en lista en rad per text)"},
            credits: {input: TEXT, label: "Krediter"},
            credit_unit: {input: TEXT, label:"Kredit enhet"},
            activity_class: {input: TEXT, label: "Aktivitetsklass"}
        },
        formatters: {
            geom: {
                input: val => JSON.stringify(val),
                output: val => val
            }
        }
    },
    project_manager:{
        create_url: "/naturehub/api/project-manager/create/",
        update_url: "/naturehub/api/project-manager/",
        contentType: null,
        allow_delete: false,
        fields:{
            user: {input: TEXT, label: "AnvändareID"},
            main_owner: {input: BOOL, label: "Huvudansvarig"},
            picture: {input: FILE, label: "Bild"},
            public_email: {input: TEXT, label: "Publik e-post"},
            public_name: {input: TEXT, label: "Publikt namn"}
        },
        formatters: {
            picture: {
                input: val => val,
                output: val => val instanceof File ? val : undefined ,
            }
        }
    },
    project_company: {
        create_url: "/naturehub/api/project-company/create/",
        update_url: "/naturehub/api/project-company/",
        contentType: null,
        allow_delete: true,
        fields:{
            company_logo: {input: FILE, label:"Företag logo"},
            company_email: {input:TEXT, label:"Företag e-post"}
        },
    },
    project_link:{
        create_url: "/naturehub/api/project-link/create/",
        update_url: "/naturehub/api/project-link/",
        contentType: "application/json",
        allow_delete: true,
        fields:{
            name: {input: TEXT, label: "Namn"},
            link: {input: TEXT, label: "Länk"},
            icon: {input: TEXT, label: "Icon"}
        }
    }
}

export default data_map