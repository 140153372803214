import React from 'react';
import Navigation from '../../components/static/Navigation';
import Footer from '../../components/static/Footer';

import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";


class NHListings extends React.Component {
    constructor() {
        super();
        this.navigation = React.createRef();
        this.state = {
            ddl_options: {
                tag: [],
                country: [],
            },
            filters: {
                tag:null,
                country:null
            }
        }
    }

    componentDidMount(){
        Window.react_data.projects.forEach((p) => {
            if(!this.state.ddl_options.tag.includes(p.tag)){
                this.state.ddl_options.tag.push(p.tag)
            } 

            if(!this.state.ddl_options.country.includes(p.country)){
                this.state.ddl_options.country.push(p.country)
            } 
        })
        this.setState({ddl_options: this.state.ddl_options});
    }

    render() {

        let projects = Window.react_data.projects.filter((p) => {
            return Object.keys(this.state.filters).reduce((acum, curr) => {
                let filter_val = this.state.filters[curr];
                if(filter_val && p[curr] != filter_val){
                    return false
                }
                
                return acum;
            }, true)
        })



        return (
            <div>
                <div className="bg-white">
                    <Navigation ref={this.navigation} />
                </div>
                <div className="container neutral-charcoal mt-3">
                    <h1>Våra Projekt</h1>
                    <p>Sök och hitta projekt för att förbättra världen ett steg i taget.</p>
                </div>
                <div className="container neutral-charcoal" style={{marginBottom:"64px"}}>
                    <div className="d-inline-block me-2 my-2">
                        <VBDropdown onChange={(val) => {this.setState({filters: {...this.state.filters, tag: val.value}})}} placeholder="Projekts Typ" options={this.state.ddl_options.tag.map((itm) => {return {"value": itm, "label": itm}})} inputStyle={{ minHeight: 0 }} />
                    </div>
                    <div className="d-inline-block me-2 my-2">
                        <VBDropdown onChange={(val) => {this.setState({filters: {...this.state.filters, country: val.value}})}} placeholder="Land" options={this.state.ddl_options.country.map((itm) => {return {"value": itm, "label": itm}})} inputStyle={{ minHeight: 0 }} />
                    </div>
                </div>

                <div className="container neutral-charcoal mb-3">
                    <div className="row">
                        {projects.map((p) => {
                            return <div style={{cursor:"pointer"}} className="col-3" onClick={() => {window.location = "/biodiversitet/" + p.id + "/"}}>
                            <div className="rounded-3 bg-neutral-stone-100 overflow-hidden" style={{height:"416px"}}>
                                <img src="https://placehold.co/400x400" className="w-100" style={{height:"192px", objectFit:"cover"}} />
                                <div className="p-2 position-relative" style={{height:"224px"}}>
                                    {/* upper half of card in this div */}
                                    <div className="pt-3 position-absolute top-0" style={{height:"112px"}}>
                                        <div className="mb-3">
                                            <span className="bg-supp-plum white rounded-5 py-1 px-2 n-caption-2">{p.tag}</span>
                                        </div>
                                        <p className="n-fs-6">{p.title} - {p.property_designation}</p>
                                    </div>
                                    {/* lower half of card in this div */}
                                    <div className="position-absolute bottom-0 pt-4" style={{height:"112px"}}>
                                        <p className="n-fs-6">{p.end_time_formats.pretty}</p>
                                        <p className="position-absolute bottom-0 n-fs-7">{p.country}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        })}
                    </div>
                </div>
                
                <Footer/>
            </div >
        )
    }
}
export default NHListings;