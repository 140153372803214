import React from 'react';
import Cookies from 'js-cookie';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import styled from "styled-components";

const PlusBerger = styled.span`
    position: relative;
    left: 97%;
    top: -24px;
    width: 20px;
    div {
        position: absolute;
        width: 100%;
        height: 5px;
        &:nth-child(1) {
            transform: rotate(90deg);
            transition: all 0.4s;
        }
    }
`

const PlusBergerA = styled.a`
    &[aria-expanded="true"] {
        .plus-berger {
            div {
                &:nth-child(1) {
                    transform: rotate(0deg);
                }
            }
        }
}`

class ProfileAdListings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ads: this.props.ads.ads,
            bids: this.props.ads.bids,
            opened: this.props.ads.opened,
            filter_year: 'all',
            filter_ads: 'all',
            child_accounts: this.props.child_accounts,
            account: this.props.ads,
            child_years: [],
            loading: false,
            userExpiringAds: Window.user_data.expiringAds
        }

    }

    filterComparisonGenerator(value) {
        let comparison_func = () => true;
        if (value == "all") {
            comparison_func = () => true
        }
        else if (value == 'draft') {
            comparison_func = (ad) => ad.state <= 15
        }
        else if (value == 'wfr') {
            comparison_func = (ad) => ad.state == 30 || ad.state == 35
        }
        else if (value == 'no_bid') {
            comparison_func = (ad) => (ad.state == 40 && ad.has_ended && !ad.has_bids) || ad.state == 42
        }
        else if (value == 'is_over') {
            comparison_func = (ad) => ad.state == 40 && ad.has_ended
        }
        else if (value == 'live') {
            comparison_func = (ad) => ad.state == 40 && !ad.has_ended
        }
        else if (value == 'no_bid_selected') {
            comparison_func = (ad) => ad.state == 44
        }
        else if (value == 'bid_selected') {
            comparison_func = (ad) => ad.state >= 50
        }
        /*else if(value == 'won' || value == "lost"){
            comparison_func = (ad) => ad.state >= 50 && ad.winner
        }
        else if(value == 'no bid selected'){
            comparison_func = (ad) => ad.state == 44
        }
        else if(value == 'lost'){
            comparison_func = (ad) => ad.state == 40 && !ad.winner
        }*/

        return comparison_func
    }

    filterChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        let comparison_func = this.filterComparisonGenerator(value)

        const set = name.replace("filter_", "");

        let result = {
            'bids': {
                'on_going': this.state.bids.on_going,
                'won': this.state.bids.won,
                'lost': this.state.bids.lost,
                'not_sold': this.state.bids.not_sold,
            }
        }
        //Filter user accounts or child accounts

        if (this.state.account.hasOwnProperty(set)) {
            let year_filter = this.state.account[set].filter((ad) => ad.year == this.state.filter_year || this.state.filter_year == 'all')
            result[set] = year_filter.filter(comparison_func)
        } else if (set == "year") {
            Object.keys(this.state.account).forEach((key) => {
                if (key != 'bids') {
                    let year_filter = this.state.account[key].filter((ad) => ad.year == value || value == 'all')
                    result["filter_" + key] = "all"
                    result[key] = year_filter.filter(comparison_func)
                } else {
                    Object.keys(this.state.account[key]).forEach((bidkey) => {
                        let year_filter = this.state.account.bids[bidkey].filter((ad) => ad.year == value || value == 'all')
                        result.bids[bidkey] = year_filter
                    })
                }
            })
        }


        result[name] = value;
        this.setState(result);
    }

    filterOption(value, context) {
        let evalFunc = this.filterComparisonGenerator(value)
        const set = context.replace("filter_", "");
        let year_filter;
        //Filter child accounts or user account ads

        year_filter = this.state.account[set].filter((ad) => ad.year == this.state.filter_year || this.state.filter_year == 'all')

        //let adStates = year_filter.reduce((acum, ad) => {acum.push(ad.state); return acum;}, []);

        for (let ad of year_filter) {
            if (evalFunc(ad)) {
                return true;
            }
        }
        return false;
    }

    loadItem(value) {
        return (
            <div className="bg-neutral-cloud rounded my-3 mx-md-4 p-4">
                <div className="row">
                    <div className="col-9">
                        <h4 className="n-fs-5">{value['betekning'] ? value['betekning'] + ' | ' : null}{value['muni'] != "None" ? value['muni'] + ", " : null} {value['region'] ? value['region'] : 'Utkast'}</h4>
                    </div>
                    <div className="col-3 ps-0">
                        <h4 className="text-end n-fs-5">#{value['id']}</h4>
                    </div>
                    <div className="col-12">
                        <p className="m-0">
                            {value['total_volume'] ? <span className="neutral-charcoal border-end border-3 border-supp-forest-green me-2 pe-2">{value['total_volume']} m3fub </span> : null}
                            <span className="border-end border-3 border-supp-forest-green me-2 pe-2">{value['type']} </span>
                            {value['arrangement'] ? value['arrangement'] : null}
                        </p>
                        <p className="m-0 mt-2 fw-medium">{value['status']}</p>
                        {value['contract_pdf'] || value['contact'] ?
                            <div className="row">
                                {value['contact'] ? <div className="col-12 col-md-6">
                                    <h5 className="mb-0 mt-2 blue n-fs-5">{value['contact'].label}</h5>
                                    <p className="n-fs-6"><b>{value['contact'].name}</b><br />
                                        Email: <a href={"mailto:" + value['contact'].email} className="fw-medium">{value['contact'].email}</a><br />
                                        Telefon: <a href={"tel:" + value['contact'].phone} className="fw-medium">{value['contact'].phone}</a></p>
                                </div> : null}
                                {value['contract_pdf'] ? <div className="col-12 col-md-6 d-flex align-items-end"><a className="n-btn btn-forest-green w-100" href={value['contract_pdf']} target="_blank">Ladda ner kontrakt</a></div> : null}
                            </div>
                            : null}
                    </div>
                </div>

                {this.loadButtons(value)}
            </div>
        )
    }

    loadButtons(value) {
        if (value['has_bids'] === true) {
            return (<div className="row ">
                    <div className="col-12 col-md-6 pe-md-0 mt-3">
                        <a href={this.adUrlDetails(value['id'], 'ad', value['state'])} className="n-btn btn-sm  btn-forest-green" target="_blank">{value['status'] == "Fullfölj virkesannons" ? "Fullfölj annons" : "Se annons"}</a>
                    </div>
                    <div className="col-12 col-md-6 ps-md-0 mt-3">
                        <a href={this.adUrlDetails(value['id'], 'bid', value['state'])} className="n-btn btn-sm  btn-forest-green" target="_blank">{value['status'] == "Du ska nu välja köpare" ? 'Välj köpare' :'Se anbud'}</a>
                    </div>
                    </div>
            )
        } else {
            return (<div className="row">
                    <div className="col-12 mt-3">
                        <a href={this.adUrlDetails(value['id'], 'ad', value['state'])} className="n-btn btn-sm btn-forest-green" target="_blank">{value['status'] == "Fullfölj virkesannons" ? "Fullfölj virkesannons" : "Se annons"}</a>
                    </div></div>
            )
        }
    }

    adUrlDetails(id, btn, state) {
        let url;
        if (btn == 'bid') {
            url = "/auctions/" + id + "/#show_bids";
        } else {
            url = state === 15 ? "/auctions/" + id + "/edit/" : "/auctions/" + id + "/";
        }
        return url
    }

    adFilter(ad, keyword){
        const description = ad.betekning ? ad.betekning.toLowerCase().replace(/ /g, '') : '';
        const adId = ad.id.toString().replace(/ /g, '');
        const email = ad.email ? ad.email.replace(/ /g, '') : '';
        const phone = ad.phone ? ad.phone.replace(/ /g, '').replace(/^\+46/, '0').replace(/-/g, '') : '';
        const name = ad.owner_name ? ad.owner_name.toLowerCase().replace(/ /g, '') : '';
        const lastname = ad.owner_lastname ? ad.owner_lastname.toLowerCase().replace(/ /g, '') : '';
        let fixed_key = keyword.toLowerCase().replace(/ /g, '')
        return (
            description.includes(fixed_key) ||
            adId.includes(fixed_key) ||
            email.includes(fixed_key) ||
            phone.includes(fixed_key.replace(/^\+46/, '0').replace(/-/g, '')) ||
            name.includes(fixed_key) ||
            lastname.includes(fixed_key)
        );
    }

    //Search function
    search = (keyword) => {

        const filteredAds = this.state.account.ads.filter((ad) => {return this.adFilter(ad,keyword)});
        const filteredLost = this.state.account.bids.lost.filter((ad) => {return this.adFilter(ad,keyword)});
        const filteredNot_sold = this.state.account.bids.not_sold.filter((ad) => {return this.adFilter(ad,keyword)});
        const filteredOn_going = this.state.account.bids.on_going.filter((ad) => {return this.adFilter(ad,keyword)});
        const filteredWon= this.state.account.bids.won.filter((ad) => {return this.adFilter(ad,keyword)});
        const filteredOpened= this.state.account.opened.filter((ad) => {return this.adFilter(ad,keyword)});

        this.setState({ 
            ads: filteredAds, 
            bids:{
                lost:filteredLost, 
                not_sold:filteredNot_sold, 
                on_going:filteredOn_going, 
                won:filteredWon
            }, 
            opened:filteredOpened
        });
        

    };

    //Child Accounts Ads

    selectChildAccountAd = (accounts) => {
        this.setState({
            loading: true
        })

        if (accounts.length != 0) {
            let child_ids = accounts.map(account => account.value);
            let target = "/my-account/listings/childaccounts/"

            fetch(target, {
                "method": "POST",
                "body": JSON.stringify({
                    "child_ids": child_ids
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(res => {
                if (res.ok) {
                    res.json().then(data => {
                        this.setState({
                            ads: data.ads.ads,
                            bids: data.ads.bids,
                            opened: data.ads.opened,
                            child_years: data.years,
                            loading: false,
                            account:data.ads

                        })
                    })
                }
                else {
                    alert('Oops! Något gick fel...')
                }
            });
        }
        else {
            this.setState({
                ads: this.props.ads.ads,
                bids: this.props.ads.bids,
                opened: this.props.ads.opened,
                account: this.props.ads,
                child_years: [],
                loading: false
            })
        }


    }

    render() {
        let category_options = [{label: "Visa alla", value: "all"},]

        let has_child_accounts = Object.keys(this.props.child_accounts).length > 0;

        if(this.filterOption("draft", 'filter_ads')){
            category_options.push({label:"Utkast", value: "draft"})
        }
        if(this.filterOption("wfr", 'filter_ads')){
            category_options.push({label:"Väntar på granskning", value: "wfr"})
        }
        if(this.filterOption("live", 'filter_ads')){
            category_options.push({label:"Aktiva annonser", value: "live"})
        }
        if(this.filterOption("no_bid", 'filter_ads')){
            category_options.push({label:"Annonser utan anbud", value: "no_bid"})
        }
        if(this.filterOption("is_over", 'filter_ads')){
            category_options.push({label:"Välj köpare", value: "is_over"})
        }
        if(this.filterOption("no_bid_selected", 'filter_ads')){
            category_options.push({label:"Inget anbud valt", value: "no_bid_selected"})
        }
        if(this.filterOption("bid_selected", 'filter_ads')){
            category_options.push({label:"Sålda annonser", value: "bid_selected"})
        }

        return <>
        <div className="d-flex flex-wrap" style={{maxWidth:"100%", width:"1200px"}}>
            <div className="mx-4">
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className="row mb-4">
                <div className="col-12 d-flex align-items-center">
                    <h1 className="n-h4 supp-forest-green mb-0 mt-5 mt-lg-0">Mina annonser</h1>
                </div>
            </div>
            
            <div className="row">
                <div class={`col-12 ${has_child_accounts ? 'col-lg-3' : 'col-lg-4'} mt-2`}>    
                    <h4 className="mb-1 mt-2 n-fs-4">Sök</h4>
                    <VBInput
                        type="text"
                        placeholder="Sök"
                        default={true}
                        onChange={(val) => { this.search(val) }} />
                </div>
                <div class={`col-12 ${has_child_accounts ? 'col-lg-3' : 'col-lg-4'} mt-2`}>
                    <h4 className="mb-1 mt-2 n-fs-4">Filtrera på år</h4>
                    <VBDropdown 
                    options={this.props.years.map((value) => {return {"label": value, "value": value}})}
                    onChange={(val) => {console.log(val);this.filterChange({target:{name:"filter_year", value:val.value}})}}
                    />
                </div>
                <div class={`col-12 ${has_child_accounts ? 'col-lg-3' : 'col-lg-4'} mt-2`}>
                    <div className={this.props.ads.ads.length == 0 ? "d-none" : null}>
                        <h4 className="mb-1 mt-2 n-fs-4">Mina annonser</h4>
                        <VBDropdown 
                        options={category_options}
                        onChange={(val) => {console.log(val);this.filterChange({target:{name:"filter_ads", value:val.value}})}} 
                        />
                    </div>
                </div>
                <div class={`col-12 ${has_child_accounts ? 'col-lg-3' : 'col-lg-4'} mt-2`}>
                    {has_child_accounts ? 
                        <div>
                            <h4 className="mb-1 mt-2 n-fs-4">Anställda</h4>
                            <VBMultiOption
                                placeholder="Anställda"
                                options={Object.keys(this.props.child_accounts).map(id => ({ label: this.props.child_accounts[id], value: id }))}
                                error={""}
                                onFocus={""}
                                onChange={(val) => { this.selectChildAccountAd(val) }} />
                        </div>
                        :null}
                </div>


                <div className="col-12 mt-3">
                    <div className="row">
                        <div>
                            <PlusBergerA className={this.state.userExpiringAds.length == 0 ? "d-none" : null} href="#myExpiringAds" data-bs-toggle="collapse" aria-expanded="true" aria-controls="myExpiringAds">
                                <div className="col-12">
                                    <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Tiden löper snart ut på dessa annonser ({this.state.userExpiringAds.length}/{this.state.account.ads.length}).</h4>
                                    <PlusBerger className="d-flex align-items-center plus-berger">
                                        <div className="rounded bg-supp-forest-green"></div>
                                        <div className="rounded bg-supp-forest-green"></div>
                                    </PlusBerger>
                                </div>
                            </PlusBergerA>
                            <div id="myExpiringAds" className={this.state.userExpiringAds.length == 0 ? "d-none" : "col-12 py-2 collapse show"}>
                                {this.state.ads.map((value) => {

                                    return (
                                        this.state.userExpiringAds.includes(`${value['id']}`) ? this.loadItem(value) : null
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <PlusBergerA className={this.state.account.ads.length == 0 ? "d-none" : null} href="#minaAnnonser" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnnonser">
                                <div className="col-12">
                                    <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Alla dina annonser ({this.state.ads.length}/{this.state.account.ads.length}).</h4>
                                    <PlusBerger className="d-flex align-items-center plus-berger">
                                        <div className="rounded bg-supp-forest-green"></div>
                                        <div className="rounded bg-supp-forest-green"></div>
                                    </PlusBerger>
                                </div>
                            </PlusBergerA>
                            <div id="minaAnnonser" className={this.state.account.ads.length == 0 ? "d-none" : "col-12 py-2 collapse"}>
                                {this.state.ads.map((value) => {
                                    return (
                                        value['show'] ? this.loadItem(value) : null
                                    )
                                })}
                            </div>
                        </div>

                        {/* Here comes the bids ongoing */}
                        {this.state.bids.on_going && this.state.account.bids.on_going &&
                            <div>
                                <PlusBergerA className={this.state.account.bids.on_going.length == 0 ? "d-none" : null} href="#minaAnbudOngoing" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudOngoing">
                                    <div className="col-12">
                                        <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Pågående anbud ({this.state.bids.on_going.length}/{this.state.account.bids.on_going.length}).</h4>
                                        <PlusBerger className="d-flex align-items-center plus-berger">
                                            <div className="rounded bg-supp-forest-green"></div>
                                            <div className="rounded bg-supp-forest-green"></div>
                                        </PlusBerger>
                                    </div>
                                </PlusBergerA>
                                <div id="minaAnbudOngoing" className={this.state.account.bids.on_going.length == 0 ? "d-none" : "col-12 py-2 collapse"}>
                                    {this.state.bids.on_going.map((value) => {
                                        return (
                                            value['show'] ? this.loadItem(value) : null
                                        )
                                    })}
                                </div>

                            </div>
                        }

                        {/* Here comes the bids won */}

                        {this.state.bids.won && this.state.account.bids.won &&
                            <div>
                                <PlusBergerA className={this.state.account.bids.won.length == 0 ? "d-none" : null} href="#minaAnbudWon" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudWon">
                                    <div className="col-12">
                                        <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Köpta annonser ({this.state.bids.won.length}/{this.state.account.bids.won.length}).</h4>
                                        <PlusBerger className="d-flex align-items-center plus-berger">
                                            <div className="rounded bg-supp-forest-green"></div>
                                            <div className="rounded bg-supp-forest-green"></div>
                                        </PlusBerger>
                                    </div>
                                </PlusBergerA>
                                <div id="minaAnbudWon" className={this.state.account.bids.won.length == 0 ? "d-none" : "col-12 py-2 collapse"}>
                                    {this.state.bids.won.map((value) => {
                                        return (
                                            value['show'] ? this.loadItem(value) : null
                                        )
                                    })}
                                </div>
                            </div>

                        }
                        {/* Here comes the bids no bid selected*/}
                        {this.state.bids.not_sold && this.state.account.bids.not_sold &&

                            <div>
                                <PlusBergerA className={this.state.account.bids.not_sold.length == 0 ? "d-none" : null} href="#minaAnbudUnsold" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudUnsold">
                                    <div className="col-12">
                                        <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Annonser som inte blev sålda ({this.state.bids.not_sold.length}/{this.state.account.bids.not_sold.length}).</h4>
                                        <PlusBerger className="d-flex align-items-center plus-berger">
                                            <div className="rounded bg-supp-forest-green"></div>
                                            <div className="rounded bg-supp-forest-green"></div>
                                        </PlusBerger>
                                    </div>
                                </PlusBergerA>
                                <div id="minaAnbudUnsold" className={this.state.account.bids.not_sold.length == 0 ? "d-none" : "col-12 py-2 collapse"}>
                                    {this.state.bids.not_sold.map((value) => {
                                        return (
                                            value['show'] ? this.loadItem(value) : null
                                        )
                                    })}
                                </div>
                            </div>
                        }
                        {/* Here comes the bids lost */}
                        <div>
                            {this.state.bids.lost && this.state.account.bids.lost &&
                                <PlusBergerA className={this.state.account.bids.lost.length == 0 ? "d-none" : null} href="#minaAnbudLost" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudLost">
                                    <div className="col-12">
                                        <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Förlorade anbud ({this.state.bids.lost.length}/{this.state.account.bids.lost.length}).</h4>
                                        <PlusBerger className="d-flex align-items-center plus-berger">
                                            <div className="rounded bg-supp-forest-green"></div>
                                            <div className="rounded bg-supp-forest-green"></div>
                                        </PlusBerger>
                                    </div>
                                </PlusBergerA>
                            }
                            {this.state.bids.lost && this.state.account.bids.lost &&
                                <div id="minaAnbudLost" className={this.state.account.bids.lost.length == 0 ? "d-none" : "col-12 py-2 collapse"}>
                                    {this.state.bids.lost.map((value) => {
                                        return (
                                            value['show'] ? this.loadItem(value) : null
                                        )
                                    })}
                                </div>
                            }
                            <PlusBergerA className={this.state.account.opened.length == 0 ? "d-none" : null} href="#oppnadeAnnonser" data-bs-toggle="collapse" aria-expanded="false" aria-controls="oppnadeAnnonser">
                                <div className="col-12">
                                    <h4 className="border-bottom border-4 border-supp-forest-green n-fs-4">Annonser du har öppnat ({this.state.opened.length}/{this.state.account.opened.length}).</h4>
                                    <PlusBerger className="d-flex align-items-center plus-berger">
                                        <div className="rounded bg-supp-forest-green"></div>
                                        <div className="rounded bg-supp-forest-green"></div>
                                    </PlusBerger>
                                </div>
                            </PlusBergerA>
                            <div id="oppnadeAnnonser" className={this.state.account.opened.length == 0 ? "d-none" : "col-12 py-2 collapse"}>
                                {this.state.opened.map((value) => {
                                    return (
                                        value['show'] ? this.loadItem(value) : null
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div ></>
    }
}

export default ProfileAdListings;