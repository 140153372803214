import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";

import { ErrorModal } from '../../modals';
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';

import { searchProps, createEmptyProp, deleteProperty, unlockProperty, fetchProperties } from './map_api';
import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';

class PropSelector extends React.Component {
    constructor(props) {
        super(props);

        const activePropsFromCookie = Cookies.get('activeProps')

        this.state = {
            firstLoad: activePropsFromCookie ? activePropsFromCookie.split(',').length : 0,
            CollapseActive: false,
            propAccordionState: "view",
            propSearchString: "",
            propSearchResults: [],
            ownedProps: this.props.ownedProps,
            usedProps: Window.react_data.usedProps,
            allowedProps: Window.react_data.allowedProps,
            currentlySelectedProp: {},
            activeProps: this.props.activeProps,
            currentlySelectedAddingProp: {},
            currentlySelectedPropModal: null,
            waitingForDivideResp: false,
            divideQue: [],
            confirmUnlockModal: false,
            deletePropertyModal: false,
            selectedDeleteProp: {},
            currentlySelectedPropertiesArray: [],
        }
        this.searchInputRef = React.createRef()
    }

    selectForestandFile() {
        document.querySelector("#hidden_file_upload_forestand").click();
    }

    sendForestandToBackend(e) {
        var input = e.target;

        var data = new FormData()
        data.append('file', input.files[0])
        this.setState({ CollapseActive: false })

        fetch('/map-api/import-forestand-to-sbp/', {
            method: 'POST',
            body: data,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        }).then(resp => resp.json())
            .then((json) => {
                let activeProps = this.state.activeProps
                let ownedProps = this.state.ownedProps
                let obj = { value: json.value, label: json.label, locked: false, selected: true, type: 'SBO' }
                activeProps.push({info: obj, borderGeojson: null, json: json.data})
                ownedProps.push(obj)
                this.setState({ ownedProps: ownedProps, activeProps: activeProps }, () => this.props.onDivideFinish(activeProps))
            });
    }

    componentDidMount() {
        //check if there is a previously selected property in the cookie
        const activePropsFromCookie = Cookies.get('activeProps')

        if (activePropsFromCookie) {
            let props = activePropsFromCookie.split(',').map(prop => this.state.ownedProps.find(p => p.value == prop)).filter(prop => prop && !prop.locked)
            
            let ownedProps = this.state.ownedProps
            Promise.all(
                props.map(prop => {
                    ownedProps.find(p => prop.value == p.value).selected = true
                })
            ).then(() => {
                this.setState({ownedProps: ownedProps}, () => this.accessProperties())
            })
        }
    }

    updateSelectedProp(itm) {
        let ownedProps = this.state.ownedProps
        ownedProps.find(p => p.value == itm.value).selected = !ownedProps.find(p => p.value == itm.value).selected
        this.setState({ ownedProps: ownedProps })
    }

    propAccordionView() {
        const PropItem = styled.div`
            .lock-icon {
                background-image: url("${Window.static_path}img/icons/lock.svg");
            }

            &:hover {
                .lock-icon {
                    background-image: url("${Window.static_path}img/icons/unlocked.svg");
                }
            }
        `
        const LockedUnlockedIcon = styled.div`
            margin-right: 8px;
            width: 20px;
            height: 20px;
            background-size: contain;
            display: inline-block;
        `

        let desc = <>Som gratis medlem får du låsa upp max 5 gratis begränsad skogsbruksplaner. Välj vilken fastigheter du vill generera skogsbruksplan på nedan</>;
        if (this.state.usedProps >= Window.react_data.allowedProps) {
            desc = <>Du är inte Pluskund och använder just nu {this.state.usedProps}/{Window.react_data.allowedProps} gratis begränsade skogsbruksplaner. Vill du se all information? <a href="/primakund/">Bli pluskund här</a>. Eller vill du låsa upp fler fastigheter får du kontakta oss via <a href="mailto:info@virkesborsen.se">mejl</a> eller ringa oss på <a href="tel:00468339944">08-33 99 44</a>.</>;
        }

        if (Window.user_data.isPremium) {
            if (this.state.usedProps >= Window.react_data.allowedProps) {
                desc = <>Du är pluskund och använder just nu {this.state.usedProps}/{Window.react_data.allowedProps} fastigheter, vill du låsa upp och aktivera Pluskund på fler fastigheter får du kontakta oss via mejl på <a href="mailto:info@virkesborsen.se">info@virkesborsen.se</a> eller ringa oss på <a href="tel:00468339944">08-33 99 44</a>.</>;
            } else {
                desc = <>Du är Pluskund och använder just nu {this.state.usedProps}/{Window.react_data.allowedProps} skogsbruksplaner välj vilken fastighet du vill låsa upp och aktivera Pluskund på.</>;
            }
        }

        let sortedOwnedProps = this.state.ownedProps.sort((a,b) => a.locked - b.locked)

        return <>
            <p className="border-bottom border-2 border-primary-main subtitle fw-bold pb-2 mb-2 supp-forest-green">{desc}</p>
            <ul className='list-group list-group-flush overflow-auto' style={{maxHeight: '40vh'}}>
                {sortedOwnedProps.map((itm) => {
                    return <li className={`list-group-item ${itm.selected ? "bg-primary-core-200" : ""} pe-3`}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <PropItem className={`d-flex align-items-center subtitle ${itm.selected ? "fw-bold" : ""} ${itm.locked ? "opacity-5" : ""}`} role="button" onClick={() => {itm.locked ? this.handleUnlockProperty(itm) : this.updateSelectedProp(itm)}}>
                                    {itm.locked ? <LockedUnlockedIcon className='lock-icon'/> : <div className="d-flex border border-2" style={{width: "25px", height: "25px", marginRight:"8px"}}>{itm.selected && <img src={`${Window.static_path}img/icons/checkmark.svg`} className="forest-green-filter" alt="checkbox" />}</div>}

                                    {itm.type == "SBO" && <img src= {`${Window.static_path}img/icons/forestand-colored.svg`} width="24px" height="24px" />}
                                    <span>{itm.label}</span>
                                </PropItem>
                            </div>
                            <div className="d-flex justify-content-between">
                                <img src={`${Window.static_path}img/icons/trash.svg`} role="button" className={`hover-supp-redwood-filter ${itm.locked ? 'opacity-5' : ''}`} width="20px" height="20px" onClick={() => { this.setState({ deletePropertyModal: true, selectedDeleteProp: itm }) }} />
                            </div>
                        </div>
                    </li>
                })}
            </ul>

            <button className='n-btn btn-core btn-sm rounded w-100 mt-2' onClick={() => this.accessProperties()} >
                Visa valda
            </button>

            <div className="border-top border-bottom border-2 border-primary-main py-2 mt-2">
                <button className='n-btn btn-forest-green btn-sm rounded w-100' onClick={() => this.setState({ propAccordionState: "add" }) } >
                    Lägg till fastighet +
                </button>
            </div>

            <p className="subtitle mb-0 mt-2 supp-forest-green" role="button" onClick={() => { this.selectForestandFile() }}>
                Du kan genom att klicka <b>här</b> importera en skogsbruksplan i formatet forestand (.xml)
            </p>

            <input onChange={(e) => {
                this.setState({ AccordionActive: null })
                this.props.onDivideStart();
                this.sendForestandToBackend(e);
            }} id="hidden_file_upload_forestand" className='position-absolute' type="file" style={{ "opacity": 0, "width": "1px", "height": "1px", zIndex: -1000 }} />
        </>
    }

    searchProp(val){
        if (val.trim() !== '') {
            searchProps(val).then((result) => {
                if (result.error) {
                    this.setState({ propAccordionState: 'view', maintenanceModal: true, maintenanceModalText: result.error })
                } else {
                    //filter already added props from results
                    let filteredResults = result.filter((itm) =>!this.state.currentlySelectedPropertiesArray.some(p => p.registerenhet === itm.registerenhet)&&!this.state.ownedProps.map(itm => itm.value).includes(itm.registerenhet))
                    this.setState({ propSearchResults: filteredResults })
                }
            })
        }
        else {
            this.setState({ propSearchResults: [] })
        }
    }
    
    handleFetchPropsError(data, onlyDivide) {
        let filteredProps = data
        let removedProps = []
        let errorMessages = []
        let sendContact = false
        
        data.map(prop => {
            if(prop.json.hasOwnProperty("is_running") && !onlyDivide) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                errorMessages.push(<>Din digitala skogsbruksplan för {prop.info.label} är redo inom några sekunder. Du får ett mail när det är genererat.</>)
            } else if(prop.json.hasOwnProperty("is_broken") && !onlyDivide) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                sendContact = true
                errorMessages.push(<>Något gick fel med fastighet {prop.info.label}.</>)
            } else if(prop.json.hasOwnProperty("over_limit") && !onlyDivide) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                sendContact = true
                errorMessages.push(<>Du har redan löst in alla dina skogsbruksplaner och kan därför inte låsa upp {prop.info.label}.</>)
            } else if(prop.json.hasOwnProperty("to_small")) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                errorMessages.push(<>Fastighet {prop.info.label} är för liten och kan därför inte genereras.</>)
            } else if(prop.json.hasOwnProperty("exceeding_delete_limit")) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                sendContact = true
                errorMessages.push(<>Det går inte att låsa upp {prop.info.label} då ditt konto är övertrasserat.</>)
            }
        })

        if(errorMessages.length != 0) {
            this.setState({errorModal: true, errorModalMessage:<>{errorMessages.map(m => <>{m}<br /></>)}{sendContact && <>Hör av dig till oss på <a href="tel:00468339944" className="fw-bold">08-33 99 44</a> eller <a href="mailto:info@treebula.se" className="fw-bold">info@treebula.se</a> så hjälpa vi dig vidare.</>}</>})
        }

        return {filtered: filteredProps, removed: removedProps}
    }

    addProp(prop) {
        let prop_obj = {value: prop.registerenhet, label: prop.beteckning, locked: true}
        if (!this.state.currentlySelectedPropertiesArray.some(p => p.registerenhet === prop_obj.value)) {
            this.setState({ propAccordionState: "view", propSearchResults:[], propSearchString:'' }, () => {
                createEmptyProp(prop.registerenhet, prop.beteckning).then(resp => {
                    if (resp.set_visible) {
                        this.setState({ ownedProps: [...this.state.ownedProps, prop_obj] })
                    } else if (resp.created) {
                        fetchProperties([prop_obj]).then(data => {
                            let props = this.handleFetchPropsError(data, true)
                            if(props.removed.length == 0) {
                                this.setState({ ownedProps: [...this.state.ownedProps, prop_obj] })
                            }
                        })
                    } else {
                        this.setState({errorModal: true})
                    }
                })
            })
        }
    }
    
    propAccordionAdd() {
        return <>
            <p className='subtitle fw-bold'>Lägg till fastighet 
                <img src={`${Window.static_path}img/icons/cross.svg`} className="float-end" width="20" height="20" role="button" onClick={() => this.setState({ propAccordionState: "view" })} />
            </p>

            <p className='subtitle mb-1'>Skriv in din fastighetsbeteckning nedan (Exempel Eskilstuna Kullen 1:2)</p>

            <div className='col-12 position-relative rounded shadow p-2'>
                <VBSearchInput
                    ref={this.searchInputRef}
                    placeholder='Sök eller eg: Sevedstorp 1:8'
                    onChange={(val) => {
                        this.setState({
                            propSearchString:val.trim()},
                            ()=>{this.searchProp(val);
                            })
                        }}
                    searchResult={
                        (this.state.propSearchString !== '') ? (
                            (this.state.propSearchResults && this.state.propSearchResults.length > 0) ? (
                            this.state.propSearchResults.map(prop => (
                                <p className="option w-100 ps-2" onClick={() => { this.addProp(prop) }}>{prop.beteckning}</p>
                            ))
                            ) : (
                            <p>Inga träffar på den sökningen.</p>
                            )
                        ) : (
                            null
                        )
                        }
                />
            </div>
        </>
    }

    handleUnlockProperty(prop) {
        if (this.state.usedProps < this.state.allowedProps) {
            this.setState({ unlockProp: prop }, () => {
                this.setState({ confirmUnlockModal: true })
            })
        } else {
            this.setState({ exceedLimitModal: true })
        }
    }

    accessProperties() {
        let selectedProps = this.state.ownedProps.filter(p => p.selected)
        if (!Window.user_data.isPremium) {
            setTimeout(() => { this.setState({ showPrimakundPopup: true }) }, 30000);
        }

        this.props.onDivideStart();
        gtag('event', 'Created_SBP', {});

        this.setState({ CollapseActive: false, activeProps: [] }, () => {
            fetchProperties(selectedProps).then(data => {
                let props = this.handleFetchPropsError(data, false)
                this.setState({ activeProps: props.filtered })
                this.props.onDivideFinish(props.filtered)
                Cookies.set('activeProps', props.filtered.map(p => p.info.value).join(','))
                if(props.removed.length > 0) {
                    let ownedProps = this.state.ownedProps
                    props.removed.map(p => {
                        ownedProps.find(i => i.value == p.info.value).selected = false
                    }, () => this.setState({ownedProps: ownedProps}))
                }
            })
        })
    }

    render() {
        return <>
            <div id="propSelector" className={`col-12 position-relative rounded bg-secondary-light p-2`} style={{ maxWidth: "450px", zIndex: "1000" }}>
                <div className="d-flex justify-content-between align-items-center bg-white user-select-none subtitle p-2" role="button" style={{color:(this.state.activeProps.length != 0 ? "#1C332A" : "#C4D0CD")}} onClick={() => { this.setState({ CollapseActive: !this.state.CollapseActive }) }}>
                    {this.state.activeProps.length == 0 ? "Välj Fastighet" : this.state.activeProps.length == 1 ? this.state.activeProps[0].info.label : `[ ${this.state.activeProps.length} fastigheter valda ]`}<img src={`${Window.static_path}img/icons/caret.svg`} height="25" width="25" className={this.state.CollapseActive ? "rotate-75" : "rotate-25"} style={{ transition: "transform .25s ease" }} />
                </div>

                <Collapse className="bg-white p-2" in={this.state.CollapseActive}>
                    <div>{this.state.propAccordionState == "view" ? this.propAccordionView() : this.propAccordionAdd()}</div>
                </Collapse>
            </div>

            <Modal show={this.state.confirmUnlockModal} onHide={() => this.setState({ confirmUnlockModal: false })} size="md" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">Lås upp en ny fastighet</h2>
                </Modal.Header>
                {this.state.unlockProp && <>
                    <Modal.Body className="py-0">
                        <p className='white'>Är du säker på att du vill låsa upp <span className="fw-bold sage">{this.state.unlockProp.label}</span>?</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between align-items-center border-0">
                        <a href="" className="primary-main subtitle fw-bold" onClick={() => this.setState({ confirmUnlockModal: false })}>Lås inte upp</a>
                        <button type="button" className="btn btn-secondary btn-sm" onClick={() => {
                            unlockProperty(this.state.unlockProp.value).then(resp => {
                                if (resp.ok) {
                                    let ownedProps = this.state.ownedProps
                                    ownedProps.find(p => p.value == this.state.unlockProp.value).locked = false
        
                                    this.setState({ ownedProps: ownedProps, usedProps: this.state.usedProps + 1, confirmUnlockModal: false})
        
                                    if (!Window.user_data.isPremium) {
                                        setTimeout(() => { this.setState({ showPrimakundPopup: true }) }, 30000);
                                    }
                                } else {
                                    resp.json().then(json => {
                                        if(json.hasOwnProperty("error") && json.error == "exceeding_delete_limit") {
                                            this.setState({confirmUnlockModal: false, errorModal: true, errorModalMessage: <>Vårt system ser att du är intresserad av flera fastigheter. Hör av dig till oss på <a href="mailto:info@virkesborsen.se:">info@virkesborsen.se</a> så hjälper vi dig.</>})
                                        } else {
                                            this.setState({confirmUnlockModal: false, errorModal: true})
                                        }
                                    })
                                }
                            })
                        }}>Lås upp</button>
                    </Modal.Footer>
                </>}
            </Modal>

            <Modal show={this.state.maintenanceModal} onHide={() => this.setState({ maintenanceModal: false })} size="md" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">Pågående underhåll</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className='white'>{this.state.maintenanceModalText && this.state.maintenanceModalText}</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-0">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.setState({ maintenanceModal: false })}>Fortsätt</button>
                </Modal.Footer>
            </Modal>
            
            {this.state.waitingForDivideResp === false ? <Modal show={this.state.deletePropertyModal} onHide={() => this.setState({ deletePropertyModal: false })} size='md' contentClassName='bg-primary-dark p-2' centered>
                <Modal.Header className='align-items-start border-0' closeVariant='white' closeButton>
                    <h2 className="primary-main mb-0">Är du säker på att du vill radera {this.state.selectedDeleteProp.label}?</h2>
                </Modal.Header>
                <Modal.Footer className="justify-content-between border-0">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.setState({ deletePropertyModal: false })}>Nej</button>
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => {
                        deleteProperty(this.state.selectedDeleteProp.value)
                        this.props.deleteProps(this.state.selectedDeleteProp)
                        let activePropsLength = this.state.activeProps.length
                        let filteredOwnedProps = this.state.ownedProps.filter(item => item.value != this.state.selectedDeleteProp.value)
                        let filteredActiveProps = this.state.activeProps.filter(item => item.info.value != this.state.selectedDeleteProp.value)
                        //If property is unlocked update usedProps immediately
                        if (this.state.selectedDeleteProp.locked === false) {
                            let usedprops = this.state.usedProps - 1
                            this.setState({ usedProps: usedprops })
                        }
                        if (activePropsLength != filteredActiveProps.length) {
                            this.props.onDivideStart();
                            this.props.updateMapDeletedProp(this.state.selectedDeleteProp)
                        }
                        this.setState({ ownedProps: filteredOwnedProps, activeProps: filteredActiveProps, deletePropertyModal: false })
                    }}>Radera</button>
                </Modal.Footer>
            </Modal> : null
            }

            <Modal show={this.state.exceedLimitModal} onHide={() => this.setState({ exceedLimitModal: false })} size="md" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">Gränsen nådd</h2>
                </Modal.Header>

                <Modal.Body>
                    <p className="white mb-3">Det verkar som att du har löst in alla dina skogsbruksplaner.</p>

                    <p className="white mb-0">Vill du låsa upp fler fastigheter får du kontakta oss via mejl på <a className="primary-main" href="mailto:info@treebula.se">info@treebula.se</a> eller ringa oss på <a className="primary-main" href="tel:00468339944">08-33 99 44</a>.</p>
                </Modal.Body>

                <Modal.Footer className="justify-content-end border-0">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => { this.setState({ exceedLimitModal: false }) }}>Fortsätta</button>
                </Modal.Footer>
            </Modal>

            <ErrorModal show={this.state.errorModal} message={this.state.errorModalMessage} onToggle={() => this.setState({errorModal: !this.state.errorModal, errorModalMessage: null})} />
        </>
    }
}

export default PropSelector;