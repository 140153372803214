import VBCarousel from "@virkesborsen/vb-js-commons/lib/components/vbCarousel";
import VBErrorBoundary from "@virkesborsen/vb-js-commons/lib/components/vbErrorBoundaryHandler";

import Login from "./login/login";
import ProfileAdListings from "./profile/ad-listings";
import Complementinfo from "./login/complementinfo";

import PriceMap from "./treebula/price-map";

import Footer from "./vb-django/components/static/Footer.js";
import Navigation from "./vb-django/components/static/Navigation.js";
import Error404 from "./vb-django/pages/Error404.js";

// Other pages
import KnowYourCustomerPage from "./vb-django/pages/KnowYourCustomer";
import ProfileBuyer from "./profile/profile-buyer.js";
import FAQPage from "./vb-django/pages/FAQPage";
import ForestCataloguePage from "./vb-django/pages/ForestCatalogue";
import PropertyValuePage from "./vb-django/pages/PropertyValue";
import ContactSellerPage from "./vb-django/pages/ContactSeller";
import ProfileEditPage from "./vb-django/pages/ProfileEdit";

// SBP
import KartaPage from "./vb-django/pages/sbp/SBPKarta";
import OverblickPage from "./vb-django/pages/sbp/SBPOverblick";
import RiskerPage from "./vb-django/pages/sbp/SBPRisker";
import EconomyPage from "./vb-django/pages/sbp/SBPEconomy";

// Auctions
import AuctionDetailsPage from "./vb-django/pages/auctions/AuctionDetails.js";
import AuctionsCreatePage from "./vb-django/pages/auctions/AuctionsCreate.js";
import Auctions from "./vb-django/pages/auctions/Auctions";

// Puck
import Editor from "./vb-django/components/editor/Editor";
import PuckPageView from "./vb-django/components/editor/PuckPageView";

require('es6-promise').polyfill();
require('isomorphic-fetch');
require('./polyfills.js');


export default {
  "VBErrorBoundary": VBErrorBoundary,
  "VBCarousel": VBCarousel,
  "ProfileAdListings": ProfileAdListings,
  "Login": Login,
  "Complementinfo": Complementinfo,
  "PriceMap": PriceMap,
  "Footer": Footer,
  "Navigation": Navigation,
  "Error404": Error404,

  // Here comes the new stuff
  "KnowYourCustomerPage": KnowYourCustomerPage,
  "KartaPage": KartaPage,
  "OverblickPage": OverblickPage,
  "EconomyPage": EconomyPage,
  "ProfileBuyer": ProfileBuyer,
  "FAQPage": FAQPage,
  "ForestCataloguePage": ForestCataloguePage,
  "PropertyValuePage": PropertyValuePage,
  "ContactSellerPage": ContactSellerPage,
  "ProfileEditPage":ProfileEditPage,
  // Puck
  "Editor": Editor,
  "PuckPageView": PuckPageView,
  "RiskerPage":RiskerPage,
  "AuctionDetailsPage":AuctionDetailsPage,
  "AuctionsCreatePage":AuctionsCreatePage,
  "Auctions":Auctions,
}