import React from 'react';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';

import { validateEmail, validateForm, userExists, registerUser } from '../api';
import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';

class PropertyValueForm extends React.Component {
    constructor() {
        super();

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            errors: {}
        }
    }

    handleSubmit(e) {
        e.preventDefault()

        let checkList = [];
        let data = this.state

        if (this.state.isLogged) {
            checkList.push('muni', 'area')
        } else if (this.state.isReg == true) {
            checkList.push('email', 'muni', 'area')
        } else {
            checkList.push('roles', 'email', 'muni', 'mobile_phone_number', 'area', 'checkbox')
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg)

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            this.setState({ loading: true, errors: {} })

            if (!this.state.isLogged && this.state.isReg === false) {
                registerUser({ email: data.email, roles: data.roles, muni: data.muni, mobile_phone_number: data.mobile_phone_number }).then(res => {
                    if (res.ok) {
                        setTimeout(() => {
                            gtag('event', 'Registered', {});
                            gtag("event", "sign_up", { method: "Site", })
                        }, 100);
                        this.setState({ isLogged: true })
                        window.location.href = `/fastighetsvardering/?muni=${data.muni}&area=${data.area}&register=true`;
                    } else {
                        this.setState({ isLogged: true, loading: false })
                    }
                })
            } else {
                if(this.state.isReg == true) {
                    window.location.href = `/fastighetsvardering/?muni=${data.muni}&area=${data.area}&email=${data.email}`;
                } else {
                    window.location.href = `/fastighetsvardering/?muni=${data.muni}&area=${data.area}`;
                }
            }
        }
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists })
                        })
                    }
                })
            }, 200)
        }
    }

    render() {
        return <>
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className={`bg-${this.props.backgroundColor} ${this.props.textColor}`} style={{ borderRadius: "40px", padding: "32px" }}>
                <div>
                    <p className="n-fs-3 fw-medium">Vad är skogen värd?</p>
                    <p className='mb-4'>Det är många faktorer som påverkar priset av skogsmark. Här ges en indikation av din fastighets värde men variationen kring medelvärdet är stort. Fyll i nedan parametrar och du får priset direkt – snabbt, enkelt och kostnadsfritt.</p>

                    <form onSubmit={(e) => { this.handleSubmit(e) }}>
                        <VBDropdown
                            label={<label className='n-fs-7' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Kommun</label>}
                            placeholder="Välj kommun"
                            options={Window.react_data.lists.munis}
                            error={this.state.errors.muni}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                            onChange={(val) => { this.setState({ muni: val.value }) }} />

                        <VBInput
                            label={<label className='n-fs-7 mt-3' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Skogsareal</label>}
                            placeholder="Välj areal"
                            type="number"
                            error={this.state.errors.area}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, area: '' } })}
                            onChange={(val) => { this.setState({ area: val }); }} />


                        {!this.state.isLogged &&
                            <VBInput
                                label={<label className='n-fs-7 mt-3' style={{ color: "rgba(0, 0, 0, 0.40)" }}>E-postadress</label>}
                                placeholder="E-postadress"
                                error={this.state.errors.email}
                                onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                                onChange={(val) => { this.checkEmail(val); this.setState({ email: val }); }} />
                        }

                        {!this.state.isLogged && this.state.isReg == false && <>
                            <VBPhoneInput
                                label={<label className='n-fs-7 mt-3' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Mobilnummer</label>}
                                error={this.state.errors.mobile_phone_number}
                                onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                                onChange={(val) => { this.setState({ mobile_phone_number: val }) }} />

                            <VBDropdown
                                label={<label className='n-fs-7 mt-3' style={{ color: "rgba(0, 0, 0, 0.40)" }}>Välj en roll</label>}
                                options={Window.react_data.lists.roles}
                                error={this.state.errors.roles}
                                onFocus={() => this.setState({ errors: { ...this.state.errors, roles: '' } })}
                                onChange={(val) => { this.setState({ roles: val.value }) }} />

                            <div className='d-flex flex-column mt-3'>
                            {this.state.errors?.checkbox && 
                                <p className='text-danger n-fs-7 mb-1'>{this.state.errors?.checkbox}</p>
                            }
                                <div className='d-flex align-items-center'>
                                    <VBCheckbox
                                        label=""
                                        initial={false}
                                        onClick={() => { this.setState({ checkbox: !this.state.checkbox, errors: { ...this.state.errors, checkbox: '' } }) }} />

                                    <p className='mb-0 n-fs-7' style={{ color: `${this.props.termsColor}` }}>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/"  className={`text-decoration-underline ${this.props.textColor}`}>användarvillkor</a> och <a href="/privacy/" className={`text-decoration-underline ${this.props.textColor}`}>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                                </div>
                            </div>
                        </>}

                        <div className='mt-4'>
                            <button type="submit" className='n-btn btn-core w-100' onClick={(e) => { this.handleSubmit(e) }}>Visa fastighetsvärdering<span><img className='ms-2' width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    }
}

PropertyValueForm.defaultProps = {
    textColor: "neutral-charcoal",
    backgroundColor: "white",
}

PropertyValueForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },

}
export default PropertyValueForm;
