import React from 'react';
import Cookies from 'js-cookie';
import Modal from 'react-bootstrap/Modal';
import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';
import { searchProps, createEmptyProp, fetchProperties, unlockProperty } from '../map_api';
import styled from "styled-components";

const PropertyModal= styled(Modal)`
    .modal-dialog {
        max-width: 500px;
        width: 90%;
      }
@media (min-width: 992px) {
    .modal-dialog{
    left: 270px;
    margin-left: calc(55% - 450px)
    }
}
`

class PropModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            propSearchResults: [],
            ownedProps: Window.react_data.ownedProps,
            currentlySelectedPropertiesArray: [],
            searchedText: "",
        }
        this.searchInputRef = React.createRef()

    }
    onDivideFinish(json, geojson, itm) {
        this.props.onDivideFinish(json, geojson, itm);
    }

    searchProp(val){
        if (val.trim() !== '') {
            searchProps(val).then((result) => {
                let filteredResults = result.filter((itm) =>!this.state.currentlySelectedPropertiesArray.some(p => p.registerenhet === itm.registerenhet)&&!this.state.ownedProps.map(itm => itm.value).includes(itm.registerenhet))
                this.setState({ propSearchResults: filteredResults })
            })
        }
        else {
            this.setState({ propSearchResults: [] })
        }
    }

    handleFetchPropsError(data, onlyDivide) {
        let filteredProps = data
        let removedProps = []
        let errorMessages = []
        let sendContact = false
        
        data.map(prop => {
            if(prop.json.hasOwnProperty("is_running") && !onlyDivide) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                errorMessages.push(<>Din digitala skogsbruksplan för {prop.info.label} är redo inom några sekunder. Du får ett mail när det är genererat.</>)
            } else if(prop.json.hasOwnProperty("is_broken") && !onlyDivide) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                sendContact = true
                errorMessages.push(<>Något gick fel med fastighet {prop.info.label}.</>)
            } else if(prop.json.hasOwnProperty("over_limit") && !onlyDivide) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                sendContact = true
                errorMessages.push(<>Du har redan löst in alla dina skogsbruksplaner och kan därför inte låsa upp {prop.info.label}.</>)
            } else if(prop.json.hasOwnProperty("to_small")) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                errorMessages.push(<>Fastighet {prop.info.label} är för liten och kan därför inte genereras.</>)
            } else if(prop.json.hasOwnProperty("exceeding_delete_limit")) {
                filteredProps = filteredProps.filter(i => i.info.value != prop.info.value)
                removedProps.push(prop)
                sendContact = true
                errorMessages.push(<>Det går inte att låsa upp {prop.info.label} då ditt konto är övertrasserat.</>)
            }
        })

        if(errorMessages.length != 0) {
            this.setState({errorModal: true, errorModalMessage:<>{errorMessages.map(m => <>{m}<br /></>)}{sendContact && <>Hör av dig till oss på <a href="tel:00468339944" className="fw-bold">08-33 99 44</a> eller <a href="mailto:info@treebula.se" className="fw-bold">info@treebula.se</a> så hjälpa vi dig vidare.</>}</>})
        }

        return {filtered: filteredProps, removed: removedProps}
    }

    addProp(prop) {
        if (!this.state.currentlySelectedPropertiesArray.some(p => p.registerenhet === prop.registerenhet)) {
            this.setState({ currentlySelectedPropertiesArray: [...this.state.currentlySelectedPropertiesArray, prop], propSearchResults:[],searchedText:'' }, () => {
                this.searchInputRef.current.setValue('');
                createEmptyProp(prop.registerenhet, prop.beteckning);
            })
        }
    }

    propertyView() {
        const PropListItem = styled.div`
        cursor: pointer;
        &.SBO{
            position:relative;
            &::before{
                background-image: url("${Window.static_path}img/icons/forestand-colored.svg");
                background-size: contain;
                background-repeat: no-repeat;
                width:24px;
                height:24px;
                display:inline-block;
                content: " ";
                margin-bottom: -3px;
            }
        }
        &.locked{
            position:relative;
            &:hover {
                &::after{
                    background-image: url("${Window.static_path}img/icons/unlocked.svg");
                    filter: invert(15%) sepia(17%) saturate(923%) hue-rotate(105deg) brightness(101%) contrast(94%);
                }
            }
            &::after{
                background-image: url("${Window.static_path}img/icons/lock.svg");
                background-size: contain;
                width:20px;
                height:20px;
                display:inline-block;
                filter: invert(15%) sepia(17%) saturate(923%) hue-rotate(105deg) brightness(101%) contrast(94%);
                content: " ";
                margin-bottom: -3px;
            }
        }
    `

        return <div>
            {this.state.currentlySelectedPropertiesArray.length > 0 && <p className='primary-dark mt-3 h6 fw-bold'>Klicka på den fastigheten du vill låsa upp*</p>}

            {this.state.currentlySelectedPropertiesArray.map((itm) => (
                <PropListItem
                    className={"subtitle my-2 primary-dark locked"}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => {
                        let prop_obj = {value: itm.registerenhet, label: itm.beteckning, locked: true}

                        this.props.onDivideStart();

                        gtag('event', 'Created_SBP', {});

                        unlockProperty(itm.registerenhet).then(resp => {
                            if (resp.ok) {
                                fetchProperties([prop_obj]).then(data => {
                                    let props = this.handleFetchPropsError(data, false)
                                    this.props.onDivideFinish(props.filtered)
                                    Cookies.set('activeProps', props.filtered.map(p => p.info.value).join(','))
        
                                    this.props.updateOwnedProps(this.state.currentlySelectedPropertiesArray.map((itm2) => {
                                        return {value: itm2.registerenhet, label: itm2.beteckning, locked: itm2.registerenhet != itm.registerenhet, selected: itm2.registerenehet != itm.registerenhet}
                                    }))
                                })
                            }
                        })

                        this.props.onHide()

                        if (!Window.user_data.isPremium) {
                            setTimeout(() => { this.setState({ showPrimakundPopup: true }) }, 30000);
                        }
                    }} >
                    {itm.beteckning}
                </PropListItem>
            ))}
        </div>
    }

    render() {
        return (<PropertyModal show={this.props.visible} onHide={() => {this.props.onHide()}} size='lg' contentClassName='bg-white p-2' centered backdrop="static">
            <Modal.Header className="align-items-start border-0" closeVariant="grey">
                <h2 className="primary-dark mb-0 fs-3" style={{ fontFamily: 'Oswald Regular' }}>Lägg till en eller flera fastigheter</h2>
            </Modal.Header>
            <Modal.Body className='py-0'>
                <p className='primary-dark lh-sm' style={{ fontSize: '16px'}}>Du kan generera en skogsbruksplan på 5 fastigheter. Ange fastighetsbeteckningen nedan och sedan väljer du vilken fastighet du vill generera skogsbruksplan på nedan genom att klicka på den.</p>
                <p className="primary-dark fst-italic lh-sm" style={{ fontSize: '16px'}}>Exempelvis Sevedstorp 1:8 eller Ydre Sevedstorp 1:8</p>
                <div className='col-12 position-relative rounded shadow p-2'>
                    <VBSearchInput
                        // label={<label className='subtitle fw-bold pb-2 mt-4'>Ange din fastighetsbeteckning</label>}
                        ref={this.searchInputRef}
                        placeholder='Sök eller eg: Sevedstorp 1:8'
                        onChange={(val) => {
                            this.setState({
                                searchedText:val.trim()},
                                ()=>{this.searchProp(val);
                                })
                            }}
                        searchResult={
                            (this.state.searchedText !== '') ? (
                              (this.state.propSearchResults && this.state.propSearchResults.length > 0) ? (
                                this.state.propSearchResults.map(prop => (
                                  <p className="option w-100 ps-2" onClick={() => { this.addProp(prop) }}>{prop.beteckning}</p>
                                ))
                              ) : (
                                <p>Inga träffar på den sökningen.</p>
                              )
                            ) : (
                             null
                            )
                          }
                    />
                </div>
                <div>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>

                        {this.propertyView()}

                    </ul>
                </div>

            </Modal.Body>
            <Modal.Footer className="justify-content-start border-0">
            </Modal.Footer>
        </PropertyModal>
        )
    }
}
export default PropModal;