import * as Comp from './PuckComps';
import {v4 as uuidv4} from 'uuid';

// Create Puck component config
const config = {
    components: {
        // Tools
        Columns: {
            fields: Comp.Columns.fields,
            defaultProps: Comp.Columns.defaultProps,
            resolveData: async ({props}, {changed}) => {
                if(changed.columns) {
                    if(props.columns.length > 12) { props.columns.pop() }
                }


                return {};
            },
            render: ( props ) => <Comp.Columns {...props} />
        },
        ColumnsMasonry: {
            fields: Comp.ColumnsMasonry.fields,
            defaultProps: Comp.ColumnsMasonry.defaultProps,
            resolveData: async ({props}, {changed}) => {
                if(changed.columns) {                   
                    if(props.columns.length > 24) { props.columns.pop() }
                }  
                return {};
            },
            render: ( props ) => <Comp.ColumnsMasonry {...props} />
        },
        CardComponent: {
            fields: Comp.CardComponent.fields,
            defaultProps: Comp.CardComponent.defaultProps,
            render: (props) => <Comp.CardComponent {...props} />
        },
        VerticalSpace: {
            fields: Comp.VerticalSpace.fields,
            defaultProps: Comp.VerticalSpace.defaultProps,
            render: (props) => <Comp.VerticalSpace {...props} />
        },
        Header: {
            fields: Comp.Header.fields,
            defaultProps: Comp.Header.defaultProps,
            render: (props) => <Comp.Header {...props} />
        },

        Text: {
            fields: Comp.Text.fields,
            defaultProps: Comp.Text.defaultProps,
            render: (props) => <Comp.Text {...props} />
        },
        Image: {
            fields: Comp.Image.fields,
            defaultProps: Comp.Image.defaultProps,
            render: (props) => <Comp.Image {...props} />
        },
        Icon: {
            fields: Comp.Icon.fields,
            defaultProps: Comp.Icon.defaultProps,
            render: (props) => <Comp.Icon {...props} />
        },
        Video: {
            fields: Comp.Video.fields,
            defaultProps: Comp.Video.defaultProps,
            render: (props) => <Comp.Video {...props} />
        },
        Anchor: {
            fields: Comp.Anchor.fields,
            defaultProps: Comp.Anchor.defaultProps,
            render: (props) => <Comp.Anchor {...props} />
        },
        Button: {
            fields: Comp.Button.fields,
            defaultProps: Comp.Button.defaultProps,
            render: (props) => <Comp.Button {...props} />
        },
        List: {
            fields: Comp.List.fields,
            defaultProps: Comp.List.defaultProps,
            render: (props) => <Comp.List {...props} />
        },
        Posts: {
            fields: Comp.Posts.fields,
            defaultProps: Comp.Posts.defaultProps,
            render: (props) => <Comp.Posts {...props} />
        },
        PostsVertical: {
            fields: Comp.PostsVertical.fields,
            defaultProps: Comp.PostsVertical.defaultProps,
            render: (props) => <Comp.PostsVertical {...props} />
        },
        PostsPopular: {
            fields: Comp.PostsPopular.fields,
            defaultProps: Comp.PostsPopular.defaultProps,
            render: (props) => <Comp.PostsPopular {...props} />
        },
        Avatar: {
            fields: Comp.Avatar.fields,
            defaultProps: Comp.Avatar.defaultProps,
            render: (props) => <Comp.Avatar {...props} />
        },
        // New components

        HeroMetric: {
            fields: Comp.HeroMetric.fields,
            defaultProps: Comp.HeroMetric.defaultProps,
            render: (props) => <Comp.HeroMetric {...props} />
        },
        ContactCard: {
            fields:Comp.ContactCard.fields,
            defaultProps: Comp.ContactCard.defaultProps,
            render: (props) => <Comp.ContactCard {...props} />
        },
        ImgContentCard: {
            fields: Comp.ImgContentCard.fields,
            defaultProps: Comp.ImgContentCard.defaultProps,
            render: (props) => <Comp.ImgContentCard {...props} />

        },
        FeatureStepsLayout: {
            fields: Comp.FeatureStepsLayout.fields,
            defaultProps: Comp.FeatureStepsLayout.defaultProps,
            render: (props) => <Comp.FeatureStepsLayout {...props} />
        },
        ContentSectionLayout: {
            fields: Comp.ContentSectionLayout.fields,
            defaultProps: Comp.ContentSectionLayout.defaultProps,
            render: (props) => <Comp.ContentSectionLayout {...props} />
        },
        FeaturesLayout: {
            fields: Comp.FeaturesLayout.fields,
            defaultProps: Comp.FeaturesLayout.defaultProps,
            render: (props) => <Comp.FeaturesLayout {...props} />
        },
        FAQ: {
            fields: Comp.FAQ.fields,
            defaultProps: Comp.FAQ.defaultProps,
            render: (props) => <Comp.FAQ {...props} />
        },
        Metrics: {
            fields: Comp.Metrics.fields,
            defaultProps: Comp.Metrics.defaultProps,
            render: (props) => <Comp.Metrics {...props} />
        },
        SocialMedia: {
            fields: Comp.SocialMedia.fields,
            defaultProps: Comp.SocialMedia.defaultProps,
            render:(props) =><Comp.SocialMedia {...props} />
        },
        
        CustomerTestimonials: {
            fields: Comp.CustomerTestimonials.fields,
            defaultProps: Comp.CustomerTestimonials.defaultProps,
            render: (props) => <Comp.CustomerTestimonials {...props} />
        },
        TestimonialWithImage: {
            fields: Comp.TestimonialWithImage.fields,
            defaultProps: Comp.TestimonialWithImage.defaultProps,
            render: (props) => <Comp.TestimonialWithImage {...props} />
        },
        PricingSection: {
            fields: Comp.PricingSection.fields,
            defaultProps: Comp.PricingSection.defaultProps,
            resolveData: async ({ props }) => {
                if (props.options.length !== 2) {
                    console.log("Pricing");
                    return Comp.PricingSection.defaultProps.options;
                }

            return props.options;
            },
            render: (props) => <Comp.PricingSection {...props} />
        },
        EarningsComparison: {
            fields: Comp.EarningsComparison.fields,
            defaultProps: Comp.EarningsComparison.defaultProps,
            render: (props) => <Comp.EarningsComparison {...props} />
        },

        // Forms
        SellerLandingForm:{
            fields: Comp.SellerLandingForm.fields,
            defaultProps:Comp.SellerLandingForm.defaultProps,
            render:(props) =><Comp.SellerLandingForm {...props} />
        },
        PrimakundForm: {
            fields: Comp.PrimakundForm.fields,
            defaultProps:Comp.PrimakundForm.defaultProps,
            render: (props) => <Comp.PrimakundForm {...props} />,
        },

        GrotForm: {
            fields: Comp.GrotForm.fields,
            defaultProps:Comp.GrotForm.defaultProps,
            render: (props) => <Comp.GrotForm {...props} />,
        },

        PropertyValueForm: {
            fields: Comp.PropertyValueForm.fields,
            defaultProps: Comp.PropertyValueForm.defaultProps,
            render: (props) => <Comp.PropertyValueForm {...props} />,
        },
        ContactForm:{
            fields: Comp.ContactForm.fields,
            defaultProps: Comp.ContactForm.defaultProps,
            render: (props) => <Comp.ContactForm {...props} />
        }, 
        
        LoginRegisterForm:{
            fields: Comp.LoginRegisterForm.fields,
            defaultProps: Comp.LoginRegisterForm.defaultProps,
            render: (props)=> <Comp.LoginRegisterForm {...props}/>
        },
        BrevoSignUpForm:{
            fields: Comp.BrevoSignUpForm.fields,
            defaultProps: Comp.BrevoSignUpForm.defaultProps,
            render: (props)=> <Comp.BrevoSignUpForm {...props}/>
        },

        // Static
        Navigation: {
            render: () => <Comp.Navigation />,
        },
        Footer: {
            fields: Comp.Footer.fields,
            defaultProps: Comp.Footer.defaultProps,
            render: (props) => <Comp.Footer {...props} />,
        },
        PostCommon: {
            fields: Comp.PostCommon.fields,
            defaultProps: Comp.PostCommon.defaultProps,
            render: (props) => <Comp.PostCommon {...props} />,
        }

        },
        categories: {
            commons: {
                defaultExpanded: false,
                components: ["Anchor", "Avatar", "Button", "CardComponent", "Columns", "ColumnsMasonry", "ContactCard", "Header", "Icon", "Image", "List", "Text", "VerticalSpace", "Video"]
            },
            sections: {
                defaultExpanded: false,
                components: ["ContentSectionLayout", "CustomerTestimonials", "EarningsComparison", "FAQ", "FeaturesLayout", "FeatureStepsLayout", "HeroMetric", "ImgContentCard", "Metrics", "PricingSection", "SocialMedia",  "TestimonialWithImage", ]
            },
            posts: {
                defaultExpanded: false,
                components: ["Posts", "PostsPopular", "PostsVertical"]
            },
            forms: {
                defaultExpanded: false,
                components: ["BrevoSignUpForm", "ContactForm", "GrotForm", "LoginRegisterForm", "PrimakundForm", "PropertyValueForm", "SellerLandingForm"]
            },
            static: {
                defaultExpanded: false,
                components: ["Footer", "PostCommon", "Navigation"]
            },
            
        }
        
    }
  
export { config }