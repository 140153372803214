import React from 'react';
import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';
import { getPropsAndArea, searchProps, getShapeInfo, onlyDivideDataRequest } from '../SBP/map_api';
import styled from "styled-components";
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/vbLeaflet';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBDatePicker from '@virkesborsen/vb-js-commons/lib/components/new/vbDatePicker';
import VBRadioGroup from '@virkesborsen/vb-js-commons/lib/components/new/vbRadioGroup';
import Cookies from 'js-cookie';
import Modal from 'react-bootstrap/Modal';


class InfoIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  render() {
    return (
      <div>
        <img className="d-md-none neutral-charcoal-filter ms-2" style={{ width: "28px", height: "28px" }} src={Window.static_path + "img/icons/info-circle.svg"} onClick={this.toggleModal} />

          <Modal show={this.state.showModal} onHide={this.toggleModal}>
            <Modal.Header closeButton>
              <p className='fw-medium m-0'>{this.props.title}</p>
            </Modal.Header>
            <Modal.Body>
              {this.props.instructions}
              <button className='mt-2 n-btn btn-forest-green' onClick={this.toggleModal}>Stäng</button>
            </Modal.Body>
          </Modal>
      </div>
    )
  }
}

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    
    const getDate = d => (d ? new Date(d) : null)

    this.state = {
      propSearchResults: [],
      currentlySelectedPropertiesArray: [],
      allow_map_edit: true,
      searchedText: "",
      loading: false,
      propertyLayers: {},
      map: null,
      loading_message: <h2 className="white w-100 text-center pt-1">Vänta...</h2>,
      type: this.props.adData.type,
      startAdDate: getDate(this.props.adData.start_time),
      endAdDate: getDate(this.props.adData.end_time),
      startFellingDate: getDate(this.props.adData.felling_start_time),
      endFellingDate: getDate(this.props.adData.felling_end_time),
      adLayers: [],
      markers: [],
      showFullTextSellingOption: false,
      selectedShape: null,
      propArea:0,
      propAreaMap:{},
      showInstructions: false,
      legendData: [],
      showFellingButton: true,
      hideFellingButton: false
  }
  this.searchInputRef = React.createRef()
  this.map = React.createRef()
  this.adLayers = new L.FeatureGroup();
}

handleReadMoreSellingOptionClick = () => {
  this.setState((prevState) => ({ showFullTextSellingOption: !prevState.showFullTextSellingOption})
  )
};

componentDidMount(){

    gtag('event', 'Created ad draft', {});
    gtag("event", "generate_lead", {
        currency: "SEK",
        value: 585
      });

 
  setTimeout(() => {
    if(this.props.adData.custom_area){
      this.setState({allow_map_edit:false})
      L.geoJSON(this.props.adData.custom_area, {
        interactive: false,
        style: (feature) => {
          return {
            color: "#ff00ff", 
            fillOpacity: 0.8,
            weight: 2.2 + 0.5,
            dashArray: '10, 10'
          };
        },
        onEachFeature: (feature, layer) => {
          layer.addTo(this.adLayers)
        },
      })
      this.adLayers.addTo(this.map.current.state.map);
      try{
        this.map.current.state.map.fitBounds(this.adLayers.getBounds());
      } catch{
        //Layer is only marker can't fit bounds
      
      }
      
    }
  }, 200)
}

searchProp(val){
  if (val.trim() !== '') {
      searchProps(val).then((json) => {
        if(json.error) {
          this.setState({ errorModal: true, errorModalText: json.error })
        } else {
          this.setState({ propSearchResults: json.filter((itm) =>!this.state.currentlySelectedPropertiesArray.some(p => p.registerenhet === itm.registerenhet)) })
        }
      })
  }
  else {
      this.setState({ propSearchResults: [] })
  }
}

addProp(prop) {
  if (!this.state.currentlySelectedPropertiesArray.some(p => p.registerenhet === prop.registerenhet)) {
    const props = [...this.state.currentlySelectedPropertiesArray, prop];
    this.setState({
      currentlySelectedPropertiesArray: props,
      propSearchResults: [],
      searchedText: ''
    }, () => {
      this.searchInputRef.current.setValue(prop.beteckning); // Set the value of VBSearchInput to the selected property's beteckning
      this.updateMapWithSelectedProperty(prop.registerenhet);
    });
    this.props.changeAdData({"description_of_property": props.map(itm => itm.beteckning).join(" & ")});
  }
}

updateMapWithSelectedProperty(registerenhet) {
  getPropsAndArea(registerenhet).then((json) => {
    this.setState((prevState)=>({
      propAreaMap:{
        ...prevState.propAreaMap,
        [registerenhet]:json["area"]
      },
      propArea:prevState.propArea + json["area"]
    }))

    const propertyLayer = L.geoJson(json["geojson"], {
      interactive: false,
      style: (feature) => {
        return {
          color: "#ffffff",
        };
      },
      onEachFeature: (feature, layer) => {
        layer.bringToBack();
        this.map.current.state.map.addLayer(layer);
      }
    });
    const propsLayrs = {
      ...this.state.propertyLayers,
      [registerenhet]: {propertyLayer: propertyLayer, json: json["geojson"]},
    }
    this.setState({
      propertyLayers: propsLayrs,
    });

    this.map.current.state.map.fitBounds(propertyLayer.getBounds());
  });
}

handleDeleteClick(registerenhet) {
  // Filter out the property to be deleted from the currently selected properties array
  const updatedPropertiesArray = this.state.currentlySelectedPropertiesArray.filter(
    (prop) => prop.registerenhet !== registerenhet
  );
  const { [registerenhet]: deletedRegisterenhet, ...rest } = this.state.propAreaMap;
  const deletedArea = deletedRegisterenhet || 0; 

  const propertyLayer = this.state.propertyLayers[registerenhet]?.propertyLayer;
  if (propertyLayer) {
    this.deleteAll()
    propertyLayer.setStyle({ opacity: 0, fillOpacity: 0 });
    this.map.current.state.drawn.clearLayers();
    this.map.current.state.drawn.removeLayer(propertyLayer);
    // Delete the area of the deleted prop from total area
    this.setState((prevState)=>({
      propAreaMap:rest,
      propArea:prevState.propArea - deletedArea
    }))
  }


  const updatedPropertyLayers = { ...this.state.propertyLayers };
  delete updatedPropertyLayers[registerenhet];

  this.setState({
    currentlySelectedPropertiesArray: updatedPropertiesArray,
    propertyLayers: updatedPropertyLayers,
  });

  const description=updatedPropertiesArray.length > 0 ? updatedPropertiesArray.map(itm => itm.beteckning).join(" & ") : "";

  this.props.changeAdData({
    "description_of_property":description 
  });
}


handleAddMarkerButtonClick = () => {
  const addMarkerFunc = (event) => {
    const { lat, lng } = event.latlng;

    const marker = new L.marker([lat, lng])
    // Create a custom popup with a delete button
    const popupContent = document.createElement('div');
    const deleteButton = document.createElement('button');
    deleteButton.className='btn btn-sm btn-outline text-nowrap';
    deleteButton.textContent = 'Ta Bort';
    deleteButton.onclick=()=> {
      if (this.state.adLayers.length > 0) {
        const updatedLayer = this.state.adLayers.filter(item => item.layer._leaflet_id !== marker._leaflet_id)
        this.setState({ adLayers: updatedLayer }, () => {
          this.props.changeAdData({ adLayers: this.state.adLayers })
          marker.remove();
        })
      }
    };
    popupContent.appendChild(deleteButton);
    marker.bindPopup(popupContent);

    this.addLayerToAd(marker, null);

    // Revert cursor style back to default
    this.map.current.state.map.getContainer().style.cursor = 'auto';

    this.map.current.state.map.off('click', addMarkerFunc);
  }

  // Set cursor style to crosshair to indicate marker placement
  this.map.current.state.map.getContainer().style.cursor = 'crosshair';
  // Capture the marker placement
  this.map.current.state.map.on('click', addMarkerFunc);
};

handleDrawButtonClick = () => {
  const ritaButton = document.querySelector('.rita-button');
  if(ritaButton){
    ritaButton.style.display = 'none';
  }
  // Ensure the map is properly initialized
  if (this.map.current.state.map) {
    const map = this.map.current.state.map;
    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);
    
    const drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        circle: false,
        polyline: false,
        marker: false,
        rectangle: false,
        circlemarker: false,
        edit: {
          featureGroup: drawnItems,
          edit: false,
          remove: true
        }
      }
    });
    map.addControl(drawControl);

    drawControl._toolbars.draw._modes.polygon.handler.enable();
    drawControl.getContainer().querySelector('.leaflet-draw-toolbar a').style.display = 'none';
    drawControl.getContainer().querySelector('.leaflet-draw-actions').style.display = 'none';
    
    // Remove the default border on leaflet
    const style = L.DomUtil.create('style');
    style.innerHTML = '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar { border: none !important; }';
    document.head.appendChild(style);

    // Create a wrapper div element to hold the buttons
    const buttonsWrapper = L.DomUtil.create('div', 'buttons-wrapper d-flex flex-column');
    buttonsWrapper.style.marginTop = '90px';

    // Create a button to finish drawing
    const finishButton = L.DomUtil.create('button', 'btn btn-secondary btn-sm mb-1');
    finishButton.innerHTML = 'Spara';
    finishButton.addEventListener('click', () => {
      drawControl._toolbars.draw._modes.polygon.handler.completeShape();
      map.removeControl(finishButton);
      map.removeControl(cancelButton);
      map.removeControl(undoButton);
      buttonsWrapper.remove();
      //show Rita button again
      if(ritaButton){
        ritaButton.style.display = 'inline-block';
      }
    });

    const cancelButton = L.DomUtil.create('button', 'btn btn-secondary btn-sm mb-1');
    cancelButton.innerHTML = 'Avbryt';
    cancelButton.addEventListener('click', () => {
      drawControl._toolbars.draw._modes.polygon.handler.disable();
      buttonsWrapper.remove();
      //show Rita button again
      if(ritaButton){
        ritaButton.style.display = 'inline-block';
      }
    });

    const undoButton = L.DomUtil.create('button', 'btn btn-secondary btn-sm mb-1');
    undoButton.innerHTML = 'Ångra';
    undoButton.addEventListener('click', () => {
      drawControl._toolbars.draw._modes.polygon.handler.deleteLastVertex();
    });

    buttonsWrapper.appendChild(finishButton);
    buttonsWrapper.appendChild(undoButton);
    buttonsWrapper.appendChild(cancelButton);

    map.on(L.Draw.Event.CREATED, (event) => {
      // Hide the buttons after polygon is created
      finishButton.style.display = 'none';
      cancelButton.style.display = 'none';
      undoButton.style.display = 'none';
    });

    map.getContainer().querySelector('.leaflet-draw-toolbar').appendChild(buttonsWrapper);
  }
};


handleShapeCreate = (e) => {
  var type = e.layerType,
      layer = e.layer;

  this.setState({ loading: true });
  let data = getShapeInfo(layer.toGeoJSON());
  data.then((val) => {
      val.is_drawn=true
      this.addLayerToAd(layer, val)
      this.setState({
        loading: false,
      })
  })

}


formatSectionsData(inData) {
  let sections_data = {}
  inData["data"].map((itm) => {
      sections_data[itm["nr"]] = { ...itm }
  });
  return sections_data
}

getLayerColor(sect_data) {
  let felling_class = sect_data["felling_class"];
  let color = ""
  if (felling_class == "S1") {
      color = "#c9c9c9"
  } else if (felling_class == "S2") {
      color = "#797979"
  } else if (felling_class == "S3") {
      color = "#393939"
  } else if (felling_class == "G1") {
      color = "#8af389"
  } else if (felling_class == "G2") {
      color = "#10950f"
  } else if (felling_class == "R") {
      color = "#95d0ea"
  } else if (felling_class == "K"){
      color = "#ff8080"
  } else{
      color = "#bb0000"
  }
  return color;
}

handleShowFellingApplicationsClick = async () => {
  this.setState({ loading: true });
  this.map.current.state.drawn.clearLayers();

  let prms = Object.values(this.state.propertyLayers).map(async (lyr) => {
    let resp = await fetch("/forestagency/finalfelling-application/buffer/", {
      method:"POST",
      body: JSON.stringify({
        geom: lyr.json
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    })
    if (resp.status >= 400) {
      this.setState({ loading: false });
      this.props.handleServerError(resp.status, 'Försök igen senare eller kontakta Kundservice.',  'Den här funktionen är inte tillgänglig.');
    }
    let json = await resp.json();

    L.geoJSON(json, {
      interactive: true,
      style: (feature) => {
        return {
          color: "#ffff00",
        };
      },
      onEachFeature: (feature, layer) => {
        layer.bringToFront();
        layer.addTo(this.map.current.state.drawn)
        layer.on('click', (e) => { 
          
          this.handleShapeClick(e, feature, layer, this.map.current.state.drawn);
      });
      },
    })
    
  })
  await Promise.all(prms);
  this.setState({ loading: false });
  this.adLayers.bringToFront();
}

handleShowFellingClassClick = async () => {
  try {
    this.setState({ loading: true });

    this.map.current.state.drawn.clearLayers();

    let legendData=[];
    let uniqueLegendData= new Set();
    let localIDLabels=[]
    let title="Huggningsklasser";

    let prms = Object.values(this.state.propertyLayers).map(async (itm) => {
      const json = await onlyDivideDataRequest(itm.json)
    
      const sections_data = this.formatSectionsData(json)
      this.setState({showFellingButton:false, hideFellingButton:true})
      if (json["geoJson"]) {
        L.geoJSON(json["geoJson"], {
          interactive: true,
          style: (feature) => {
            const legendLabel = sections_data[feature["properties"]["nr"]]["felling_class"];
            const color = this.getLayerColor(sections_data[feature["properties"]["nr"]]);
            const title= "Huggningsklasser";

          if (!uniqueLegendData.has(legendLabel)) {
            uniqueLegendData.add(legendLabel);
            legendData.push({
              label: legendLabel,
              color: color
            });
          }
            return {
              color: color,
              legendLabel: legendLabel,
              title: title,
            };
          },
          onEachFeature: (feature, layer) => {
            localIDLabels.push({
              location: sections_data[feature["properties"]["nr"]]["label_location"],
              label: sections_data[feature["properties"]["nr"]]["localID"]
            });
            
            layer.addTo(this.map.current.state.drawn)
            layer.bringToFront();
            layer.on('click', (e) => { 
              this.handleShapeClick(e, feature, layer);
          });
          },
        });

        this.setState({ sections_data: sections_data, legendData:legendData });
        this.map.current.setLocalIDData(localIDLabels)
        const legendDataExplanation = [
          "S1: yngre slutavverkningsbar skog", 
          "S2: mogen avverkningsbar skog som bör slutavverkas", 
          "G1: yngre gallringsskog",
          "G2: äldre gallringsskog som uppnått lägsta ålder för slutavverkning", 
          "R: röjningsskog", 
          "K: kalmark före föryngringsåtgärder" ,
          "IA: Ingen åtgärd" ,
          "R1: R",    
      ];
      const explanation = legendDataExplanation.filter(explanation => {
        const label = explanation.split(":")[0].trim();
        return legendData.some(data => data.label === label);
    });
    
        this.map.current.setLegendData(legendData,title,explanation)
      }
    });

    await Promise.all(prms)
    this.adLayers.bringToFront();
    this.setState({ loading: false });
  } catch (error) {
    console.error('Error:', error);
    this.setState({ loading: false });
  }
};


// Add Legend on the map
setLegendData(legendData, title){
  this.setState({legendData:legendData, legendTitle:title})
}

handleHideFellingClass(){ 
  this.map.current.state.drawn.clearLayers();
  this.setState({showFellingButton:true, hideFellingButton:false})
}

handleShapeClick(e, feature, layer) {
  e.target.bringToFront();
  let geojson = e.target.toGeoJSON();
  let data = {}

  if (this.state.adLayers.some((item)=>item.layer._leaflet_id === layer._leaflet_id)) {
    this.deselectSection(feature,layer)
  } else {
    if (this.state.sections_data && this.state.sections_data.hasOwnProperty(feature["properties"]["nr"])) {
      data = this.state.sections_data[feature["properties"]["nr"]]
      this.addLayerToAd(layer, data)
    } else {
      this.setState({ loading: true });
      let data = getShapeInfo(geojson);
      data.then((val) => {
        this.addLayerToAd(layer, val)
        this.setState({
          loading: false,
        })
      })
    }
  }
}

deselectSection(feature,layer){
  const updatedAdLayers = this.state.adLayers.filter((item) => item.layer._leaflet_id !== layer._leaflet_id);
  this.setState({ adLayers: updatedAdLayers });
  let color = ""
  if (this.state.sections_data && this.state.sections_data[feature["properties"]["nr"]]) {
    color = this.getLayerColor(this.state.sections_data[feature["properties"]["nr"]])
  }
  else {
    color = "#ffff00"
  }
  const originalStyle = {
    color: color,
    fillOpacity: 0,
    weight: 2.2 + 0.5,
    dashArray: null
  };
  layer.setStyle(originalStyle);

  this.props.changeAdData({ adLayers: updatedAdLayers });
}


addLayerToAd(layer, data){
  this.map.current.state.drawn.removeLayer(layer);
  layer.addTo(this.adLayers);
  this.adLayers.addTo(this.map.current.state.map);
  if (!layer.hasOwnProperty("_icon")){
    layer.setStyle({
        color: "#ff00ff", 
        fillOpacity: 0.8,
        weight: 2.2 + 0.5,
        dashArray: '10, 10'
    });
  }

  this.state.adLayers.push({data: data, layer: layer});

  this.props.changeAdData({adLayers:this.state.adLayers});
}

// Delete every layer in map
  deleteAll(){
    // Remove the selected section
    if (this.state.adLayers.length > 0) {
      this.state.adLayers.forEach(adLayer => {
        const layer = adLayer.layer;
        if (layer.setStyle) {
          const originalStyle = {
            color: null,
            fillOpacity: 0,
            weight: null,
            dashArray: null
          };
          layer.setStyle(originalStyle);

          this.map.current.state.drawn.removeLayer(layer);
        } else if (layer instanceof L.Marker) {
          this.map.current.state.map.removeLayer(layer);
        }
      });
    }
    // Remove markers and all sections

    this.setState({ adLayers: [] });
    this.props.changeAdData({ adLayers: [] })
  }

  clearLayers() {
    if (this.state.adLayers.length > 0) {
      this.state.adLayers.forEach(adLayer => {
        let color = ""
        if (adLayer.data && adLayer.data.nr !== undefined && typeof adLayer.data.nr === 'number') {
          color = this.getLayerColor(adLayer.data)
        }

        else if(adLayer.data.is_drawn){
          color=""
        }     
        else {
          color = "#ffff00"
        }
        const layer = adLayer.layer;
        if (layer.setStyle) {
          const originalStyle = {
            color: color,
            fillOpacity: 0,
            weight: 2.2 + 0.5,
            dashArray: null
          };
          layer.setStyle(originalStyle);

          this.map.current.state.drawn.removeLayer(layer);
        } else if (layer instanceof L.Marker) {
          this.map.current.state.map.removeLayer(layer);
        }
      });
    }

    this.props.changeAdData({ adLayers: [] })
  }
handleSellingOptionChange = (selectedOption) => {
  this.props.changeAdData({ type: selectedOption});
}


handleDateChange = (date, type) => {
  if(!date) {
    console.error('invalid date:', date);
    return;
  }
  const convert_date = date.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' });

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let errorMessage = null;

  if (type === 'startAd') {
    //You can just start from today
    errorMessage = date < today ? 'Du kan bara börja från och med idag.' : null;
    this.setState({ startAdErrorMessage: errorMessage }, () => {
      if (!errorMessage) {
        this.props.changeAdData({ start_time: convert_date });
        this.setState({ startAdDate: date });
      }
    });
    
  }
  if (type === 'endAd') {
    const minEndDate = new Date(this.state.startAdDate);
    minEndDate.setDate(minEndDate.getDate() + 1);

    const endAdDate = new Date(date);
    

    // End date must be at least 1 day from start date.
    errorMessage = endAdDate.getTime() < minEndDate.getTime() ? '*Slutdatum måste vara minst 1 dag från startdatumet.' : null;

    this.setState({ endAdErrorMessage: errorMessage }, () => {
      if (!errorMessage) {
        this.props.changeAdData({ end_time: convert_date });
        this.setState({ endAdDate: endAdDate });
      }
    });
  }
  if (type === 'startFelling') {
    const endAdDate = new Date(this.state.endAdDate);
    let errorMessage = null;

    if( date < endAdDate) {
      //The start date must be at least the same as or later than the end date of the promotional period.
    errorMessage = '*Startdatumet måste vara minst detsamma som eller senare än slutdatumet för annonsperioden.'
    }
    this.setState({ startFellingErrorMessage: errorMessage }, () => {
      if (!errorMessage) {
        this.props.changeAdData({ felling_start_time: convert_date });
        this.setState({ startFellingDate: date });
      }
    });
  }

  if (type === 'endFelling') {
    const minEndDate = new Date(this.state.startFellingDate);
    minEndDate.setDate(minEndDate.getDate() + 1);

    //End date must be at least 1 day from start date.
    errorMessage = date.getTime() < minEndDate.getTime() ? '*Slutdatum måste vara minst 1 dag från startdatum.' : null;

    this.setState({ endFellingErrorMessage: errorMessage }, () => {
      if (!errorMessage) {
        this.props.changeAdData({ felling_end_time: convert_date });
        this.setState({ endFellingDate: date });
      }
    });
  }
  this.setState((prevState) => ({
    dateErrorMessages: {
      ...prevState.dateErrorMessages,
      [type]: errorMessage,
    }
  }));
};



handleDateDeliveryChange = (date, type) => {
  if (!date) {
    console.error('Invalid date:', date);
    return;
  }

  const convert_date = date.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' });
  let errorMessage = null;

  if (type === 'startFelling') {
    const endAdDate = new Date(this.state.endAdDate);

    errorMessage= endAdDate.getTime() > new Date(date).getTime() ? 'Startdatum för avverkning kan inte vara före annonsens slutdatum.' : null;

    this.setState({ startFellingErrorMessage: errorMessage }, () => {
      if (!errorMessage) {
        this.props.changeAdData({ felling_start_time: convert_date });
        this.setState({ startFellingDate: date });
      }
    });

    // Set endFellingDate to startFelling year
    const endFellingDate = new Date(date);

    // Update state and send data to API
    this.setState({ 
      startFellingDate: date,
      endFellingDate: endFellingDate
    }, () => {
      this.props.changeAdData({
        felling_start_time: convert_date,
        felling_end_time: endFellingDate.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })
      });
    });
  }

  this.setState((prevState) => ({
    dateErrorMessages: { ...prevState.dateErrorMessages, [type]: errorMessage }
  }));
};

toggleInstructions = () => {
  this.setState(prevState => ({
    showInstructions: !prevState.showInstructions
  }));
};


propertyView() {
  const PropListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const filteredProperties = this.state.currentlySelectedPropertiesArray

  return (
    <div className='mt-3'>
    {filteredProperties.length > 0 && (
      <p className='mb-2 fw-medium neutral-charcoal'>Sälj ditt virke:</p>
    )}
    {filteredProperties.map((itm) => (
      <PropListItem key={itm.registerenhet} className="my-2 neutral-charcoal">
        <div className='d-flex align-items-center' style={{cursor: 'auto'}}>
          {itm.beteckning}
          <img src={`${Window.static_path}img/icons/delete.svg`} className='neutral-charcoal-filter ms-2 mb-1' style={{cursor: 'pointer'}} height='18px' width='18px' alt='Delete Icon' onClick={() => this.handleDeleteClick(itm.registerenhet)}/>
        </div>
      </PropListItem>
    ))}
  </div>
  );
}

//For leveransvirke only
renderLeveransvirkeSection() {
  return (
    <div className='row flex-md-row justify-content-between mt-5'>
    <div className='col-12 col-md-6'>
    <p className='mb-2 fw-medium neutral-charcoal'>Leveransperiod:</p>
      {/* Delivery period */}

      <div className='row flex-md-row justify-content-between'> 
      <div className='col-8 col-lg-5'>
        <p className='mb-0'>Upphämtningsdatum</p>
          <VBDatePicker onChange={(date) => this.handleDateDeliveryChange(date, 'startFelling')}
          selectedDate={this.state.startFellingDate} value={this.state.startFellingDate}
          />
          {this.state.startFellingErrorMessage && ( <p className="error-message red-alert">{this.state.startFellingErrorMessage}</p> )}
          </div>
          
          </div>
          </div>
      </div>
  )
}

//For avverkningsuppdrag & leveransrotköp & rotpost
renderDefaultSection() {
  return(
    <div className='row flex-md-row justify-content-between mt-5'>
    <div className='col-12 col-md-6'>
    <div className='d-flex'>
    <p className='mb-2 fw-medium neutral-charcoal'>3. Avverkningsperiod:</p>
      {/* Felling period */}
      {/* Mobile Modal */}
      <InfoIcon title="Avverkningsperiod:" instructions={[
          <p className='mb-0'><span className='fw-medium'>Avverkningsperiod:</span> Här anger du hur lång tid köparen som du eventuellt väljer har på sig att avverka skogen. Vi rekommenderar 2 till 5 år.</p>
              ]} />
      </div>

      <div className='row flex-md-row justify-content-between'>
      <div className='col-8 col-lg-5'>
        <p className='mb-0'>Från</p>
          <VBDatePicker onChange={(date) => this.handleDateChange(date, 'startFelling')}
          selectedDate={this.state.startFellingDate} i/>
          {this.state.startFellingErrorMessage && ( <p className="error-message red-alert">{this.state.startFellingErrorMessage}</p> )}
          </div>
          <div className='col-8 col-lg-5 mt-3 mt-lg-0'>
          <p className='mb-0'>Till</p>
          <VBDatePicker onChange={(date) => this.handleDateChange(date, 'endFelling')}
            selectedDate={this.state.endFellingDate} isEndDate />
          {this.state.endFellingErrorMessage && <p className="error-message red-alert">{this.state.endFellingErrorMessage}</p>}
          </div>
          </div>
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
          <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <p className='mb-0'><span className='fw-medium'>Avverkningsperiod:</span> Här anger du hur lång tid köparen som du eventuellt väljer har på sig att avverka skogen. Vi rekommenderar 2 till 5 år.</p>
            </div>
          </div>
      </div>
  )
}

deleteTheWholeAd(){
  this.setState({loading:true})
  
   this.props.changeAdData({ adLayers: [],
    markers: [],
    forestWithSections: null,
    total_volume: null,
    total_area_ha: null,
    longitude: null,
    latitude: null,
    area: null,
    geometryFile: null,
    geoCollection: null,
    description_of_property: null,
    ground_preparation: false,
    felling_application_responsible: null,
    fsc_certified: null,
    pefc_certified: null,
    visible_draft: false,
    assortment: null,
    region: null,
    muni: null,
    attachments: [],
    custom_area:null
   },()=>{
    window.location.reload()
   })

}

capitalizeFirstLetter(str) {
  if (typeof str !== 'string' || str.length === 0) {
      return str;
  }
  
  if (str[0] === str[0].toLowerCase()) {
      return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
      return str;
  }
}
getAdArrangementLabel(arrangement){
  let changeArrangement=""
  if (arrangement == "gallring"){
       changeArrangement="Gallring"
  }
  else if(arrangement == "slutavverkning"){
    changeArrangement="Slutavverkning"
  }
  else if(arrangement == "vindfallen"){
    changeArrangement="Övrig avverkning"
  }
  else if(arrangement == "grot"){
    changeArrangement="Sälj biobränsle"
  }
  return changeArrangement
}
  render () {
    const filteredProperties = this.state.currentlySelectedPropertiesArray;
    
    let ad_type = Window.react_data.adTypes.find((itm) => itm.value == this.props.adData.type)
    let ad_type_name = ad_type ? ad_type.label.toLowerCase() : ""

    return (
      <div className='' data-step='1'>
        <VBLoadingScreen
          show_loading={this.state.loading}
          disable_reload_timeout={true}
          loading_text={this.state.loading_message} />

          
        <div className='row flex-md-row justify-content-between d-flex flex-column-reverse align-items-center mt-5'>
          <div className='col-12 col-md-8 '>
        <p className='mb-2 fw-medium neutral-charcoal'>Fastighetsbeteckning / fastighetsbeteckningar:</p>
        <div className='col-lg-8 col-12 position-relative rounded shadow p-2' style={{zIndex:1020}}>
        {this.state.allow_map_edit || !this.props.adData.description_of_property ? <VBSearchInput
            ref={this.searchInputRef} placeholder='Sök eller eg: Sevedstorp 1:8' onChange={(val) => { this.setState({searchedText:val.trim()}, ()=>{this.searchProp(val); }) }}
            searchResult={(this.state.searchedText !== '') ? ((this.state.propSearchResults && this.state.propSearchResults.length > 0) ? (this.state.propSearchResults.map(prop => ( <p className="option w-100 ps-2" onClick={() => { this.addProp(prop) }}>{prop.beteckning}</p>))) : ( <p>Inga träffar på den sökningen.</p>)
            ) : (
              null
              )
              }
          /> : <p className="fs-8 m-2">{this.props.adData.description_of_property}</p>}
        </div>
        <div>
          <ul style={{ listStyleType: 'none', padding: 0 }}> {this.propertyView()} </ul>
        </div>
        <div>
         {this.props.adData.description_of_property !== null && <button className='n-btn btn-charcoal' onClick={()=>{this.deleteTheWholeAd()}}>Börja om din annons
          </button>}
        </div>
        </div>

        <div className='col-12 col-md-4 mb-4 mb-md-0'>
        <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <div className='d-flex flex-column'>
            <p className='mb-2 neutral-charcoal fw-medium'>Behöver du hjälp?</p>
            <p className='neutral-charcoal n-fs-7 mb-2'>Du når oss via telefon eller mail:</p>
              <a className='neutral-charcoal text-decoration-underline n-fs-7 mb-2' href='tel:08-33 99 44'>08-33 99 44</a>{' '}
              <a className='neutral-charcoal text-decoration-underline n-fs-7' href='mailto: info@virkesborsen.se'>info@treebula.se</a>
            
          </div>
          </div>
          </div>
          </div>

          {filteredProperties.length > 0 && this.state.allow_map_edit ? <div className='row'>
          <p className='mb-1 neutral-charcoal mt-3 fw-medium'>Vänligen välj:</p>
          {/* When you choose 'Visa avverkningsanmälningar,' kindly select one or more layers on the map below. If you opt for 'Rita,' please draw one or more shapes directly on the map. Clicking 'Placera markör' prompts you to place a marker on the map, and for 'Visa avdelningar,' please select one or more layers on the map below. */}
          <p className='mb-1 mt-1 neutral-charcoal'><span className='fw-medium'>Visa avverkningsanmälningar:</span> För att se avverkningsanmälningar och välja ett område.</p>
          <p className='mb-1 neutral-charcoal'><span className='fw-medium'>Rita:</span> för att skapa en eller flera områden direkt på kartan.</p>
          <p className='mb-1 neutral-charcoal'><span className='fw-medium'>Placera Markör:</span> om du vill markera en plats på kartan.</p>
          <p className='mb-1 neutral-charcoal'><span className='fw-medium'>Visa avdelningar:</span> För att se automatiskt genererade avdelningar baserat på öppen data och för att välja ett område. </p>
          <p className='mb-1 neutral-charcoal'><span className='fw-medium'>Radera allt:</span> för att rensa kartan.
          För att avmarkera en markerad avdelning klickar du på den markerade avdelningen igen.
          </p>
          <div className='row'>
          <div className='mt-2 row col-12 col-lg-10 col-xl-8'>
            <div className='col-12 col-md-6 col-lg-4'>
            <button className="n-btn btn-charcoal btn-outline text-nowrap btn-sm m-1" onClick={this.handleShowFellingApplicationsClick}>Visa Avverkningsanmälningar</button>
            </div>
            <div className='col-4 col-md-4 col-lg-2'>
            <button className="rita-button n-btn btn-charcoal btn-outline text-nowrap btn-sm m-1" onClick={this.handleDrawButtonClick}>Rita</button>
            </div>
            <div className='col-6 col-md-4 col-lg-3'>
            <button className="n-btn btn-charcoal btn-outline text-nowrap btn-sm m-1" onClick={this.handleAddMarkerButtonClick}>Placera Markör</button>
            </div>
            {this.state.propArea < 500 && <div className='col-6 col-md-4 col-lg-3'> {this.state.showFellingButton ? <button className="n-btn btn-charcoal btn-outline text-nowrap btn-sm m-1" onClick={this.handleShowFellingClassClick}>Visa Avdelningar</button> : this.state.hideFellingButton ? <button className="n-btn btn-charcoal btn-outline text-nowrap btn-sm m-1" onClick={()=>{this.handleHideFellingClass()}}>Dölj Avdelningar</button> : null} </div>}
           {this.state.adLayers.length > 0 && <div className='col-6 col-md-4 col-lg-2'> <button className="n-btn btn-charcoal btn-outline text-nowrap btn-sm m-1" onClick={()=>this.clearLayers()}>Radera allt</button> </div>}
            </div>
            </div>
          </div> :null}
          
        <div className='mt-3' style={{height: '500px', position:'relative'}}>
        {this.props.adData.description_of_property !== null && this.props.adData.description_of_property !== "" ? null: <div className='d-flex align-items-center justify-content-center px-4' style={{ backgroundColor: 'black', position: 'absolute', width: '100%', height: '500px', zIndex: 1010, borderRadius:"15px" }}>
        <p className='n-fs-5 white'>Ange fastighetsbeteckning ovan för att se kartan</p>
        </div>}
          <VBLeaflet
            enableDrawControl={{
              circle: false,
              rectangle: false,
              polyline: false,
              edit: false,
              circlemarker: false,
              marker: false,
            }}
            wms_skogsstyrelsen_vector={[
              { "name": "Avverkningsanmälan", "layer": "Avverkningsanmalan_Skogsstyrelsen", "link": "http://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaAvverkningsanmalan/MapServer/WmsServer?" },
              { "name": "Utförd avverkning", "layer": "UtfordAvverkning_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaUtfordavverkning/MapServer/WmsServer?" },
              { "name": "Nyckelbiotoper", "layer": "Nyckelbiotop_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaNyckelbiotop/MapServer/WmsServer?" },
              { "name": "Object med naturvärde", "layer": "Objektnaturvarde_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaObjektnaturvarde/MapServer/WmsServer?" },
              { "name": "Sumpskog", "layer": "Sumpskog_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaSumpskog/MapServer/WmsServer" },
              { "name": "Observationer invasiva arter", "layer": "SpeciesObservationsInvasive", "link": "https://sosgeo.artdata.slu.se/geoserver/SOS/wms" },
              { "name": "Observationer rödlistade arter", "layer": "SpeciesObservationsRedlisted", "link": "https://sosgeo.artdata.slu.se/geoserver/SOS/wms" }
            ]}
            style={{ "borderRadius": "15px" }}
            basemapName="Satellit"
            ref={this.map}
            enableLayers={true}
            hideToolkitButton={true}
            onShapeCreate={(e, all_drawn) => { this.handleShapeCreate(e, all_drawn); }}
            onShapeClick={(e, all_drawn) => { }}
            onDrawStart={(e) => {  }}
          />
          </div>        
        <div>
        {/* show the type User selected from the salja virke form here */}
        <div className='mt-5'>
          <p className='neutral-charcoal'>Typ av annons: <span className='fw-medium'>{this.getAdArrangementLabel(this.props.adData.arrangement)}</span>.</p>
        </div>
        <div className='row flex-md-row justify-content-between mt-5'>
        <div className='col-12 col-md-6'>
        <div className='d-flex'>
        <p className='neutral-charcoal fw-medium'>1. Hur vill du sälja ditt virke?</p>
        {/* Mobile Modal */}
        <InfoIcon title="Hur vill du sälja ditt virke?" instructions={[
          <div>
              <p><span className='fw-medium'>Ange önskad försäljningsform.</span> Är du osäker hjälper vi dig gärna med att förklara vilka olika alternativ som finns.</p>
              <p><span className='fw-medium'>Avverkningsuppdrag:</span> Är den vanligaste försäljningsformen. Här får du antingen anbud som pris per sortiment eller prislista + eventuella tillägg. Kostnaden för att avverka och ta hand om ditt virke får du som en fast avverkningskostnad eller som en lista baserad på medelstam.</p>
              <p><span className='fw-medium'>Leveransrotköp:</span> Med denna försäljningsform får du ett nettopris inkl. avverkningskostnader oavsett kvalité och sortiment. Exempel: 450kr/ m³fub.</p>
              <p><span className='fw-medium'>Leveransvirke:</span> När du eller någon annan avverkar och skogsbolaget köper virket vid bilväg. Här får du också oftast prislista + eventuella tillägg eller fast pris per sortiment.</p>
              <p><span className='fw-medium'>Rotpost:</span> När skogen är stämplad av en stämplingsförrätare och en stämplingslängd finns. Då får du ett total pris inkl. avverkningskostnad. Exempel: 1 000 000 kr.</p>
              </div>
              ]} />
        </div>
        {/* How do you want to sell your wood? */}
        <VBRadioGroup
          options={Window.react_data.adTypes.map(adType => ({
            value:adType.value, 
            label:this.capitalizeFirstLetter(adType.label)
          }))}
          initial={this.props.adData.type}
          onChange={this.handleSellingOptionChange} />
        </div>

        {/* tablet & mobile */}
        <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
        <div className='rounded-3 bg-neutral-stone-300 p-3'>
        <p className='mb-2'><span className='fw-medium'>Ange önskad försäljningsform.</span> Är du osäker hjälper vi dig gärna med att förklara vilka olika alternativ som finns.</p>
          <p className='mb-2'><span className='fw-medium'>Avverkningsuppdrag:</span> Är den vanligaste försäljningsformen. Här får du antingen anbud som pris per sortiment eller prislista + eventuella tillägg. Kostnaden för att avverka och ta hand om ditt virke får du som en fast avverkningskostnad eller som en lista baserad på medelstam.</p>
          {this.state.showFullTextSellingOption ? (
            <div>
          <p className='mb-2'><span className='fw-medium'>Leveransrotköp:</span> Med denna försäljningsform får du ett nettopris inkl. avverkningskostnader oavsett kvalité och sortiment. Exempel: 450kr/ m³fub.</p>
          <p className='mb-2'><span className='fw-medium'>Leveransvirke:</span> När du eller någon annan avverkar och skogsbolaget köper virket vid bilväg. Här får du också oftast prislista + eventuella tillägg eller fast pris per sortiment.</p>
          <p className='mb-2'><span className='fw-medium'>Rotpost:</span> När skogen är stämplad av en stämplingsförrätare och en stämplingslängd finns. Då får du ett total pris inkl. avverkningskostnad. Exempel: 1 000 000 kr.</p>
        </div>
      ) : null}
      <button className="n-btn btn-outline btn-charcoal" onClick={this.handleReadMoreSellingOptionClick}>
        {this.state.showFullTextSellingOption ? "Läs mindre" : "Läs mer"}
      </button>
        </div>
        </div>
        </div>

          <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
          <div className='d-flex'>
          <p className='neutral-charcoal fw-medium'>2. Annonseringsperiod:</p>
          {/* Mobile Modal */}
        <InfoIcon title="Annonseringsperiod:" instructions={[
          <p className='mb-0 neutral-charcoal'><span className='fw-medium'>Annonseringsperiod:</span> Här väljer du hur lång tid din annons ska vara publicerad hos Treebula. Det är den tid som virkesköparna har på sig att lämna anbud. Vi rekommenderar att annonsen ligger publicerad i 3 till 4 veckor så att köparna har tillräckligt med tid.</p>
              ]} />
          </div>
          {/* Advertising period */}

          <div className='row flex-md-row justify-content-between'>
          <div className='col-8 col-lg-5'>
            <p className='mb-0 neutral-charcoal'>Från</p>
              <VBDatePicker onChange={(date) => this.handleDateChange(date, 'startAd')}
              selectedDate={this.state.startAdDate} />
              {this.state.startAdErrorMessage && ( <p className="red-alert error-message">{this.state.startAdErrorMessage}</p> )}
              </div>
              <div className='col-8 col-lg-5 mt-3 mt-lg-0'>
              <p className='mb-0 neutral-charcoal'>Till</p>
              <VBDatePicker onChange={(date) => this.handleDateChange(date, 'endAd')}
                selectedDate={this.state.endAdDate} />
              {this.state.endAdErrorMessage && <p className="red-alert error-message">{this.state.endAdErrorMessage}</p>}
              </div>
          </div>
          </div>
       
        {/* tablet & mobile */}
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
          <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <p className='mb-0 neutral-charcoal'><span className='fw-medium'>Annonseringsperiod:</span> Här väljer du hur lång tid din annons ska vara publicerad hos Treebula. Det är den tid som virkesköparna har på sig att lämna anbud. Vi rekommenderar att annonsen ligger publicerad i 3 till 4 veckor så att köparna har tillräckligt med tid.</p>
          </div>
          </div>
          </div>

          {ad_type_name === 'leveransvirke' && this.renderLeveransvirkeSection()}
          {ad_type_name !== 'leveransvirke' && this.renderDefaultSection()}
        
        {this.props.adData.arrangement !== "gallring" && (
        <div className='row flex-md-row justify-content-between mt-5'>
        <div className='col-12 col-md-6'>
        <div className='d-flex'>
        <p className='col-md-12 col-6 fw-medium neutral-charcoal'>{`${ad_type_name === 'leveransvirke' ? '3.' : '4.'} Vem är ansvarig för avverkningsanmälan?`}</p>
        {/* Mobile Modal */}
        <InfoIcon title="Vem är ansvarig för avverkningsanmälan?" instructions={[
          <div>
          <p className='mb-2 neutral-charcoal'><span className='fw-medium'>Jag:</span> Du som skogsägare utför själv avverkningsanmälan via skogsstyrelsen.</p>
        <p className='mb-2 neutral-charcoal'><span className='fw-medium'>Köparen:</span> Personen som du eventuellt väljer som köpare upprättar avverkningsanmälan åt dig.</p>
        <p className='mb-0'><span className='fw-medium'>Finns redan:</span> Om en avverkningsanmälan redan finns väljer du detta alternativ.</p>
        </div>
              ]} />
        </div>
        {/* Who is responsible for logging notifications? */}
        <VBRadioGroup
          options={[
            { label: 'Jag', value: 'seller' },
            { label: 'Köparen', value: 'buyer'},
            { label: 'Finns Redan', value: 'exists'},
          ]}
          initial={this.props.adData.felling_application_responsible}
          onChange={(val)=>{
            this.props.changeAdData({ felling_application_responsible: val})}} />
        </div>
        <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
        <div className='rounded-3 bg-neutral-stone-300 p-3'>
        <p className='mb-2 neutral-charcoal'><span className='fw-medium'>Jag:</span> Du som skogsägare utför själv avverkningsanmälan via skogsstyrelsen.</p>
        <p className='mb-2 neutral-charcoal'><span className='fw-medium'>Köparen:</span> Personen som du eventuellt väljer som köpare upprättar avverkningsanmälan åt dig.</p>
        <p className='mb-0'><span className='fw-medium'>Finns redan:</span> Om en avverkningsanmälan redan finns väljer du detta alternativ.</p>
        </div>
        </div>
        </div>
        )}

      </div>
      <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} size="md" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">Pågående underhåll</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className='white'>{this.state.errorModalText && this.state.errorModalText}</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-0">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.setState({ errorModal: false })}>Fortsätt</button>
                </Modal.Footer>
            </Modal>
      </div>
    )
  }
}

export default Step1;
