import Cookies from 'js-cookie';

const txt = (props) => {


    const target_lang = Cookies.get("target_lang")
    let prop_source = props.text

    let loc_id = props.global ? prop_source : props.location_id
    if(props.location_id){
        loc_id = props.location_id;
    }

    if(target_lang){
        if(Window?.lang_data[loc_id]?.target_lang == target_lang){
            if(Window.lang_data[loc_id].result_txt){
                //Handle source text change
                if(prop_source != Window.lang_data[loc_id].source_txt && Window.user_data.isAdmin){
                    let headers = {
                        'credentials': 'same-origin',
                        'X-CSRFToken': Cookies.get('csrftoken'),
                        'Content-Type': "application/json"
                    }

                    fetch(`/lang-api/update-string/${Window.lang_data[loc_id].id}/`, {
                        method: "PATCH",
                        headers: headers,
                        body:JSON.stringify({
                            requires_update:true,
                            source_txt: prop_source
                        })
                    })
                }

                return Window.lang_data[loc_id].result_txt
            } else{
                //Untranslated copy found, do nothing
            }
        } else if(Window.user_data.isAdmin){
            let headers = {
                'credentials': 'same-origin',
                'X-CSRFToken': Cookies.get('csrftoken'),
                'Content-Type': "application/json"
            }
    
            fetch('/lang-api/add-string/', {
                method: "POST",
                headers: headers,
                body:JSON.stringify({
                    page_path: props.global ? 'GLOBAL' : window.location.pathname,
                    location_id: loc_id,
                    context_clue: props.context_clue,
                    source_txt: prop_source,
                    target_lang: target_lang
                })
            }).then((resp) =>{
                if(resp.status < 300){
                    //create successfull, do nothing
                } else{
                    resp.json().then((json) => {
                        if(json?.non_field_errors[0] == 'The fields page_path, location_id, target_lang must make a unique set.'){
                            //Blank translate already created, do nothing
                        } else{
                            alert("Could not create blank translation object")
                        }
                    })
                }
            })
        }
    }

    return prop_source
}

export default txt;