import React, { Component } from 'react';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 
import Modal from 'react-bootstrap/Modal';
import * as Opt from '../Helpers';

const fontSizeArray = [
  '10px',
  '13px',
  '16px',
  '20px',
  '25px',
  '31px',
  '39px',
  '49px',
  '61px',
  '76px',
  '95px',
];

const Quill = ReactQuill.Quill

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArray
Quill.register(Size, true);


class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTextEdit:false,
      text: this.props.value, 
    };
  }

  handleSave(){
    this.props.onChange(this.state.text)
  }

  handleEdit(text){
    this.setState({text:text})
  }

  
  render() {
    return (
      <>
      <Modal show={this.state.showTextEdit} onHide={() => this.setState({ showTextEdit: false })} size="xl" contentClassName="bg-white p-2" centered>
        <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>Edit text</Modal.Header>
        <Modal.Body>
          <ReactQuill
            theme="snow"
            value={this.state.text}
            onChange={this.handleEdit.bind(this)}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: fontSizeArray}],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote'],
                ['link'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ color: Opt.colorHex.map((itm)=>itm.value)}, { background: Opt.colorHex.map((itm)=>itm.value) }],
                [{ align: [] }],
              ],
            }}
          />
        </Modal.Body>
        <Modal.Footer>
            <div className='justify-content-between'>
              <button className='btn btn-sm btn-outline' onClick={() => { this.setState({ showTextEdit: false }) }}>Cancel</button>
              <button className='btn btn-sm btn-primary' onClick={() => { this.setState({ showTextEdit: false }); this.handleSave() }}>Save</button>
            </div>
        </Modal.Footer>
      </Modal>
      <button className="_Button_1brfa_1 _Button--secondary_1brfa_53 _Button--medium_1brfa_22" onClick={() => {this.setState({showTextEdit: true})}}>Edit text</button>
      </>
    );
  }
}
TextEditor.defaultProps={
    value:"Lorem ipsum dolor sit amet.",
    text:"Lorem ipsum dolor sit amet.",
    color:"primary-dark"
}

export default TextEditor;