import React from "react";


class NHTextCapper extends React.Component{
    constructor(props){
        super(props);

        this.state={
            visible: false
        }
    }

    render(){
        let text = this.props.text;

        if(!this.state.visible && text.length > this.props.max_length){
            text=text.slice(0,this.props.max_length)
        }

        return <>
            {text}
            {this.props.text.length > this.props.max_length && <span style={{cursor:"pointer"}} className="mb-1 d-block text-decoration-underline supp-forest-green" onClick={()=>{this.setState({visible:!this.state.visible})}}>{this.state.visible ? "Dölj.." : "Visa mer.."}</span>}
        </>
    }
}

export default NHTextCapper;