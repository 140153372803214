import React, { Fragment } from 'react';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import Modal from 'react-bootstrap/Modal';

import { validateEmail, validateForm, userExists, loginUser, registerUser, getKlarnaCheckout } from '../api';
import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';

class PrimakundForm extends React.Component {
    constructor(props) {
        super();

        let url = new URL(window.location);

        let coupon_code = "";
        let sub_service = props.sub_service ? props.sub_service : "year";

        if (url.searchParams.get("coupon_code")) {
            coupon_code = url.searchParams.get("coupon_code");
        }

        if (url.searchParams.get("subscription_length")) {
            sub_service = url.searchParams.get("subscription_length");
        }

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            isPremium: Window.user_data.isPremium,
            subscription: sub_service,
            coupon: coupon_code,
            formData: {},
            errors: {},
            loading: false,
            errorModal: false,
            disableForms: false
        }

        this.timeout = 0;
        this.klarnaCheckout = React.createRef();
        this.container = React.createRef();
    }

    componentDidMount() {
        let url = new URL(window.location);
        if (url.searchParams.get("instant_generate") && this.state.isLogged) {
            this.klarnaCheckout.current.scrollIntoView({ behavior: "smooth" });
            getKlarnaCheckout(this.klarnaCheckout.current, this.state.coupon, this.state.subscription)
        } else if (url.searchParams.get("load_form")) {
            this.container.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists })
                        })
                    } else {
                        this.setState({ errorModal: true })
                    }
                })
            }, 100)
        }
    }

    handleLoginRegisterSubmit(e) {
        e.preventDefault();

        let checkList = [];
        let data = this.state.formData

        // Check which fields needs to be validated
        if (this.state.isReg === null) {
            checkList.push('email')
        } else if (this.state.isReg == true) {
            checkList.push('email', 'password')
        } else {
            checkList.push('roles', 'email', 'muni', 'mobile_phone_number')
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg)

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            if (this.state.isReg === true) {
                this.setState({ loading: true })
                loginUser({ email: data.email, password: data.password }).then(res => {
                    if (res.ok) {
                        res.json().then((json) => {
                            this.setState({ isLogged: true, loading: false, isPremium: json.is_premium })
                            document.getElementsByTagName('body')[0].classList.toggle("nav-update")
                        })
                    }
                    else if (res.status == 401) {
                        validation.email = 'E-posten och det angivna lösenordet överensstämmer inte';
                        validation.password = 'E-posten och det angivna lösenordet överensstämmer inte';

                        this.setState({ errors: validation, loading: false })
                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })

            } else if (this.state.isReg === false) {
                this.setState({ loading: true })
                registerUser({ email: data.email, muni: data.muni, roles: [2], mobile_phone_number: data.mobile_phone_number }).then(res => {
                    if (res.ok) {
                        this.setState({ isLogged: true, loading: false, isPremium: false })
                        document.getElementsByTagName('body')[0].classList.toggle("nav-update")
                    }
                    else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })
            } else {
                this.setState({ loading: false, errorModal: true })
            }
        }
    }

    loginForm() {
        return (<form onSubmit={(e) => { this.handleLoginRegisterSubmit(e) }}>
            <div className={`row justify-content-center py-5 ${this.props.textColor && this.props.textColor}`}>
                <div className="col-12 col-md-6">
                    <VBInput
                        type="email"
                        label={<label className='n-fs-7 pb-1'>Ange e-postadress</label>}
                        error={this.state.errors.email}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                        onChange={(val) => { this.checkEmail(val); this.setState({ formData: { ...this.state.formData, email: val } }); }} />

                    {this.state.isReg == true ? <Fragment>
                        <p className='n-fs-7 mb-0 py-2'>Du är redan medlem. Ange ditt lösenord och klicka på logga in!</p>

                        <VBInput
                            type="password"
                            inputClass="mb-2"
                            label={<label className='n-fs-7 pb-1'>Ange lösenord</label>}
                            error={this.state.errors.password}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, password: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, password: val } }); }} />

                        <a href="/forgot-password/" className="n-fs-7 fw-bold float-end" target="_blank">Glömt lösenord?</a>
                    </Fragment> : null}

                    {this.state.isReg == false ? <Fragment>
                        <p className='n-fs-7 mb-0 py-2'>Du verkar inte ha något konto sedan tidigare. Fyll i informationen nedan för att registrera dig.</p>
                        <div className='row py-2'>
                            <div className="col-12 col-xl-6">
                                <VBDropdown
                                    label={<label className='n-fs-7 pb-1'>Välj en roll</label>}
                                    options={Window.react_data.lists.roles}
                                    autoClose={true}
                                    error={this.state.errors.roles}
                                    onFocus={() => this.setState({ errors: { ...this.state.errors, roles: '' } })}
                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, roles: val.value} }); }} />
                            </div>

                            <div className="col-12 col-xl-6">
                                <VBMultiOption
                                    label={<label className='n-fs-7 pb-1'>{this.state.formData?.roles?.indexOf(2) != -1 ? 'Var äger du skog?' : 'Var är du verksam?'}</label>}
                                    options={Window.react_data.lists.munis}
                                    autoClose={true}
                                    error={this.state.errors.muni}
                                    onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, muni: val.map(itm => parseInt(itm.value)) } }); }} />
                            </div>
                        </div>

                        <VBPhoneInput
                            inputClass='my-1'
                            label={<label className='n-fs-7 pb-1'>Mobilnummer</label>}
                            error={this.state.errors.mobile_phone_number}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, mobile_phone_number: val } }); }} />

                    </Fragment> : null}

                    <div className="d-flex justify-content-center mt-4">
                        <button type="submit" className="n-btn btn-forest-green">{this.state.isReg == false ? 'Registrera' : 'Logga in'}</button>
                    </div>

                    <p className='n-fs-7 text-center text-md-start mt-3 neutral-charcoal'>Genom att klicka på knappen ovan godkänner du våra <a href="/terms/" className='neutral-charcoal'>användarvillkor </a> och <a href='/privacy/' className='neutral-charcoal'>integritetspolicy.</a></p>
                </div>
            </div>
        </form>)
    }

    loadKlarnaCheckout(coupon, subscription) {
        this.setState({ loading: true })
        getKlarnaCheckout(this.klarnaCheckout.current, coupon, subscription).then((json) => {
            if (json.errors) {
                this.setState({ errors: json.errors })
            } else {
                this.setState({ disableForms: true })
            }
            this.setState({ loading: false })
            this.container.current.scrollIntoView({ behavior: "smooth" });
        })
    }

    handleKlarnaSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true })

        getKlarnaCheckout(this.klarnaCheckout.current, this.state.coupon, this.state.subscription).then((json) => {
            if (json.errors) {
                this.setState({ errors: json.errors })
            }
            this.setState({ loading: false })
        })
    }

    klarnaForm() {
        return (<form className="row justify-content-center mt-3" onSubmit={(e) => this.handleKlarnaSubmit(e)}>
            <div style={{maxWidth:"513px"}}>
                <div className='col-12'>
                    <VBCheckbox
                        label={<span className="mb-3">Jag har en rabattkod.</span>}
                        initial={false}
                        onClick={() => { this.setState({ hasCouponCode: !this.state.hasCouponCode })}} />

                    {this.state.hasCouponCode && <VBInput
                        placeholder="Rabattkod"
                        initial={this.state.coupon}
                        error={this.state.errors.coupon_code}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, coupon_code: '' } })}
                        onChange={(val) => { this.setState({ coupon: val }); this.klarnaCheckout.current.innerHTML = ""; }} />}
                </div>

                <div className="d-flex justify-content-center align-items-center" style={{marginTop:"40px"}}>
                    <a href='' className="n-btn btn-forest-green" onClick={(e)=>{this.handleKlarnaSubmit(e)}}>Uppgradera till Plus<span><img className={`ms-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path+"img/icons/arrow.svg"} /></span></a>
                </div>
            </div>
        </form>)
    }

    render() {
        return (<div className="container">
            <div className="row">
                <div className="col-12">
                    <section ref={this.container} className={`px-3 py-5 bg-${this.props.backgroundColor} ${this.props.textColor}`} style={{ borderRadius: `var(--Radius-16, 64px)`, padding: "80px 64px", gap: "80px", }}>
                    <VBLoadingScreen show_loading={this.state.loading} />
                    <div className={`row justify-content-center`}>
                        <div className="col-12 col-md-10 col-lg-6 text-center">
                            <p className='n-fs-3 fw-medium'>{this.props.title}</p>

                            {!this.state.disableForms ? this.state.isLogged ? <p className="n-fs-6">{this.props.paragraph} </p> : <p className="n-fs-6 mb-0">Du behöver vara inloggad eller registrerad kund för att bli Pluskund. Kom igång nedan.</p> : null}
                        </div>
                    </div>

                    {/* THE SEPERATE CHECKS IS TO DUE A BUG IN EDITING INPUTS ON BOTH FORMS AT THE SAME TIME */}
                    {this.state.isLogged && !this.state.disableForms ? this.klarnaForm() : null}

                    {!this.state.isLogged && !this.state.disableForms ? this.loginForm() : null}

                    <div className={`row justify-content-center ${this.props.textColor && this.props.textColor} `}>
                        <div className="col-12 col-md-10 col-lg-8">
                            {this.state.coupon_code == "PRIMASEB" && this.state.isLogged ? <span className='caption'>SEB bjuder dig som använder denna rabattkod på gratis Pluskund dom tre första månaderna. På grund av tekniska begränsningar så kommer det se ut som att du endast fick 1 månad gratis fram tills 2021-06-23 och sen kommer du se den fullständiga längden</span> : null}
                            <div ref={this.klarnaCheckout} className="shadow mt-5"></div>
                        </div>
                    </div>

                    <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} contentClassName="bg-neutral-cloud-200 p-2" centered>
                        <Modal.Body>
                            <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
                            <p className='n-fs-5 neutral-charcoal py-3'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>
                            <button type="button" className="n-btn float-end" style={{background:"#FF5B6C", border:"None"}} onClick={() => this.setState({ errorModal: false })}>Fortsätt</button>
                        </Modal.Body>
                    </Modal>
                </section>
                </div>
            </div>
        </div>)
    }
}
PrimakundForm.defaultProps = {
    title: "Uppgradera till Pluskund",
    paragraph: "Har du en rabattkod? Ange nedan innan du går vidare till betaluppgifter.",
    backgroundColor: "neutral-stone-200",
    textColor: "neutral-charcoal",
}

PrimakundForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    title: { type: "text" },
    paragraph: { type: "text" },   
}

export default PrimakundForm;