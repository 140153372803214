import React from 'react';
import * as Opt from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import TextEditor from '../editor/fields/TextEditor';
import ColorSelector from '../editor/fields/ColorSelector';

class FeaturesLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const colNum = 12 / this.props.numberColumns;
    const tablet = colNum * 2;
    
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'}`}>
      <div className={`container`}>
            <div className='col-12 d-flex flex-column flex-lg-row'>

              {/* Items here */}
              <div className={`row ${this.props.horizontalAlignContent} align-items-stretch p-0`}>
                {this.props.items.map((item, index) => {
                  const mtClass = 'mt-0';
                  let colClass = `col-lg-${colNum} col-md-${tablet}`;

                    return (
                      <div key={index} className={`${colClass} my-2 px-lg-2 px-0`}>
                      <div className={`h-100 d-flex align-items-start flex-column ${mtClass} ${item.showItemBackgroundColor === "yes" ? `bg-${item.backgroundColor} p-3 rounded-4`: 'p-3 rounded-4'}`}>
                        <div className='col-lg-4'>

                          {/* icon or number here */}
                          {/* icon here */}
                          {this.props.showIcon === "yes" && (
                            <div className={`bg-${item.imageBgColor} mb-3 rounded-3 p-2 d-flex align-content-center justify-content-center`} style={{ width: this.props.iconSize, height: this.props.iconSize }}>
                              <img alt={item.alt} src={`${item.src}`} className={`rounded-3 ${item.iconColor}`} />
                            </div>
                          )}

                          {/* number here */}
                          {this.props.showIcon === "no" && (
                            <div className={`bg-${item.imageBgColor} mb-3 rounded-3 p-2 d-flex align-content-center justify-content-center`} style={{ width: '40px', height: '40px' }}>
                              <p className={`n-fs-5 ${item.numberColor}`}>{item.number}</p>
                            </div>
                          )}
                          
                        </div>

                        {/* header and text here */}
                        <p className={`n-fs-5 mb-2 ${item.headerColor}`}>{item.header}</p>
                        <div className='d-block quill-text mb-4' dangerouslySetInnerHTML={{ __html: item.body }}></div>

                        {/* button here */}
                        {item.showButton === "yes" && (
                          <div className='mb-3 mt-auto'>
                          <a href={item.href} className={`n-btn ${item.buttonSize} ${item.buttonStyle} ${item.buttonOptions}`}>
                      {item.buttonText}
                      <span>{item.buttonIconAfter && <img className={`ms-1 ${item.buttonIconColor}`} style={{ width: "18px", height: "18px" }} src={item.buttonIconAfter} />}</span>
                      </a>
                      </div>
                        )}
                        
                      </div>
                      </div>
                    );
                })}
              </div>
            </div>
            </div>
        </div>
    );
  }
}

FeaturesLayout.defaultProps = {
  visibility: "",
  numberColumns: 3,
  showIcon: "yes",
  iconSize: "64px",
  horizontalAlignContent: 'justify-content-start',
  items: [
    {
      src: "https://placehold.co/600x400",
      alt: "scenic image",
      iconColor: 'neutral-charcoal-filter',
      imageBgColor: 'supp-forest-green',
      numberColor: 'neutral-charcoal',
      number: 1,
      header: 'Short heading here',
      headerColor: 'neutral-charcoal',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
      showItemBackgroundColor: "yes",
      backgroundColor: 'neutral-cloud-500',
      showButton: "yes",
      buttonText: "Action",
      href: "/",
      buttonSize: "",
      buttonStyle: "btn-outline",
      buttonOptions: "btn-charcoal",
      buttonIconAfter: null,
      buttonIconColor: "neutral-charcoal-filter",
    },
    {
      src: "https://placehold.co/600x400",
      alt: "scenic image",
      iconColor: 'neutral-charcoal-filter',
      imageBgColor: 'supp-forest-green',
      numberColor: 'neutral-charcoal',
      number: 2,
      header: 'Short heading here',
      headerColor: 'neutral-charcoal',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
      showItemBackgroundColor: "yes",
      backgroundColor: 'neutral-cloud-500',
      showButton: "yes",
      buttonText: "Action",
      href: "/",
      buttonSize: "",
      buttonStyle: "btn-outline",
      buttonOptions: "btn-charcoal",
      buttonIconAfter: null,
      buttonIconColor: "neutral-charcoal-filter",
    },
    {
      src: "https://placehold.co/600x400",
      alt: "scenic image",
      iconColor: 'neutral-charcoal-filter',
      imageBgColor: 'supp-forest-green',
      numberColor: 'neutral-charcoal',
      number: 3,
      header: 'Short heading here',
      headerColor: 'neutral-charcoal',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
      showItemBackgroundColor: "yes",
      backgroundColor: 'neutral-cloud-500',
      showButton: "yes",
      buttonText: "Action",
      href: "/",
      buttonSize: "",
      buttonStyle: "btn-outline",
      buttonOptions: "btn-charcoal",
      buttonIconAfter: null,
      buttonIconColor: "neutral-charcoal-filter",
    },
  ],
};

FeaturesLayout.fields = {
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  numberColumns: {
    type: "select",
    options: Opt.colOptions
  },
  showIcon: {
    type: "select",
    options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
  },
  iconSize: {
    type: "select",
    options: Opt.iconSizeOptions
  },
  horizontalAlignContent: {
    type: "select",
    options: Opt.contentJustify
  },
  items: {
    type: 'array',
    arrayFields: {
      src: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'icon'} type={'icon'} onChange={onChange} value={value} />,
      },
      alt: { type: "text" },
      iconColor:{
        type: "select",
        options: Opt.filterOptions
      },
      imageBgColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      numberColor: {
        type: "select",
        options: Opt.colorVariables
      },
      number: { type: "text" },
      header: { type: "text" },
      headerColor: {
        type: "select",
        options: Opt.colorVariables
      },
      body: { 
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} /> 
      },
      showItemBackgroundColor: {
        type: "select",
        options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
      },
      backgroundColor:{
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      showButton: {
        type: "select",
        options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    },
      buttonText: { type: "text" },
      href: { type: "text" },
      buttonSize: {
        type: "select",
        options: Opt.buttonSize
      },
      buttonStyle: {
        type: "select",
        options: Opt.buttonStyle
      }, 
      buttonOptions: {
        type: "select",
        options: Opt.buttonOptions
      },
      buttonIconAfter: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
      },
      buttonIconColor: {
        type: "select",
        options: Opt.filterOptions
      },     
    }, defaultItemProps: FeaturesLayout.defaultProps.items[0],
  },
}

export default FeaturesLayout;