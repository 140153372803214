import React from 'react';
import AdTables from '../../components/AdFlow/AdTables';
import AdPlaceBidSection from '../../components/AdFlow/AdPlaceBidSection';
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/vbLeaflet';
import Cookies from 'js-cookie';
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';
import { getProps, searchProps, getShapeInfo, onlyDivideDataRequest } from '../../components/SBP/map_api';
import Modal from 'react-bootstrap/Modal';

class AuctionDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ad: Window.react_data.ad,
      bookmarked: Window.react_data.ad ? Window.react_data.ad.bookmarked : false,
      loading:false,
      direction_coords: Window.react_data.ad.longitude &&  Window.react_data.ad.latitude ? this.getCentroid(Window.react_data.ad) : "0,0",
      errorModal: false,
      errorModalText: ''
    };

    this.map = React.createRef()
  }

  handleServerError(response_status, text500, text400) {
    if (response_status >= 500) {
      this.setState({errorModal: true, errorModalText: text500})
    } else if (response_status >= 400) {
      this.setState({errorModal: true, errorModalText: text400})
    }
  }

  redirectAfterServerError = () => {
    window.location.href = '/'
  }

  cleanNumbers(num) {
    return Number(num).toFixed(1).toString().replace(".0", "").replace(".", ",")
  }

  getCentroid(ad) {
    return `${ad.latitude},${ad.longitude}`
  }

  handlePrint = () => {
    window.print();
  }

  componentDidMount() { 
    var drawnItems = new L.FeatureGroup();
    setTimeout(() => {   
      this.map.current.state.map.addLayer(drawnItems);
      if (Window.react_data.trustedBuyer) {
        if (Window.react_data.ad && Window.react_data.ad.description_of_property !== null && Window.react_data.ad.description_of_property !== "") {

          this.setState({ loading: true })
          const descriptions = Window.react_data.ad.description_of_property.split('&');
          descriptions.forEach(description => {
            this.searchProp(description.trim())
          })



        }
        if (Object.keys(Window.react_data.ad.geom).length !== 0) {
          let geoJson = L.geoJson(Window.react_data.ad.geom, {
            style: {
              color: "#DFFF5D",
            },
            onEachFeature: (feature, layer) => {
              drawnItems.addLayer(layer)
            }
          });
          this.map.current.state.map.fitBounds(geoJson.getBounds());
        }

      }
         
    }, 500);
  }

  sendForReview() {
    this.setState({loading:true});
    Cookies.remove('ad_id')
    fetch(window.location, {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: "review=1"
    }).then((resp) => {
      if (resp.ok) {
        window.location.href = window.location.href.replace("/review/", "")
      } else {
        this.handleServerError(resp.status, 'Försök igen senare eller kontakta oss.',  'Den här funktionen är inte tillgänglig.');
      }
    })
  }

  searchProp(val) {
    
      searchProps(val).then((result) => {
        try{
          if (result.length > 0) {
            this.updateMapWithSelectedProperty(result[0].registerenhet)
          }
          else{
            this.setState({loading:false})
          }
        }
        catch{
          this.setState({loading:false})
        }
      })
    }

// Trying to show map with the selected property
  updateMapWithSelectedProperty(registerenhet) {
   
    var drawnItems = new L.FeatureGroup();
    getProps(registerenhet).then((json) => {
  
      const propertyLayer = L.geoJson(json, {
        interactive: false,
        style: (feature) => {
          return {
            color: "#ffffff",
            fillColor: "transparent"
          };
        },
        onEachFeature: (feature, layer) => {
          layer.bringToBack();
          this.map.current.state.map.addLayer(layer);
        }
      });
      this.map.current.state.map.addLayer(drawnItems);
      if(Object.keys(Window.react_data.ad.geom).length !== 0) {
        let geoJson = L.geoJson(Window.react_data.ad.geom, {
          style: {
              color: "#DFFF5D",
          },
          onEachFeature: (feature, layer) => {
            drawnItems.addLayer(layer)
          }
        });
        this.map.current.state.map.fitBounds(geoJson.getBounds());
      } 
      else{
        this.map.current.state.map.fitBounds(propertyLayer.getBounds());
      }      
      this.setState({loading:false})
    })
    .catch(error => {    
      console.error("Error fetching data:", error);
      this.setState({ loading: false });
    });
  }

  handleBookmarkClick(adId, event) {
    event.stopPropagation();
    this.setState({ loading: true });
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': Cookies.get('csrftoken'),
    };

    fetch(window.location, {
        method: 'POST',
        headers: headers,
        body: "bookmarked=1",
    })
        .then(response => response.json())
        .then(data => {
          this.setState({bookmarked:data.bookmarked,loading:false})
       
        })
        .catch(error => {
            console.error('Error:', error);
            this.setState({ loading: false });
        });

};


  render() {
    if (this.state.ad.should_display_popup) {
      return (
        <div className="container-fluid n-fs-6 p-0">
          <VBLoadingScreen
          show_loading={this.state.loading}
          disable_reload_timeout={false}
          loading_text={null} />
          {Window.user_data.isBanned ? (
            <section className='bg-white pt-0 pt-md-3'>
              <div className="container">
                <div className="row flex-md-row align-items-start justify-content-center">
                  <div className='col-md-6 col-12'>
                    <img src={`${Window.static_path}img/icons/Warning.svg`} className='tertiary-red-filter me-1' height='16px' width='16px' alt='Warning Icon' />
                    <p className='text-center mb-0 tertiary-red subtitle fw-bold mb-3'>
                      Du har inte tillgång till att öppna och se annonser, kontakta Treebula för mer information på{' '}
                      <a className='text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href="tel:00468339944">08339944</a> eller maila till <a className='text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href="mailto:info@virkesborsen.se">info@virkesborsen.se.</a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className="container-fluid p-0">
              <div className="bg-white">

              </div>

              <section className='bg-white pt-0 pt-md-3'>
                <div className="container">
                  <div className="row flex-md-row">

                    {/*Ads Top Summary Above Map*/}
                    <div className='p-2 mb-3 order-1 order-md-1'>

                      {/*From BE: ad_bookmarked_by_current_user static is true load below*/}
                      <div className='d-flex align-items-center justify-content-center mb-4'> 
                        <p className="ms-2 n-fs-2 fw-medium text-center"><span><img onClick={(e)=>{this.handleBookmarkClick(this.state.ad.id,e)}}
                            src={`${Window.static_path}img/icons/${this.state.bookmarked ? 'bookmark_star_orange.svg' : 'bookmark_star.svg'}`}
                            height='24px' width='24px' alt='BookMark'/></span>{this.state.ad.type && this.state.ad.type.name && this.state.type.name.capitalize} {this.state.ad && this.state.ad.label || ''}
                          {this.state.ad && this.state.ad.fullserviceadorder && !actionIsLive && this.state.ad.fullserviceadorder.state_display_name}</p>
                      </div>

                      <div className='d-flex flex-wrap column align-items-center justify-content-center'>
                        <p className='me-2 mb-0 n-fs-6 fw-medium'>{this.cleanNumbers(this.state.ad.total_volume)} m³fub </p>
                        <span className='me-2'>|</span>
                        <p className='me-2 mb-0 n-fs-6'>{this.state.ad.arrangement} </p>
                        <span className='me-2'>|</span>
                        <p className='me-2 mb-0 n-fs-6'>{this.state.ad.type}</p>
                        {this.state.ad.auction_is_over && (
                          <p className='mb-0 n-fs-6'><span className='me-2'>|</span>Slutdatum: {this.state.ad.end_time}</p>
                        )}
                        {this.state.ad.auction_in_future && (
                          <p className='mb-0 n-fs-6'><span className='me-2'>|</span>Startdatum: {this.state.ad.start_time}</p>
                        )}
                        {this.state.ad.auction_is_live && (
                          <p className='mb-0 n-fs-6'><span className='me-2'>|</span>Slutdatum: {this.state.ad.end_time}</p>
                        )}
                      </div>
                    </div>

                    {/*Left Section*/}
                    <div className="bg-white primary-dark col-12 col-lg-8 col-md-6 mb-4 order-3 order-md-2">
                      {/*Leaflet Map*/}
                      <div className='d-flex justify-content-center position-relative'>
                      {Window.react_data.trustedBuyer ? null: <div className='d-flex align-items-center justify-content-center px-4 position-absolute' style={{ backgroundColor: 'rgba(0,0,0,0.7)', width: '100%', height: '500px', zIndex: 1010, borderRadius:"15px" }}>
                      <a href="/kontakta-oss/" className='n-btn btn-core'>Kontakta oss för att se all information</a>
                      </div>}
                        <VBLeaflet
                          enableDrawControl={false}
                          style={{ borderRadius: "16px", height: "500px" }}
                          basemapName="Mapbox Satelite Borders"
                          ref={this.map}
                          wms_skogsstyrelsen_vector={[
                            { "name": "Avverkningsanmälan", "layer": "Avverkningsanmalan_Skogsstyrelsen", "link": "http://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaAvverkningsanmalan/MapServer/WmsServer?" },
                            { "name": "Utförd avverkning", "layer": "UtfordAvverkning_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaUtfordavverkning/MapServer/WmsServer?" },
                            { 
                              "name": "Nyckelbiotoper", 
                              "layer": "Nyckelbiotop_Skogsstyrelsen", 
                              "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaNyckelbiotop/MapServer/WmsServer?",
                              "contentGenerator": (content) => {
                                let show_info_keys = [{key:"Beteckn", label: "Beteckning"}, {key:"Biotop1", label:"Biotop 1"}, {key:"Biotop2", label: "Biotop 2"}, {key:"Biotop3", label:"Biotop 3"}, 
                                {key:"Beskrivn1", label:"Beskrivning 1"}, {key:"Beskrivn2", label: "Beskrivning 2"}, {key:"Beskrivn3", label: "Beskrivning 3"}, {key:"Url", label:"Länk"}
                              ]
                                let elems = content.documentElement.querySelectorAll("FIELDS")
                                let output = []
                                elems.forEach((itm) => {
                                  show_info_keys.forEach((key) => {
                                    let val = itm.getAttribute(key.key)
                                    output.push({label:key.label, value:val})
                                  });
                                })
                          
                                return output;
                              }
                          },
                            { "name": "Object med naturvärde", "layer": "Objektnaturvarde_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaObjektnaturvarde/MapServer/WmsServer?" },
                            { "name": "Sumpskog", "layer": "Sumpskog_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaSumpskog/MapServer/WmsServer" },
                            { 
                              "name": "Fornlämningar", 
                              "layer": "fornlamning", 
                              "link": "https://pub.raa.se/visning/lamningar_v1/wms",
                              "contentGenerator": (content) => {
                                let show_info_keys = [{key:"lamningsnummer", label: "Lämningsnummer"}, {key:"raa_nummer", label:"RAA nummer"}, {key:"lamningstyp", label: "Lämningstyp"}, {key:"egenskap", label:"Beskrivning"}, 
                                {key:"senast_publicerad", label:"Senast publicerad"}, {key:"url", label: "Länk"}
                              ]
                                let output = []
 
                                show_info_keys.forEach((key) => {
                                  let elem = content.documentElement.getElementsByTagName("lamningar_v1:" + key.key)[0]
                                  if(elem){
                                    let val = elem.innerHTML.toString()
                                    output.push({label: key.label, value: val})
                                  }
                                });
                          
                                return output;
                              }
                            },
                            { "name": "Vattenskyddsområden", "layer": "PS.ProtectedSites.VSO", "link": "https://geodata.naturvardsverket.se/inspire/ps-nvr/ows" },
                            { "name": "Observationer invasiva arter", "layer": "SpeciesObservationsInvasive", "link": "https://sosgeo.artdata.slu.se/geoserver/SOS/wms" },
                            { "name": "Observationer rödlistade arter", "layer": "SpeciesObservationsRedlisted", "link": "https://sosgeo.artdata.slu.se/geoserver/SOS/wms" }
                          ]}
                          enableLayers={true}
                          hideToolkitButton={true}
                        />
                      </div>

                      {Window.react_data.trustedBuyer ? <div className="p-4 d-flex justify-content-center alignt-items-center" ><button className="n-btn btn-forest-green" onClick={()=> {window.open(`https://www.google.com/maps/dir//${this.state.direction_coords}/`, "_blank")}}>Vägbeskrivning</button></div>:null}

                      {/*All Tables*/}
                      <AdTables />
                    </div>

                    {/*Right Section*/}
                    <div className="bg-white primary-dark col-12 col-lg-4 col-md-6 order-2 order-md-3">

                      {/* <PrintButton/> */}
                      {/* <div className='mb-4'>
                        <button className='btn border border-1 border-secondary-main text-nowrap w-100' onClick={this.handlePrint}>
                          <img src={Window.static_path + "img/icons/print.svg"} className="primary-dark-filter pe-2" style={{ width: '32px', height: '32px' }}></img>
                          <span>Skriv ut annons</span>
                        </button>
                      </div> */}

                      <AdPlaceBidSection toggle_loading={() => {this.setState({loading:!this.state.loading});}} />
                    </div>

                  </div>
                </div>
                <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false }, this.redirectAfterServerError())} contentClassName="bg-neutral-cloud-200 p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="neutral-charcoal" closeButton>
                        <h3 className="neutral-charcoal mb-0">Oops! Något gick fel.</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='neutral-charcoal'>{this.state.errorModalText}</p>
                        <p className='neutral-charcoal'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>
                        <button type="button" className="n-btn float-end" style={{background:"#FF5B6C", border:"None"}} onClick={() => this.setState({ errorModal: false }, this.redirectAfterServerError())}>FORTSÄTT</button>
                    </Modal.Body>
                </Modal>
                  {/* only for Adreview */}
                  {window.location.href.includes("/review") ?
                    <div className="position-fixed bg-neutral-stone-100 w-100 py-4 start-0 bottom-0" style={{ zIndex: 1000 }}>
                      {/* Edit Ad */}
                      <div className='container'>
                        <div className='d-flex justify-content-end'>
                          <a className='n-btn btn-ghost btn-outline btn-sm me-3' href="/auctions/create/">Redigera</a>
                          {/* Submit for review */}
                          <button className="n-btn btn-forest-green btn-sm" onClick={this.sendForReview.bind(this)}>Skicka till granskning<span><img className={`ms-2 white-filter`} style={{ width: "16px", height: "16px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
                        </div>
                      </div>
                    </div> : null}
                </section>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <section className='bg-white pt-0 pt-md-3'>
          <div className="container neutral-charcoal">
            <div className="row flex-md-row justify-content-center">
              <div className='col-md-6 col-12'>
                {/* Conditions for opening an ad */}
                <h1 className="mb-2 n-fs-4 text-center mt-4">Villkor för öppning av annons</h1>
                {/* Click "I agree" to see the full information and confirm that you have read our terms of use and thus may not submit tenders directly to the forest owner on this advertisement. Read our terms of use */}
                <p className='text-center n-fs-6'>Klicka "Jag godkänner" för att se den fullständiga informationen och bekräfta att du har tagit del av våra användarvillkor och därmed inte får lämna anbud direkt till skogsägaren på denna annons. Läs våra användarvillkor <span>{''}</span>
                  <a className='supp-spruce text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href="/terms" target="_blank" rel="noopener noreferrer">
                    här.
                  </a>
                </p>
                <div className="row flex-md-row justify-content-center">
                  <form className='col-md-8 col-12' method="post">
                    <input type="hidden" name="csrfmiddlewaretoken" value={Cookies.get('csrftoken')} />
                    <input
                      type="submit"
                      data-track="true"
                      data-eventName="Opened Ad"
                      data-eventData={`{"ad_end_date":"_date${this.state.ad.end_time}"}`}
                      className='n-btn btn-core text-nowrap w-100 mb-3 btn-sm d-block' style={{ minWidth: '100px' }}
                      value="Jag godkänner"
                      name="accept_terms"
                    />
                    {/* Cancel */}
                    <a className='n-btn btn-outline btn-charcoal btn-sm text-nowrap w-100 mb-3 d-block' style={{ minWidth: '100px' }} href="/">
                      Avbryt
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default AuctionDetailsPage;