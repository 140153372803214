import React from 'react';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import Modal from 'react-bootstrap/Modal';

import { validateEmail, validateForm, userExists, createAd, registerUser, uploadGrotAdAttachments } from '../api';
import { searchProps, createEmptyProp } from '../components/SBP/map_api';
import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';

class GrotForm extends React.Component {
    constructor() {
        super();
        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            isFakeLogged: false,
            errors: {},
            adForm: {},
            userForm: {},
            searchedText: "",
            loginVisible: true,
        }

        this.navigation = React.createRef();
        this.searchInputRef = React.createRef();
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists })
                        })
                    } else {
                        this.setState({ errorModal: true })
                    }
                })
            }, 200)
        }
    }

    handleLoginRegister(e) {
        e.preventDefault();

        let checkList = []
        let data = this.state.userForm

        if (this.state.isReg) {
            checkList.push('email')
        } else if (this.state.isReg === false) {
            checkList.push('email', 'muni', 'mobile_phone_number', 'checkbox')
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg)

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            this.setState({ loading: true })
            if (this.state.isReg) {
                this.setState({ loading: false, isLogged: true, isFakeLogged: true, loginVisible: false })
            } else {
                registerUser({ email: data.email, roles: [2], muni: data.muni, mobile_phone_number: data.mobile_phone_number }).then(res => {
                    if (res.ok) {
                        this.setState({ isLogged: true })
                        document.getElementsByTagName('body')[0].classList.toggle("nav-update")
                        
                        gtag('event', 'Registered', {});
                        gtag("event", "sign_up", { method: "Site" });

                        res.json().then(json => {
                            this.setState({ passwordSentMessage: json.message, loginVisible: false, welcomeVisible: true, loading: false })
                        })

                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })
            }
        }
    }

    handleAdSubmit(e) {
        e.preventDefault();

        if(this.grotFormValidation()) {
            this.setState({ loading: true })
            let adForm = this.state.adForm;
            let error = false

            let data = {
                arrangement: 'grot',
                muni: adForm?.muni,
                grot_volume: adForm?.grot_volume,
                comments: "Vad du har att salja: " + adForm?.sort_of_grot + "\nVar ligger groten/brannved: " + adForm?.location,
                description_of_property: adForm?.property?.beteckning,
                lm_id: adForm?.property?.registerenhet
            }
    
            if (this.state.isFakeLogged) {
                data.email = this.state.userForm.email
            }
    
            createAd(data).then((resp) => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        this.setState({ adForm: { ...this.state.adForm, id: json.ad_id } })

                        if (this.state.adForm.property && !this.state.isFakeLogged && !error) {
                            createEmptyProp(adForm.property.registerenhet, adForm.property.beteckning).then(res => {
                                if (!res.created && !res.set_visible) {
                                    error = true
                                    this.setState({ loading: false, errorModal: true })
                                }
                            })
                        }
                
                        if (this.state.temp_files && !error) {
                            let attachementResp = uploadGrotAdAttachments(this.state.temp_files, json.ad_id, this.state.isFakeLogged ? this.state.userForm.email : "")
                            if (!attachementResp) {
                                error = true
                                this.setState({ loading: false })
                            }
                        }
                    })
                } else {
                    error = true
                    this.setState({ loading: false, errorModal: true })
                }
            }).then(() => {
                if (!error) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.setState({ grotFormVisible: false, successVisible: true, loading: false })
                }
            })
        }
    }

    grotFormValidation() {
        let adForm = this.state.adForm
        let checkList = ['sort_of_grot', 'grot_volume', 'location', 'muni']

        let validation = {};
        checkList.forEach(check => {
            if (check === 'sort_of_grot' && (!adForm.sort_of_grot || adForm.sort_of_grot.length < 1)) {
                validation.sort_of_grot = "Du måste ange typ av grot/brännved";
            }
            if (check === 'grot_volume' && (!adForm.grot_volume || adForm.grot_volume.length < 1)) {
                validation.grot_volume = "Du måste ange en volym för grot/brännved";
            }
            if (check === 'location' && (!adForm.location || adForm.location.length < 1)) {
                validation.location = "Du måste ange platsen för grot/brännved";
            }
            if (check === 'muni' && (!adForm.muni || adForm.muni.length < 1)) {
                validation.muni = "Du måste välja minst en kommun";
            }
        })

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
            return false
        } else {
            return true
        }
    }

    // Handle files
    handleFiles(event, index) {
        let data = this.state
        if (index == null) {
            let files = event.target.files
            !data.temp_files ? data.temp_files = [] : null;
            Array.from(files).map(file => { data.temp_files.push(file) })
        } else {
            data.temp_files.splice(index, 1)
            data.temp_files.length == 0 ? delete data.temp_files : null;
        }

        this.setState({ ...data })
    }

    searchProp(val) {
        if (val.trim() !== '') {
            searchProps(val).then((result) => {
                if(result.error) {
                    alert(result.error)
                } else {
                    let filteredResults = result
                    this.setState({ propSearchResults: filteredResults })
                }
            })
        }
        else {
            this.setState({ propSearchResults: [] })
        }
    }

    removeProperty() {
        this.setState(prevState => ({
            adForm: {
                ...prevState.adForm,
                property: null 
               
            }
        }));
    }

    grotForm() {
        return <div>
            <form onSubmit={(e) => { this.handleAdSubmit(e) }}>
                <h3 className="n-fs-3 fw-medium">Få prisförslag på ditt biobränsle</h3>

                <p className={`mb-4 ${this.props.paragraphColor}`}>Fyll i formuläret nedan för att få ett prisförslag på din GROT, brännved eller biobränsle – kostnadsfritt och utan förbindelser.</p>

                <VBDropdown
                    label={<label className='n-fs-7 neutral-pebble'>Vad ska du sälja?</label>}
                    placeholder="Välj typ"
                    options={[{ label: "Biobränsle", value: "GROT" }, { label: "Brännved", value: "Brännved" }, { label: "Båda GROT och brännved", value: "Båda GROT och brännved" }]}
                    error={this.state.errors.sort_of_grot}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, sort_of_grot: '' } })}
                    onChange={(val) => { this.setState({ adForm: { ...this.state.adForm, sort_of_grot: val.value } }) }} />

                <VBDropdown
                    label={<label className='n-fs-7 mt-3 neutral-pebble'>Var ligger ditt brännved/ GROT?</label>}
                    placeholder="Välj plats"
                    options={[{ label: "Vid bilväg", value: "Vid bilväg" }, { label: "På hygget", value: "På hygget" }, { label: "Båda på hygget och vid bilväg", value: "Båda på hygget och vid bilväg" }]}
                    error={this.state.errors.location}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, location: '' } })}
                    onChange={(val) => { this.setState({ adForm: { ...this.state.adForm, location: val.value } }) }} />

                <VBDropdown
                    label={<label className='n-fs-7 mt-3 neutral-pebble'>Vilken kommun ligger den i?</label>}
                    placeholder="Välj kommun"
                    error={this.state.errors.muni}
                    options={Window.react_data.lists.munis.map(itm => { return { value: itm.value, label: itm.label } })}
                    onChange={(val) => { this.setState({ adForm: { ...this.state.adForm, muni: val.value } }) }} />


                <VBSearchInput
                    label={<label className='n-fs-7 mt-3 neutral-pebble'>Vilken fastighet ligger den i? (Valfritt)</label>}
                    ref={this.searchInputRef}
                    placeholder='Ange fastighetsbeteckning'
                    error={this.state.errors.property}
                    onChange={(val) => {
                        this.setState({
                            searchedText: val.trim()
                        },
                            () => {
                                this.searchProp(val);
                            })
                    }}
                    searchResult={
                        (this.state.searchedText !== '') ? (
                            (this.state.propSearchResults && this.state.propSearchResults.length > 0) ? (
                                this.state.propSearchResults.map(prop => {
                                    return <p className="option w-100 ps-2" onClick={() => { this.setState({ adForm: { ...this.state.adForm, property: prop } }) }}>{prop.beteckning}</p>
                                })
                            ) : (
                                <p>Inga träffar på den sökningen.</p>
                            )
                        ) : (
                            null
                        )
                    } />

                <p className='red-alert n-fs-7'>{this.state.errors.property}</p>
                {this.state.adForm?.property?.beteckning &&  <div className="d-inline-block">
                    <div className="p-1 rounded-3 n-fs-6 me-1 mt-1 bg-neutral-cloud-300">{this.state.adForm?.property?.beteckning}<img src={Window.static_path + "img/icons/X.svg"} className='ms-1' role="button" width="18px" height="18px" onClick={() => { this.removeProperty(); }} /></div>
                </div>}            

                <VBInput
                    type="text"
                    label={<label className='n-fs-7 mt-3 neutral-pebble'>Hur mycket ska du sälja?</label>}
                    placeholder="Ange volym (estimat)"
                    error={this.state.errors.grot_volume}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, grot_volume: '' } })}
                    onChange={(val) => { this.setState({ adForm: { ...this.state.adForm, grot_volume: val } }); }} />

                <div className="bg-whiteoff rounded-bottom mt-4">
                    <input multiple type="file" id="attachments" className="position-absolute" style={{ opacity: 0, width:"1px" }} onChange={(e) => { this.handleFiles(e, null); }} />
                    <p className={`n-fs-5 fw-medium mb-0 ${this.props.subtitleColor}`}>Ladda upp bilder</p>
                    <p className={`n-fs-7 mb-0 ${this.props.subtitleColor}`}>Om du har bilder på din Grot/Brännved kan du ladda upp dem här</p>                
                </div>

                {this.state.temp_files ? <>
                    <div className="bg-whiteoff d-flex flex-wrap rounded-top mt-3" >
                        {Array.from(this.state.temp_files).map((file, index) => {
                            return (
                                <div className="p-1 rounded-3 n-fs-8 me-1 mt-1 bg-neutral-cloud-300">{file.name}<img src={Window.static_path + "img/icons/X.svg"} className='ms-1' role="button" width="16px" height="16px" onClick={(e) => { this.handleFiles(e, index); }} /></div>
                            )
                        })}
                    </div> </> : null}
                    <label role='button' className="n-btn btn-forest-green mt-3" for="attachments"><img className='me-2 mb-1' style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/icon-left.svg"} />Välj filer</label>

                {Object.keys(this.state.adForm).length !== 0 ?
                    <div className='mt-5'>
                        <button type="submit" className={`n-btn btn-core w-100`} onClick={(e) => { this.handleAdSubmit(e) }}>Skicka<span><img className={`ms-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
                    </div> :
                    <div className='mt-5'>
                        <button type="submit" className={`n-btn btn-core w-100`} onClick={(e) => { this.handleAdSubmit(e) }} disabled>Skicka<span><img className={`ms-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
                    </div>
                }
            </form>
        </div>
    }

    welcomeMessage() {
        return <div>
            <h3 className="n-fs-3 fw-medium">Välkommen!</h3>
            <p className={`mb-2 n-fs-5 fw-medium ${this.props.subtitleColor}`}>Vad händer nu?</p>
            <p className={`mb-0 n-fs-7 ${this.props.subtitleColor}`}>1.Ett lösenord har skickats till din mailadress. Du kan börja fylla i formuläret för att få ett prisförslag på din grot och brännved</p>
            <p className={`mb-4 n-fs-7 ${this.props.subtitleColor}`}>2. Du kan börja fylla i formuläret för att få ett prisförslag på din grot och brännved</p>
            <p className={`mb-2 n-fs-5 fw-medium ${this.props.subtitleColor}`}>Har du frågor?</p>
            <p className={`mb-0 n-fs-6 ${this.props.subtitleColor}`}>Du når oss på:</p>
            <p className={`mb-0 n-fs-6 ${this.props.subtitleColor}`}>08-33 99 44</p>
            <p className={`mb-0 n-fs-6 ${this.props.subtitleColor}`}>info@treebula.se</p>

            <div className='mt-5'>
                <button type="submit" className={`n-btn btn-core w-100`} onClick={() => { this.setState({ welcomeVisible: false, grotFormVisible: true }) }}>Fortsätt<span><img className={`ms-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
            </div>
        </div>
    }

    successMessage() {
        return <div>
            <h3 className="n-fs-3 fw-medium">SUCCÉ</h3>
            <p className="mb-4 n-fs-6">Tack för din förfrågan. Vi kommer nu att ta in prisförslag från alla potentiella köpare och återkomma till dig så snabbt som möjligt. Om du inte redan bifogat bilder i din förfrågan ber vi dig skicka bild, din fastighetsbeteckning och mailadress som SMS till <a href="tel:0046730518651">0730518651</a> eller som ett mail till <a href="mailto:biobransle@treebula.se">biobransle@treebula.se</a>.</p>
            <p className={`mb-2 n-fs-5 fw-medium`}>Vad händer nu?</p>
            <p className={`mb-0 n-fs-7`}>1. Vi har tagit emot all information</p>
            <p className={`mb-4 n-fs-7`}>2. Vi kommer ta kontakt med dig inom kort</p>
            <p className={`mb-2 n-fs-5 fw-medium`}>Har du frågor?</p>
            <p className={`mb-0 n-fs-6`}>Du når oss på:</p>
            <p className={`mb-0 n-fs-6`}>08-33 99 44</p>
            <p className={`mb-0 n-fs-6`}>info@treebula.se</p>
        </div>
    }

    loginForm() {
        return <div>
            <h3 className="n-fs-3 fw-medium">Få prisförslag på ditt biobränsle</h3>

            <form onSubmit={(e) => { this.handleLoginRegister(e) }}>
                <VBInput
                    type="email"
                    label={<label className='n-fs-7 neutral-pebble'>Börja med att ange din mailadress:</label>}
                    placeholder="E-postadress"
                    error={this.state.errors.email}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                    onChange={(val) => { this.checkEmail(val); this.setState({ userForm: { ...this.state.userForm, email: val } }); }} />

                {this.state.isReg == false && <>
                    <VBPhoneInput
                        label={<label className='n-fs-7 mt-3 neutral-pebble'>Ange mobilnummer:</label>}
                        error={this.state.errors.mobile_phone_number}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                        onChange={(val) => { this.setState({ userForm: { ...this.state.userForm, mobile_phone_number: val } }); }} />

                    <VBMultiOption
                        label={<label className='n-fs-7 mt-3 neutral-pebble'>Var äger du skog?</label>}
                        options={Window.react_data.lists.munis}
                        autoClose={true}
                        error={this.state.errors.muni}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                        onChange={(val) => { this.setState({ userForm: { ...this.state.userForm, muni: val.map(itm => parseInt(itm.value)) } }); }} />

                    <div className='d-flex flex-column' style={{ marginTop: "24px" }}>
                        {this.state.errors?.checkbox && 
                            <p className='text-danger n-fs-7 mb-1'>{this.state.errors?.checkbox}</p>
                        }
                        <div className='d-flex align-items-center'>
                            <VBCheckbox
                                label=""
                                initial={false}
                                onClick={() => { this.setState({ userForm: {...this.state.userForm, checkbox: !this.state.userForm.checkbox}, errors: { ...this.state.errors, checkbox: '' } }) }} />
                                
                            <p className='mb-0 n-fs-7'>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/"  className={`text-decoration-underline`}>användarvillkor</a> och <a href="/privacy/" className={`text-decoration-underline`}>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                        </div>
                    </div>
                </>}

                <div className='mt-5'>
                    <button type="submit" className={`n-btn btn-core w-100`} onClick={(e) => { this.handleLoginRegister(e) }}>Få prisförslag<span><img className='ms-2 neutral-charcoal-filter' width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
                </div>
            </form>
        </div>
    }

    render() {
        let html = null
        if (this.state.welcomeVisible) {
            html = this.welcomeMessage()
        } else if (this.state.successVisible) {
            html = this.successMessage()
        } else if (this.state.isLogged || this.state.isFakeLogged) {
            html = this.grotForm()
        } else {
            html = this.loginForm()
        }
        return <>
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className={`bg-${this.props.backgroundColor} ${this.props.textColor} w-100`} style={{ borderRadius: "40px", padding: "32px" }}>
                {html}

                <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} contentClassName="bg-neutral-cloud-200 p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="neutral-charcoal" closeButton>
                        <h2 className="n-fs-4 neutral-charcoal mb-0">Oops! Något gick fel.</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='n-fs-5 neutral-charcoal'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>
                        <button type="button" className="n-btn float-end" style={{background:"#FF5B6C", border:"None"}} onClick={() => this.setState({ errorModal: false })}>Fortsätt</button>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    }
}

GrotForm.defaultProps = {
    backgroundColor:"white",
    textColor: "neutral-charcoal" ,
    subtitleColor: "neutral-charcoal",
    paragraphColor: "neutral-charcoal",
}

GrotForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    subtitleColor: {
        type: "select",
        options: Opt.colorVariables
    },
    paragraphColor: {
        type: "select",
        options: Opt.colorVariables
    }
}

export default GrotForm;